
//to handle state
const state = {
    user: localStorage.getItem('user'),
    token: localStorage.getItem('token'),
    server: localStorage.getItem('server') ? localStorage.getItem('server') : 'localhost'
}

//to handle state
const getters = {
    isLoggedIn(state) {
        return !!state.user;
    },
    authStatus(state) {
        return state.status
    },
    getUser(state) {
        return state.user
    },
    getServer(state) {
        return state.server
    },
}

//to handle actions
const actions = {
    keluar({commit}){
        commit('logout')
    },
    initServer({commit}, server) {
        commit('setServer', server)
    },
    deleteServer({commit}) {
        commit('removeServer')
    }
}

//to handle mutations
const mutations = {
    setUser(state, user) {
        localStorage.setItem('user', JSON.stringify(user))
        state.user = JSON.stringify(user);
    },
    setToken(state, token) {
        localStorage.setItem('token', token)
        state.token = token;
    },
    auth_success(state) {
        state.status = 'success'
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.user = ''
        state.token = ''
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    },
    setServer(state, server) {
        state.server = server
        localStorage.setItem('server', server)
    },
    removeServer(state) {
        state.server = ''
        localStorage.removeItem('server')
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}