<template>
  <div data-app class="container set-height">
    <div class="row">
      <div class="col-4 m4">
        <v-card>
          <v-card-text>
            <span class="card-title">Form Kategori Bahan Baku</span>

            <v-text-field
              label="Nama Kategori"
              v-model="name"
              @keyup.prevent="submitCategory"
            ></v-text-field>
            <v-text-field
              label="Deskripsi Kategori"
              v-model="description"
              @keyup.prevent="submitCategory"
            ></v-text-field>
            <div v-if="this.id == ''">
              <v-btn block color="success" @click.prevent="submitCategory">
                Tambah
              </v-btn>
            </div>
            <div v-else>
              <v-col>
                <v-row>
                  <v-col class="pl-0 py-0">
                    <v-btn block color="error" @click.prevent="clear">
                      Batal
                    </v-btn>
                  </v-col>
                  <v-col class="pr-0 py-0">
                    <v-btn
                      block
                      color="warning"
                      @click.prevent="submitCategory"
                    >
                      Ubah
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div class="col-8 m8">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small @click="changeCategory(item)" class="warning ma-1">
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-btn small @click="removeCategory(item)" class="error ma-1">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data> Belum ada data </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiRawMaterialCategory from "@/api/rawMaterialCategory.js";

export default {
  data() {
    return {
      items: [],
      name: "",
      description: "",
      id: "",
      headers: [
        {
          text: "Nama Kategori",
          align: "start",
          value: "name",
        },
        {
          text: "Deskripsi",
          value: "description",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      search: "",
    };
  },
  mounted() {
    this.initCategory();
  },
  methods: {
    initCategory() {
      this.getCategory()
        .then((response) => response.json())
        .then((json) => {
          this.items = [];
          if (json.success != undefined && json.success == true) {
            this.listCategory(json.data);
          }
        })
        .catch(() => {});
    },
    listCategory(datas) {
      Array.from(datas).forEach((el) => {
        this.items.push({
          id: el.id,
          name: el.name,
          description: el.description,
          created_at: el.created_at,
          updated_at: el.updated_at,
        });
      });
    },
    submitCategory(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      let category = {
        name: this.name,
        description: this.description,
      };

      if (this.id == "") {
        this.addCategory(category)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.items.push({
                id: json.data.id,
                name: json.data.name,
                description: json.data.description,
              });
              swal.success(json.message);
              (this.name = ""), (this.description = ""), (this.id = "");
            } else {
              swal.error(json.message);
            }
          })
          .catch((err) => {
            swal.error(err);
          });
      } else {
        this.editCategory(this.id, category)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.items.forEach((p) => {
                if (p.id == this.id) {
                  (p.name = this.name), (p.description = this.description);
                }
              });
              swal.success(json.message);
              (this.name = ""), (this.description = ""), (this.id = "");
            } else {
              swal.error(json.message);
            }
          })
          .catch((err) => {
            swal.error(err);
          });
      }
    },
    changeCategory(data) {
      (this.name = data.name),
        (this.description = data.description),
        (this.id = data.id);
    },
    removeCategory(data) {
      let response = confirm(`Are you sure you want to delete ${data.name}`);
      if (response) {
        ApiRawMaterialCategory.delete(data.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.initCategory();
              swal.success(json.message);
            } else {
              swal.error(json.message);
            }
          })
          .catch((err) => {
            swal.error(err);
          });
      } else {
        return;
      }
    },
    async getCategory() {
      return await ApiRawMaterialCategory.all();
    },
    async addCategory(category) {
      return await ApiRawMaterialCategory.create(category);
    },
    async editCategory(id, category) {
      return await ApiRawMaterialCategory.put(id, category);
    },
    async deleteCategory(id) {
      return await ApiRawMaterialCategory.delete(id);
    },
    submit(event) {
      if (event.keyCode == 13) {
        swal.success("Mantap");
      }
    },
    clear() {
      this.id = "";
      this.name = "";
      this.description = "";
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>