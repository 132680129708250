<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form Produk</span>
                        
                        <v-text-field
                            label="Nama Produk"
                            v-model="name"
                            @keyup.prevent="submitProduct"
                        ></v-text-field>
                        <v-text-field
                            label="Deskripsi Produk"
                            v-model="description"
                            @keyup.prevent="submitProduct"
                        ></v-text-field>
                        <div v-if="(this.id == '')">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="submitProduct"
                                >
                                Tambah
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            block
                                            color="error"
                                            @click.prevent="clear"
                                        >
                                            Batal
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            block
                                            color="warning"
                                            @click.prevent="submitProduct"
                                        >
                                            Ubah
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="products"
                        :search="search"
                        sort-by="name"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small @click="changeProduct(item)" class="warning ma-1">
                            <v-icon
                                small
                            >
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn small @click="removeProduct(item)" class="error ma-1">
                            <v-icon
                                small
                            >
                                mdi-delete
                            </v-icon>
                        </v-btn>
                        </template>
                        <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initProducts"
                        >
                            Reset
                        </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiProduct from '@/api/product.js'


export default {
    data() {
        return {
            products: [],
            name: '',
            description: '',
            id: '',
            headers: [
                {
                text: 'Nama Product',
                align: 'start',
                value: 'name',
                },
                { text: 'Deskripsi', value: 'description' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
        }
    },
    mounted() {
        this.initProducts()
    },
    methods: {
        initProducts() {
            this.getProducts()
            .then(response => response.json())
            .then(json => {
                this.products = []
                if( json.success != undefined && json.success == true ) {
                    this.listProducts(json.data)
                }
            })
            .catch(() => {
                
            })
        },
        listProducts(datas) {
            Array.from(datas).forEach(el => {
                this.products.push({
                    id: el.id,
                    name: el.name,
                    description: el.description,
                    created_at: el.created_at,
                    updated_at: el.updated_at,
                })
            })
        },
        submitProduct(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            let product = {
                name: this.name,
                description: this.description,
            }
            
            if(this.id == '') {
                this.addProduct(product)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.products.push({
                            id: json.data.id,
                            name: json.data.name,
                            description: json.data.description
                        })
                        swal.success(json.message)
                        this.name = '',
                        this.description = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                this.editProduct(this.id, product)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.products.forEach(p => {
                            if(p.id == this.id) {
                                p.name = this.name,
                                p.description = this.description
                            }
                        })
                        swal.success(json.message)
                        this.name = '',
                        this.description = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }
            
        },
        changeProduct(data) {
            this.name = data.name,
            this.description = data.description,
            this.id = data.id

        },
        removeProduct(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.products = this.products.filter(u => u.id != data.id)
                this.deleteProduct(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.listProducts(json.data)
                        swal.success(json.message)
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                return
            }
        },
        async getProducts() {
            return await ApiProduct.all()
        },
        async addProduct(product) {
            return await ApiProduct.create(product)
        },
        async editProduct(id, product) {
            return await ApiProduct.put(id, product)
        },
        async deleteProduct(id) {
            return await ApiProduct.delete(id)
        },
        submit(event) {
            if(event.keyCode == 13) {
                swal.success('Mantap')
            }
        },
        clear() {
            this.id = ''
            this.name = ''
            this.description = ''
        },
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>