import RawMaterialLogApi from '../api/rawMaterialLog'
import ProcessedMaterialLogApi from '../api/processedMaterialLog'

const state = () => ({
    bbOpname: [],
    bbOpnameUnScanned: [],
    bbOpnameScanned: [],
    bsjOpname: [],
    bsjOpnameUnScanned: [],
    bsjOpnameScanned: [],
    bjOpname: [],
    bjOpnameUnScanned: [],
    bjOpnameScanned: [],
})

const getters = {
    bbOpname(state) {
        return state.bbOpname
    },
    bbOpnameUnScanned(state) {
        return state.bbOpnameUnScanned
    },
    bbOpnameScanned(state) {
        return state.bbOpnameScanned
    },
    bsjOpname(state) {
        return state.bsjOpname
    },
    bsjOpnameUnScanned(state) {
        return state.bsjOpnameUnScanned
    },
    bsjOpnameScanned(state) {
        return state.bsjOpnameScanned
    },
    bjOpname(state) {
        return state.bjOpname
    },
    bjOpnameUnScanned(state) {
        return state.bjOpnameUnScanned
    },
    bjOpnameScanned(state) {
        return state.bjOpnameScanned
    },
}

const actions = {
    async fetchBBOpname({commit}) {
        await RawMaterialLogApi.opname()
        .then(data => data.json())
        .then(res => {
            commit('setBBOpname', res.data)
        })
        .catch(err => {
            console.log(err)
        })
    },
    splitBBOpname({commit}, datas) {
        Array.from(datas).forEach(el => {
            if(el.flag == 0) {
                commit('addBBOpnameUnScanned', el)
            } else {
                commit('addBBOpnameScanned', el)
            }
        })
    },
    scanBBOpname({commit}, data) {
        commit('addScannedBBOpname', data)
    },
    deleteAllBBOpname({commit}) {
        commit('clearBBOpname')
    },
    async fetchBSJOpname({commit}) {
        await ProcessedMaterialLogApi.opnameBSJ()
        .then(data => data.json())
        .then(res => {
            commit('setBSJOpname', res.data)
        })
        .catch(err => {
            console.log(err)
        })
    },
    async fetchBJOpname({commit}) {
        await ProcessedMaterialLogApi.opnameBJ()
        .then(data => data.json())
        .then(res => {
            commit('setBJOpname', res.data)
        })
        .catch(err => {
            console.log(err)
        })
    },
    splitBSJOpname({commit}, datas) {
        Array.from(datas).forEach(el => {
            if(el.flag == 0) {
                commit('addBSJOpnameUnScanned', el)
            } else {
                commit('addBSJOpnameScanned', el)
            }
        })
    },
    splitBJOpname({commit}, datas) {
        Array.from(datas).forEach(el => {
            if(el.flag == 0) {
                commit('addBJOpnameUnScanned', el)
            } else {
                commit('addBJOpnameScanned', el)
            }
        })
    },
    scanBSJOpname({commit}, data) {
        commit('addScannedBSJOpname', data)
    },
    scanBJOpname({commit}, data) {
        commit('addScannedBJOpname', data)
    },
    deleteAllBSJOpname({commit}){
        commit('clearBSJOpname')
    },
    deleteAllBJOpname({commit}) {
        commit('clearBJOpname')
    }
}

const mutations = {
    setBBOpname(state, data) {
        state.bbOpname = data
    },
    setBBOpnameUnScanned(state, data) {
        state.bbOpnameUnScanned = data
    },
    setBBOpnameScanned(state, data) {
        state.bbOpnameScanned = data
    },
    addBBOpnameUnScanned(state, data) {
        state.bbOpnameUnScanned.push({
            id: data.id,
            raw_material_id: data.raw_material_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            raw_material: {
                name: data.raw_material.name,
                supplier: {
                    name: data.raw_material.supplier.name
                }
            },
            flag: data.flag
        })
    },
    addBBOpnameScanned(state, data) {
        state.bbOpnameScanned.push({
            id: data.id,
            raw_material_id: data.raw_material_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            raw_material: {
                name: data.raw_material.name,
                supplier: {
                    name: data.raw_material.supplier.name
                }
            },
            flag: data.flag
        })
    },
    addScannedBBOpname(state, result) {
        const index = state.bbOpnameUnScanned.map(e => { return e.barcode }).indexOf(result.data.barcode);
        if (index > -1) {
            state.bbOpnameUnScanned.splice(index, 1);
        }
        state.bbOpnameScanned.push({
            id: result.data.id,
            raw_material_id: result.data.raw_material_id,
            barcode:result.data.barcode,
            amount: result.data.amount,
            raw_material: {
                name: result.data.raw_material.name
            },
            flag: 1
        })
        state.bbOpname.filter(e => { if(e.barcode == result.data.barcode) { e.flag = 1 } })
    },
    clearBBOpname(state) {
        state.bbOpnameScanned = []
        state.bbOpnameUnScanned = []
    },
    setBSJOpname(state, data) {
        state.bsjOpname = data
    },
    setBSJOpnameUnScanned(state, data) {
        state.bsjOpnameUnScanned = data
    },
    setBSJOpnameScanned(state, data) {
        state.bsjOpnameScanned = data
    },
    addBSJOpnameUnScanned(state, data) {
        state.bsjOpnameUnScanned.push({
            id: data.id,
            product_item_id: data.product_item_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            product_item: {
                name: data.product_item.name,
            },
            flag: data.flag
        })
    },
    addBSJOpnameScanned(state, data) {
        state.bsjOpnameScanned.push({
            id: data.id,
            product_item_id: data.product_item_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            product_item: {
                name: data.product_item.name,
            },
            flag: data.flag
        })
    },
    addScannedBSJOpname(state, result) {
        const index = state.bsjOpnameUnScanned.map(e => { return e.barcode }).indexOf(result.data.barcode);
        if (index > -1) {
            state.bsjOpnameUnScanned.splice(index, 1);
        }
        state.bsjOpnameScanned.push({
            id: result.data.id,
            product_item_id: result.product_item_id,
            barcode:result.data.barcode,
            amount: result.data.amount,
            product_item: {
                name: result.data.product_item.name
            },
            flag: 1
        })
        state.bsjOpname.filter(e => { if(e.barcode == result.data.barcode) { e.flag = 1 } })
    },
    clearBSJOpname(state) {
        state.bsjOpnameScanned = []
        state.bsjOpnameUnScanned = []
    },
    setBJOpname(state, data) {
        state.bjOpname = data
    },
    setBJOpnameScanned(state, data) {
        state.bjOpnameScanned = data
    },
    setBJOpnameUnScanned(state, data) {
        state.bjOpnameUnScanned = data
    },
    addBJOpnameUnScanned(state, data) {
        state.bjOpnameUnScanned.push({
            id: data.id,
            product_item_id: data.product_item_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            product_item: {
                name: data.product_item.name,
            },
            flag: data.flag
        })
    },
    addBJOpnameScanned(state, data) {
        state.bjOpnameScanned.push({
            id: data.id,
            product_item_id: data.product_item_id,
            amount: data.amount,
            barcode: data.barcode,
            created_at: data.created_at,
            updated_at: data.updated_at,
            product_item: {
                name: data.product_item.name,
            },
            flag: data.flag
        })
    },
    addScannedBJOpname(state, result) {
        const index = state.bjOpnameUnScanned.map(e => { return e.barcode }).indexOf(result.data.barcode);
        if (index > -1) {
            state.bjOpnameUnScanned.splice(index, 1);
        }
        state.bjOpnameScanned.push({
            id: result.data.id,
            product_item_id: result.product_item_id,
            barcode:result.data.barcode,
            amount: result.data.amount,
            product_item: {
                name: result.data.product_item.name
            },
            flag: 1
        })
        state.bjOpname.filter(e => { if(e.barcode == result.data.barcode) { e.flag = 1 } })
    },
    clearBJOpname(state) {
        state.bjOpnameScanned = []
        state.bjOpnameUnScanned = []
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}