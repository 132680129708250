import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/product')
    },
    async get(id) {
        return await api.get('/product/' + id)
    },
    async create(product) {
        return await api.post('/product', product)
    },
    async put(id, product) {
        return await api.put('/product/' + id, product)
    },
    async delete(id) {
        return await api.delete('/product/' + id)
    }
}