<template>
  <div data-app class="container set-height">
    <v-btn class="ma-2" color="orange darken-2" dark small to="/invoice">
      <v-icon dark left> mdi-arrow-left </v-icon>Kembali
    </v-btn>
    <v-row>
      <v-col cols="12" md="4" class="m4">
        <v-card>
          <v-card-text>
            <v-card-title>Form Penjualan Barang</v-card-title>
            <v-text-field
              label="Nomor Invoice"
              v-model="note"
              disabled
            ></v-text-field>
            <v-text-field
              label="Operator Checkout"
              v-model="checkout_by"
            ></v-text-field>
            <v-text-field
              label="Barcode"
              v-model="barcode"
              v-on:keydown.enter.prevent="addToList"
              ref="barcode"
            ></v-text-field>
            <div class="text-center">
              <v-btn block color="success" @click.prevent="addToList">
                Tambah ke list
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="m8">
        <v-card>
          <v-card-text>
            <v-card-title
              >List Penjualan Barang Sementara #{{ note }}</v-card-title
            >
            <div class="my-2 d-flex flex-row-reverse">
              <v-btn color="primary" v-on:click="refresh" small>
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="width: 1%" class="text-left">#</th>
                    <th class="text-left">Nama Barang</th>
                    <th class="text-left">Berat</th>
                    <th class="text-left">Barcode</th>
                    <th class="text-left">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(invoice_item, index) in barcodes"
                    :key="invoice_item.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ invoice_item.product_name }}</td>
                    <td>{{ invoice_item.quantity }}</td>
                    <td>{{ invoice_item.barcode }}</td>
                    <td>
                      <button
                        class="btn btn-block"
                        @click.prevent="removeBarcode(invoice_item)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </tbody>
                <div class="d-flex">
                  <div class="my-2">
                    <v-btn color="primary" v-on:click="submit" small>
                      Submit
                      <v-icon>mdi-table-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiInvoice from "@/api/invoice.js";
import ApiInvoiceTemp from "@/api/invoiceTemp.js";
import moment from "moment";

export default {
  data() {
    return {
      invoices: [],
      id: "",
      invoiced_to: "",
      invoiced_at: "",
      address: "",
      discount: "",
      note: "",
      invoice: {
        invoiced_to: "",
        invoiced_at: "",
        address: "",
      },
      invoice_items: [],
      barcodes: [],
      barcode: "",
      invoice_id: "",
      invoice_name: "",
      list: "",
      headers: [
        {
          text: "Invoice Kepada",
          align: "start",
          sortable: false,
          value: "invoiced_to",
        },
        { text: "Alamat", value: "address" },
        { text: "Tanggal", value: "invoiced_at" },
        { text: "Nomor Invoice", value: "note" },
        { text: "Discount", value: "discount" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      check: "",
      checkout_by: "",
    };
  },
  mounted() {
    this.initInvoices(), this.focusInput(), this.initBarcodes();
  },
  methods: {
    initInvoices() {
      this.getInvoice(this.$route.params.id)
        .then((response) => response.json())
        .then((json) => {
          if (json.success != undefined && json.success == true) {
            this.invoice_id = json.invoice.id;
            this.invoiced_to = json.invoice.invoiced_to;
            this.invoiced_at = json.invoice.invoiced_at;
            this.discount = json.invoice.discount;
            this.address = json.invoice.address;
            this.note = json.invoice.note;
            this.listInvoices(json.invoice);
          }
        });
    },
    listInvoices(datas) {
      this.invoices.push({
        invoice_id: datas.id,
        invoiced_to: datas.invoiced_to,
        invoiced_at: datas.invoiced_at,
        discount: datas.discount,
        address: datas.address,
        note: datas.note,
      });
    },
    initBarcodes() {
      this.getInvoiceTemp(this.$route.params.id)
        .then((response) => response.json())
        .then((json) => {
          if (json.success != undefined && json.success == true) {
            this.barcodes = [];
            this.listBarcodes(json.data);
          } else {
            swal.error(json.message);
          }
        });
    },
    listBarcodes(datas) {
      Array.from(datas).forEach((el) => {
        if (el.raw_material_id == null || el.raw_material == null) {
          this.barcodes.push({
            id: el.id,
            invoice_id: el.invoice_id,
            product_item_id: el.product_item_id,
            quantity: el.quantity,
            price: el.price,
            barcode: el.barcode,
            product_name: el.product_item.name,
            type: "B",
          });
        } else {
          this.barcodes.push({
            id: el.id,
            invoice_id: el.invoice_id,
            product_item_id: el.raw_material_id,
            quantity: el.quantity,
            price: el.price,
            barcode: el.barcode,
            product_name: el.raw_material.name,
            type: "A",
          });
        }
      });
      console.log(datas);
    },
    addToList(event) {
      setTimeout(() => {
        if (event.keyCode != undefined && event.keyCode != 13) {
          return;
        }

        if (this.invoice_id == "") {
          swal.error("Silahkan pilih Invoice Header");
          return;
        }

        if (this.barcode == "") {
          swal.error("Barcode tidak boleh kosong!");
          return;
        }

        this.barcode = this.barcode.replace(/\s/g, "");
        var check = this.barcodes.some((b) => b.barcode == this.barcode);

        if (check == false) {
          ApiInvoiceTemp.getBarcode(this.barcode, this.checkout_by)
            .then((barcode) => barcode.json())
            .then((result) => {
              if (result.success != undefined && result.success == true) {
                console.log(result);
                let data = {
                  invoice_id: this.invoice_id,
                  product_item_id: result.data.product_id,
                  quantity: result.data.quantity,
                  price: result.data.price,
                  barcode: result.data.barcode,
                  type: result.type,
                };
                console.log(data);
                this.createInvoiceItem(data)
                  .then((add) => add.json())
                  .then((item) => {
                    console.log(item);
                    if (item.success != undefined && item.success == true) {
                      swal.success("Barang berhasil masuk ke list!");
                      this.barcodes.push({
                        product_name: result.data.product_name,
                        quantity: result.data.quantity,
                        barcode: result.data.barcode,
                        type: result.type,
                      });
                    } else {
                      swal.error(item.message);
                    }
                  });
              } else {
                swal.error(result.message);
              }
            });
        } else {
          swal.error("Barang sudah ada di list!");
        }
        this.barcode = "";
        this.focusInput();
      }, 500);
    },
    removeBarcode(data) {
      let response = confirm(`Are you sure you want to delete ${data.barcode}`);
      if (response) {
        this.deleteBarcode(data.barcode)
          .then((response) => response.json())
          .then((result) => {
            if (result.success != undefined && result.success == true) {
              this.barcodes = this.barcodes.filter(
                (i) => i.barcode != data.barcode
              );
              swal.success(result.message);
            } else {
              swal.error(result.message);
            }
          });
      } else {
        return;
      }
      this.focusInput();
    },
    submit() {
      if (this.barcodes == "") {
        swal.error("List Checkout Kosong!");
      } else if (this.checkout_by == "") {
        swal.error("Masukan Operator Checkout!");
      } else {
        this.barcodes.forEach((item) => {
          item.checkout_by = this.checkout_by;
        });
        let list = {
          invoice_id: this.invoice_id,
          barcodes: this.barcodes,
        };
        console.log(list);
        ApiInvoiceTemp.addBarcode(list)
          .then((result) => result.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.refresh();
              swal.success(json.message);
            } else {
              swal.error(json.message);
            }
          });
      }
    },
    refresh() {
      this.initBarcodes();
      this.focusInput();
    },
    reset() {
      let response = confirm(`Apakah anda yakin ingin menghapus semua list`);

      if (response) {
        this.barcodes = [];
      } else {
        return;
      }
      this.focusInput();
    },
    async getInvoice(id) {
      return await ApiInvoice.get(id);
    },
    async copyData(data) {
      return await ApiInvoiceTemp.copy(data);
    },
    async createInvoiceItem(data) {
      return await ApiInvoiceTemp.create(data);
    },
    async getInvoiceTemp(id) {
      return await ApiInvoiceTemp.getInvoiceId(id);
    },
    async deleteBarcode(barcode) {
      return await ApiInvoiceTemp.deleteBarcode(barcode);
    },
    focusInput() {
      this.$refs.barcode.focus();
    },
  },
  filters: {
    formattedDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>