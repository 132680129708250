import List from '@/views/product-item/List'
import BSJLog from '@/views/product-item/BSJLog'
import BJLog from '@/views/product-item/BJLog'
import BSJCheckin from '@/views/product-item/BSJCheckin'
import BJCheckin from '@/views/product-item/BJCheckin'
import Checkout from '@/views/product-item/Checkout'
import FilterBSJ from '@/views/product-item/FilterBSJ'
import FilterBJ from '@/views/product-item/FilterBJ'

export default [
    {
        name: 'ProductItem',
        path: '/product-item',
        component: List,
        meta: {
            title: 'Product Item List',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.BSJLog',
        path: '/product-item-bsj-log',
        component: BSJLog,
        meta: {
            title: 'Product Item BSJLog',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.BJLog',
        path: '/product-item-bj-log',
        component: BJLog,
        meta: {
            title: 'Product Item BJLog',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.BSJCheckin',
        path: '/product-item/bsj/checkin',
        component: BSJCheckin,
        meta: {
            title: 'Product Item BSJ Check In',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.BJCheckin',
        path: '/product-item/bj/checkin',
        component: BJCheckin,
        meta: {
            title: 'Product Item BJ Check In',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.Checkout',
        path: '/product-item/checkout',
        component: Checkout,
        meta: {
            title: 'Product Item BSJ Check Out',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.FilterBSJ',
        path: '/product-item/filterbsj',
        component: FilterBSJ,
        meta: {
            title: 'Filter Inventory Log BSJ',
            requiresAuth: true
        }
    },
    {
        name: 'ProductItem.FilterBJ',
        path: '/product-item/filterbj',
        component: FilterBJ,
        meta: {
            title: 'Filter Inventory Log BJ',
            requiresAuth: true
        }
    }


]