<template>
  <div data-app class="container set-height">
    <v-btn
      class="ma-2"
      color="orange darken-2"
      dark
      small
      to="/raw-material-log"
    >
      <v-icon dark left> mdi-arrow-left </v-icon>Kembali
    </v-btn>
    <v-row>
      <v-col cols="12" md="3" class="col-3 m4">
        <v-card>
          <v-card-text>
            <span class="card-title">Form Bahan Baku (BB) Keluar</span>
            <v-datetime-picker
              label="Tanggal Keluar"
              date-format="dd/MM/yyyy"
              time-format="HH:mm"
              v-model="date_out"
            >
            </v-datetime-picker>
            <v-text-field
              label="Operator Checkout"
              v-model="checkout_by"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              label="Scan Barcode"
              v-model="barcode"
              v-on:keydown.enter.prevent="addToList"
              ref="barcode"
            ></v-text-field>
            <div class="text-center">
              <v-btn
                block
                :loading="loading"
                :disabled="loading"
                color="success"
                @click.prevent="addToList"
              >
                Tambah ke list
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" class="m8">
        <v-card>
          <v-card-text>
            <span class="card-title">List Sementara Bahan Baku Keluar</span>
            <div class="my-2 d-flex flex-row-reverse">
              <v-btn color="error" v-on:click="reset" small>
                Hapus Semua
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Bahan Baku</th>
                    <th class="text-left">Berat</th>
                    <th class="text-left">Barcode</th>
                    <th class="text-left">Tanggal Keluar</th>
                    <th class="text-left">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(raw_material, index) in barcodes"
                    :key="raw_material.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ raw_material.raw_material.name }}</td>
                    <td>{{ raw_material.amount }}</td>
                    <td>{{ raw_material.barcode }}</td>
                    <!-- <td>{{ new Date(raw_material.date_out).toLocaleString() }}</td> -->
                    <td>{{ formattedDate(raw_material.date_out) }}</td>
                    <td>
                      <button
                        class="btn"
                        @click.prevent="removeBarcode(raw_material.barcode)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </tbody>
                <div class="d-flex">
                  <div class="my-2">
                    <v-btn
                      :loading="loading2"
                      :disabled="loading2"
                      color="primary"
                      v-on:click="submit"
                      small
                    >
                      Submit
                      <v-icon>mdi-table-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiRawMaterialLog from "@/api/rawMaterialLog.js";
import ApiRawMaterial from "@/api/rawMaterial.js";
import moment from "moment";

export default {
  data() {
    return {
      raw_matearials: [],
      id: "",
      amount: "",
      status: "",
      barcode: "",
      barcodes: [],
      barcodeRules: [
        (v) => !!v || "Barcode tidak boleh kosong",
        (v) => v.length >= 13 || "Barcode harus lebih dari 13 karakter",
      ],
      loading: false,
      loading2: false,
      date_out: new Date(),
      checkout_by: "",

      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    addToList(event) {
      this.loading = true;
      setTimeout(async () => {
        if (this.barcode == "") {
          swal.error("Barcode tidak boleh kosong!");
          this.loading = false;
          return;
        }

        if (event.keyCode != undefined && event.keyCode != 13) {
          this.loading = false;
          return;
        }
        if (this.date_out == "" || this.date_out == null) {
          this.date_out = new Date();
        }

        this.barcode = this.barcode.replace(/\s/g, "");
        var check = this.barcodes.some((b) => b.barcode == this.barcode);
        console.log(check);
        if (check == false) {
          await this.checkOutBarcode(this.barcode)
            .then((barcode) => barcode.json())
            .then((result) => {
              if (result.success != undefined && result.success == true) {
                swal.success("Bahan Baku berhasil masuk ke list!");
                this.barcodes.push({
                  id: result.data.id,
                  raw_material_id: result.data.raw_material_id,
                  barcode: result.data.barcode,
                  amount: result.data.amount,
                  raw_material: {
                    name: result.data.raw_material.name,
                  },
                  date_out: this.date_out,
                });
              } else {
                swal.error(result.message);
              }
            });
        } else {
          swal.error("Bahan Baku sudah ada di list!");
        }

        this.barcode = "";
        this.focusInput();
      }, 500);
      this.loading = false;
    },
    removeBarcode(barcode) {
      let response = confirm(`Are you sure you want to delete ${barcode}`);
      if (response) {
        this.barcodes = this.barcodes.filter((i) => i.barcode != barcode);
      } else {
        return;
      }
      this.focusInput();
    },
    listBarcode(datas) {
      Array.from(datas).forEach((el) => {
        this.barcodes.push({
          id: el.id,
          raw_material_id: el.raw_material_id,
          barcode: el.barcode,
          amount: el.amount,
          raw_material: {
            name: el.raw_material.name,
          },
        });
      });
    },
    submit(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.barcodes.length == 0) {
        swal.error("List Checkout Kosong!");
      } else if (this.checkout_by == "") {
        swal.error("Masukan Operator Checkout!");
      } else {
        this.loading2 = !this.loading2;
        this.barcodes.forEach((item) => {
          item.checkout_by = this.checkout_by;
        });
        let list = {
          checkout: this.barcodes,
        };
        ApiRawMaterialLog.addBarcode(list)
          .then((result) => result.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              swal.success(json.message);
              this.barcodes = [];
            } else {
              swal.error(json.message);
            }
          });
        this.loading2 = !this.loading2;
        this.focusInput();
      }
    },
    reset() {
      let response = confirm(`Apakah anda yakin ingin menghapus semua list`);

      if (response) {
        this.barcodes = [];
      } else {
        return;
      }
      this.focusInput();
    },
    async checkOutBarcode(barcode) {
      return await ApiRawMaterialLog.checkOutBarcode(barcode);
    },
    async getRawMaterial(id) {
      return await ApiRawMaterial.get(id);
    },
    focusInput() {
      this.$refs.barcode.focus();
    },
    formattedDate(value) {
      return moment(value).locale("id").format("DD MMMM YYYY HH:mm");
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
.btn {
  background-color: firebrick;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
</style>