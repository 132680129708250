<template>
  <div data-app class="container set-height">
    <v-row>
      <v-col cols="12" md="4" class="m4">
        <v-row>
          <v-col>
            <v-card>
              <v-tabs v-model="tab" background-color="blue" dark>
                <v-tab :key="add"> Form Barang Setengah Jadi (BSJ) </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item :key="add">
                  <v-card flat>
                    <v-card-text>
                      <v-autocomplete
                        :items="operators"
                        label="Pilih Operator"
                        v-model="operator_id"
                        item-text="name"
                        item-value="id"
                        @keyup.prevent="submitBSJ"
                      ></v-autocomplete>
                      <v-autocomplete
                        :items="product_items"
                        label="Pilih Barang"
                        v-model="product_item_id"
                        item-text="name"
                        item-value="id"
                        @keyup.prevent="submitBSJ"
                      ></v-autocomplete>
                      <v-text-field
                        label="Berat (Kg)"
                        v-model="amount"
                        @keyup.prevent="submitBSJ"
                      ></v-text-field>
                      <v-autocomplete
                        :items="stat"
                        label="Pilih Status"
                        v-model="status"
                        item-text="name"
                        item-value="id"
                        @keyup.prevent="submitBSJ"
                      ></v-autocomplete>
                      <v-datetime-picker
                        label="Tanggal Masuk"
                        date-format="dd/MM/yyyy"
                        time-format="HH:mm"
                        @keyup.prevent="submitBSJ"
                        v-model="date_in"
                      >
                      </v-datetime-picker>
                      <div v-if="this.id != ''">
                        <v-datetime-picker
                          label="Tanggal Keluar"
                          date-format="dd-MM-yyyy"
                          :disabled="status == `in`"
                          @keyup.prevent="submitBSJ"
                          v-model="date_out"
                        >
                        </v-datetime-picker>
                        <v-text-field
                          label="Barcode"
                          v-model="barcode"
                          @keyup.prevent="submitBSJ"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="text-center" v-if="this.id == ''">
                        <v-btn
                          block
                          :loading="loading"
                          :disabled="loading"
                          color="success"
                          @click.prevent="submitBSJ"
                        >
                          Tambah
                        </v-btn>
                      </div>
                      <div class="text-center" v-else>
                        <v-row>
                          <v-col>
                            <v-btn block color="error" @click.prevent="clear">
                              Batal
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              :loading="loading"
                              :disabled="loading"
                              color="warning"
                              @click.prevent="submitBSJ"
                            >
                              Ubah
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
          <v-col v-if="user.role_id <= 2">
            <v-card>
              <v-tabs v-model="tab2" background-color="blue" dark>
                <v-tab :key="reportOne"> Report </v-tab>
                <v-tab :key="reportTwo"> Rekap </v-tab>
                <v-tab :key="reportThree"> Produksi </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab2">
                <v-tab-item :key="reportOne">
                  <v-card flat>
                    <v-card-text>
                      <span class="card-title"
                        >Report Barang Setengah Jadi</span
                      >
                      <v-date-picker v-model="dates" range></v-date-picker>
                      <v-text-field
                        v-model="dateRangeText"
                        label="Jarak Tanggal"
                        prepend-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="report"
                      >
                        Export
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :key="reportTwo">
                  <v-card flat>
                    <v-card-text>
                      <span class="card-title">Recap Barang Setengah Jadi</span>
                      <v-date-picker v-model="dates" range></v-date-picker>
                      <v-text-field
                        v-model="dateRangeText"
                        label="Jarak Tanggal"
                        prepend-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="recap"
                      >
                        Export
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :key="reportThree">
                  <v-card flat>
                    <v-card-text>
                      <span class="card-title"
                        >Report Produksi Barang Setengah Jadi</span
                      >
                      <v-date-picker
                        label="Tanggal Produksi"
                        v-model="prodDate"
                      >
                      </v-date-picker>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="production"
                      >
                        Export
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" class="m8">
        <div>
          <v-card>
            <v-card-title>
              <v-btn
                small
                to="/product-item/checkout"
                class="ma-2"
                color="red"
                dark
                >BSJ Keluar
                <v-icon dark right>mdi-barcode-scan</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="query.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                v-on:keydown.enter.prevent="searchData"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                x-small
                fab
                class="mx-2"
                color="green"
                dark
                v-on:click="reload"
              >
                <v-icon dark center>mdi-cached</v-icon>
              </v-btn>
              <v-btn
                small
                to="/product-item/filterbsj"
                class="ma-2"
                color="warning"
                >Filter
                <v-icon right>mdi-filter</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="bsjLog"
              :server-items-length="query.total"
              :loading="loadingTable"
              class="elevation-1"
              hide-default-footer
              :items-per-page="query.per_page"
              :page="query.page"
              :sort-desc.sync="options.sort_desc"
              :sort-by.sync="options.sort_by"
            >
              <template v-slot:[`item.date_in`]="{ item }">
                <span>{{ formattedDate(item.date_in) }}</span>
              </template>
              <template v-slot:[`item.date_out`]="{ item }">
                <span v-if="item.date_out == null || item.date_out == ''"
                  >-</span
                >
                <span v-else>{{ formattedDate(item.date_out) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="changeProcessedMaterial(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small @click="removeProcessedMaterial(item)">
                  mdi-delete
                </v-icon>
                <v-icon small @click="print(item.id)"> mdi-printer </v-icon>
              </template>
              <template v-slot:no-data>
                <span> Data belum ada </span>
              </template>
            </v-data-table>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="2" class="py-2">
                  <v-select
                    v-model="query.per_page"
                    :items="perPages"
                    label="Tampilkan Data"
                    @change="handlePerPageChange"
                  ></v-select>
                </v-col>
                <v-col
                  cols="10"
                  class="align-self-center py-2 d-flex justify-end"
                >
                  <v-pagination
                    v-model="query.page"
                    :length="query.last_page"
                    total-visible="7"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                    @input="handlePageChange"
                  ></v-pagination>
                  <v-chip
                    class="align-self-center ma-2"
                    label
                    color="orange"
                    text-color="white"
                  >
                    <span class="font-weight-bold">
                      Total {{ query.total }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiProcessedMaterialLog from "@/api/processedMaterialLog.js";
import ApiProductItem from "@/api/productItem.js";
import ApiProduct from "@/api/product.js";
import ApiUser from "@/api/user.js";
import ApiOperator from "@/api/operator.js";
import Axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      logs: [],
      product_item_id: "",
      amount: "",
      stat: ["in", "retur"],
      status: "in",
      checkout_by: "",
      processed_times: 1,
      barcode: "",
      id: "",
      operator_id: "",
      operator: {
        name: "",
      },
      user: {},
      product_item: {
        name: "",
        product: {
          name: "",
        },
      },
      product_items: [],
      prodDate: new Date().toISOString().substr(0, 10),
      headers: [
        {
          text: "Barang Setengah Jadi",
          align: "start",
          value: "product_item.name",
          width: "30%",
        },
        { text: "Operator", value: "operator.name" },
        { text: "Berat", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Tanggal Masuk", value: "date_in" },
        { text: "Tanggal Keluar", value: "date_out" },
        { text: "Dikeluarkan", value: "checkout_by" },
        { text: "Barcode", value: "barcode" },
        { text: "Dicetak", value: "print_count", align: "center" },
        { text: "Actions", value: "actions", sortable: false, width: "10px" },
      ],
      tab: null,
      tab2: null,
      reportOne: null,
      reportTwo: null,
      reportThree: null,
      add: null,
      loading: false,
      loadingTable: false,
      operators: [],
      datetime: new Date(),
      date_in: new Date(),
      date_out: "",
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      query: {
        page: 1,
        per_page: 10,
        search: "",
        total: 0,
        last_page: 0,
      },
      perPages: [10, 20, 50, 100, 200],
      options: {
        sort_by: "date_in",
        sort_desc: true,
      },
    };
  },
  created() {
    if (this.bsjLog?.length == 0) {
      this.initProductItemLogs();
    } else {
      this.listProductItemLogs(this.bsjLog);
      this.query.page = this.bsjPagination.page;
      this.query.per_page = Number(this.bsjPagination.per_page);
      this.query.total = this.bsjPagination.total;
      this.query.last_page = this.bsjPagination.last_page;
    }
    this.user = JSON.parse(this.getUser);
    this.initProductItems();
    this.initOperators();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    ...mapGetters([
      "isLoggedIn",
      "getUser",
      "bsjLog",
      "bsjPagination",
      "getServer",
    ]),
  },
  methods: {
    ...mapActions(["fetchBSJLog"]),
    async initProductItemLogs() {
      this.loadingTable = true;
      const payload = {
        query: this.query,
        options: this.options,
      };
      await this.fetchBSJLog(payload);
      this.listProductItemLogs(this.bsjLog);
      this.query.page = this.bsjPagination.page;
      this.query.per_page = Number(this.bsjPagination.per_page);
      this.query.total = this.bsjPagination.total;
      this.query.last_page = this.bsjPagination.last_page;

      this.loadingTable = false;
    },
    initProductItems() {
      this.getProductItems()
        .then((response) => response.json())
        .then((json) => {
          this.product_items = [];
          if (json.success != undefined && json.success == true) {
            this.listProductItems(json.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initOperators() {
      this.getOperators()
        .then((response) => response.json())
        .then((json) => {
          this.operators = [];
          if (json.success != undefined && json.success == true) {
            this.listOperators(json.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listOperators(datas) {
      Array.from(datas).forEach((el) => {
        this.operators.push({
          id: el.id,
          name: el.name,
        });
      });
    },
    listProductItemLogs(datas) {
      this.logs = [];
      Array.from(datas).forEach((el) => {
        this.logs.push({
          id: el.id,
          product_item_id: el.product_item_id,
          user_id: el.user_id,
          amount: el.amount,
          status: el.status,
          barcode: el.barcode,
          processed_times: el.processed_times,
          created_at: el.created_at,
          updated_at: el.updated_at,
          operator_id: el.operator_id,
          date_in: el.date_in,
          date_out: el.date_out,
          product_item: {
            name: el.product_item.name,
            product: {
              name: el.product_item.product.name,
            },
          },
          user: {
            name: el.user.name,
          },
          operator: {
            name: el.operator.name,
          },
        });
      });
    },
    listProductItems(datas) {
      Array.from(datas).forEach((el) => {
        this.product_items.push({
          id: el.id,
          name: el.name,
          description: el.description,
          price: el.price,
          rate: el.rate,
          product_id: el.product_id,
          product: {
            name: el.product.name,
          },
          created_at: el.created_at,
          updated_at: el.updated_at,
        });
      });
    },
    submitBSJ(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.status == "") {
        swal.error("Silahkan masukkan status");
      }
      this.loading = !this.loading;
      var date_out = "";
      if (this.date_out == null) {
        date_out = "";
      } else {
        date_out = this.date_out;
      }

      let log = {
        product_item_id: this.product_item_id,
        user_id: this.user.id,
        amount: this.amount,
        status: this.status,
        processed_times: 1,
        operator_id: this.operator_id,
        date_in: this.date_in,
        date_out: date_out,
        product_item: {
          name: this.product_item.name,
        },
      };

      if (this.id == "") {
        this.addProcessedMaterialLog(log)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.query.page = 1;
              this.initProductItemLogs();
              swal.success(json.message);
              this.clear();
            } else {
              swal.error(json.message);
            }
          });
      } else {
        this.editProcessedMaterialLog(this.id, log)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.initProductItemLogs();
              swal.success(json.message);
              this.clear();
            } else {
              swal.error(json.message);
            }
          });
      }
      this.loading = !this.loading;
    },
    changeProcessedMaterial(data) {
      this.date_in = new Date(data.date_in);
      this.date_out = data.date_out == null ? "" : new Date(data.date_out);
      console.log(this.date_out);
      this.product_item_id = data.product_item_id;
      this.amount = data.amount;
      this.status = data.status;
      this.barcode = data.barcode;
      this.processed_times = data.processed_times;
      this.id = data.id;
      this.product_item.name = data.product_item.name;
      this.product_item.product.name = data.product_item.product.name;
      this.operator_id = data.operator_id;
      this.operator.name = data.operator.name;
    },
    removeProcessedMaterial(data) {
      let response = confirm(
        `Are you sure you want to delete log with barcode: ${data.barcode}`
      );
      if (response) {
        this.deleteProcessedMaterialLog(data.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              
              this.initProductItemLogs();
              swal.success(json.message);
            }
          });
      } else {
        return;
      }
    },
    print(id) {
      this.printBarcode(id)
        .then((response) => response.json())
        .then((json) => {
          if (json.success != undefined && json.success == true) {
            this.initProductItemLogs();
            swal.success("Berhasil");
          }
        });
    },
    report(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.dates[0] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      if (this.dates[1] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      this.loading = !this.loading;
      const apiURL = "http://" + this.getServer + ":3000" || "";

      Axios.get(
        apiURL +
          "/processedmateriallog/report/bsj?start=" +
          this.dates[0] +
          "&end=" +
          this.dates[1],
        { responseType: "blob" }
      )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "report_bsj.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
      this.loading = !this.loading;
    },
    recap(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.dates[0] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      if (this.dates[1] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      this.loading = !this.loading;
      const apiURL = "http://" + this.getServer + ":3000" || "";

      Axios.get(
        apiURL +
          "/processedmateriallog/rekap/bsj?start=" +
          this.dates[0] +
          "&end=" +
          this.dates[1],
        { responseType: "blob" }
      )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "rekap_bsj.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
      this.loading = !this.loading;
    },
    production(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.prodDate == null) {
        swal.error("Tanggal harus diisi!");
        return;
      }

      this.loading = !this.loading;
      const apiURL = "http://" + this.getServer + ":3000" || "";

      Axios.get(
        apiURL +
          "/processedmateriallog/report/production?date=" +
          this.prodDate,
        { responseType: "blob" }
      )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "report_produksi.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
      this.loading = !this.loading;
    },
    searchData() {
      this.query.page = 1;
      this.initProductItemLogs();
    },
    reload() {
      this.query.page = 1;
      this.query.per_page = 10;
      this.query.search = "";
      this.initProductItemLogs();
    },
    handlePageChange(value) {
      this.query.page = value;
      this.initProductItemLogs();
    },
    handlePerPageChange(value) {
      this.query.per_page = value;
      this.initProductItemLogs();
    },
    async getProcessedMaterialLogs() {
      return await ApiProcessedMaterialLog.all(this.query, this.options);
    },
    async getProcessedMaterialLogsBSJ() {
      return await ApiProcessedMaterialLog.allBSJ(this.query, this.options);
    },
    async addProcessedMaterialLog(log) {
      return await ApiProcessedMaterialLog.create(log);
    },
    async editProcessedMaterialLog(id, log) {
      return await ApiProcessedMaterialLog.put(id, log);
    },
    async deleteProcessedMaterialLog(id) {
      return await ApiProcessedMaterialLog.delete(id);
    },
    async getProductItems() {
      const type = "BSJ";
      return await ApiProductItem.all(type);
    },
    async getProductItem(id) {
      return await ApiProductItem.get(id);
    },
    async getProduct(id) {
      return await ApiProduct.get(id);
    },
    async getUserAll() {
      return await ApiUser.all();
    },
    async printBarcode(id) {
      return await ApiProcessedMaterialLog.print(id);
    },
    async getReport(start, end) {
      return await ApiProcessedMaterialLog.report(start, end);
    },
    async getRecap(start, end) {
      return await ApiProcessedMaterialLog.recap(start, end);
    },
    async getProduction(date) {
      return await ApiProcessedMaterialLog.production(date);
    },
    async getOperators() {
      return await ApiOperator.all();
    },
    async getOperator(id) {
      return await ApiOperator.get(id);
    },
    async getProductItemsBSJ() {
      return await ApiProductItem.bsj();
    },
    submit_coba(event) {
      if (event.keyCode == 13) {
        swal.success("Mantap");
      }
    },
    formatDate(date) {
      date = "2020-09-01T00:00:00.000Z";
      var ms = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "Sepeptember",
        "Oktober",
        "November",
        "Desember",
      ];
      return (
        date.getDate() + " " + ms[date.getMonth()] + " " + date.getFullYear()
      );
    },
    clear() {
      this.id = "";
      this.product_item_id = "";
      this.amount = "";
      this.status = "in";
      this.processed_times = 1;
      this.date_in = new Date();
      this.date_out = "";
      this.barcode = "";
      this.operator_id = "";
    },
    formattedDate(value) {
      return moment(value).locale("id").format("DD-MM-YYYY HH:mm");
    },
  },
  watch: {
    options: {
      handler() {
        if (this.options.sort_by) {
          this.initProductItemLogs();
        } else {
          this.options.sort_by = "";
          this.options.sort_desc = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>
