import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/invoice')
    },
    async get(id) {
        return await api.get('/invoice/' + id)
    },
    async create(invoice) {
        return await api.post('/invoice', invoice)
    },
    async put(id, invoice) {
        return await api.put('/invoice/' + id, invoice)
    },
    async delete(id) {
        return await api.delete('/invoice/' + id)
    },
    async export(id) {
        return await api.get('/invoice/export/' + id)
    }
}