import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'
import Login from '../views/Login.vue';
import Home from '../views/Home.vue'
import Role from '../views/role/List.vue'
import User from '../views/user/List.vue'
import Supplier from '../views/supplier/List.vue'
import Product from '../views/product/List.vue'
import ProductCategory from '../views/product/Category.vue'
import rawmaterial from '../routes/rawmaterial'
import productitem from '../routes/productitem'
import invoice from '../routes/invoice'
import operator from '../routes/operator'
import stockOpname from '../routes/stockOpname'

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/role',
    name: 'Role',
    component: Role,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/supplier',
    name: 'Supplier',
    component: Supplier,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product-category',
    name: 'Category Product',
    componet: ProductCategory,
    meta: {
      requiresAuth: true,
    }
  },
  ...rawmaterial,
  ...productitem,
  ...invoice,
  ...operator,
  ...stockOpname
]

let router = new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router
