<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form Operator</span>
                         <v-text-field
                            label="Nama Operator"
                            v-model="name"
                            @keyup.prevent="submitOperator"
                        ></v-text-field>
                        <div v-if="(this.id == '')">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="submitOperator"
                                >
                                Tambah
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-col>
                                <v-row>
                                    <v-col class="pl-0 py-0">
                                        <v-btn
                                            block
                                            color="error"
                                            @click.prevent="clear"
                                            >
                                            Batal
                                        </v-btn>
                                    </v-col>
                                    <v-col class="pr-0 py-0">
                                        <v-btn
                                            block
                                            color="warning"
                                            @click.prevent="submitOperator"
                                            >
                                            Ubah
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                            <v-btn x-small fab class="mx-2" color="green" dark v-on:click="reload">
                            <v-icon dark center>mdi-cached</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="operators"
                        :search="search"
                        sort-by="id"
                        class="elevation-1"
                    >
                        <!-- <template v-slot:[`item`]="{ item }" scope="props">
                            <td>{{ props.index }}</td>
                            <td class="text-xs-left">{{ props.item.name }}</td>
                            <td class="text-xs-left">{{ props.item.username }}</td>
                        </template> -->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small @click="changeOperator(item)" class="warning ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn small @click="removeOperator(item)" class="error ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                            <v-btn small @click="print(item.id)" class="secondary ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-printer
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initOperators"
                        >
                            Reset
                        </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiOperator from '@/api/operator.js'


export default {
    data() {
        return {
            operators: [],
            name: '',
            id: '',
            headers: [
                {
                    text: 'ID Operator',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Name', value: 'name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
            selected: [],
        }
    },
    mounted() {
        this.initOperators()
    },
    methods: {
        initOperators() {
            this.getOperators()
            .then(response => response.json())
            .then(json => {
                this.operators = []
                if( json.success != undefined && json.success == true ) {
                    this.listOperators(json.data)
                }
            })
            .catch(() => {
                
            })
        },
        listOperators(datas) {
            Array.from(datas).forEach(el => {
                this.operators.push({
                    id: el.id,
                    name: el.name,
                })
            })
        },
        submitOperator(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            let Operator = {
                name: this.name
            }

            if(this.id == '') {
                this.addOperator(Operator)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.operators.push({
                            id: json.data.id,
                            name: json.data.name
                        })
                        this.name = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
            }else{
                let id = this.id    
                let name = this.name

                this.editOperator(id, Operator)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.operators.forEach(u => {
                            if(u.id == id) {
                                u.name = name
                            }
                        })
                        this.name = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
            }
            
        },
        changeOperator(data) {
            this.name = data.name,
            this.id = data.id

        },
        removeOperator(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.deleteOperator(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.initOperators()
                    }
                })
            }
        },
        reload() {
            this.initOperators()
        },
        async getOperators() {
            return await ApiOperator.all()
        },
        async addOperator(Operator) {
            return await ApiOperator.create(Operator)
        },
        async editOperator(id, Operator) {
            return await ApiOperator.put(id, Operator)
        },
        async deleteOperator(id) {
            return await ApiOperator.delete(id)
        },
        async print(id) {
            return await ApiOperator.print(id)
        },
        submit(event) {
            if(event.keyCode == 13) {
                swal.success('Mantap')
            }
        },
        clear() {
            this.id = ''
            this.name = ''
        }
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>