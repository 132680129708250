import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/operator')
    },
    async get(id) {
        return await api.get('/operator/' + id)
    },
    async create(operator) {
        return await api.post('/operator', operator)
    },
    async put(id, operator) {
        return await api.put('/operator/' + id, operator)
    },
    async delete(id) {
        return await api.delete('/operator/' + id)
    },
    async print(id) {
        return await api.get('/operator/print/'+ id)
    },
    async postName(name) {
        return await api.post('/operator/name/', name)
    }
}