<template>
  <v-container class="set-height">
    <div class="row">
      <div class="col">
        <v-card>
          <v-card-text>
            <v-card-title v-if="isLoggedIn">
              Selamat datang {{ user.name }}!
            </v-card-title>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      cookie: 'tes'
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getUser"]),
    user() {
      return JSON.parse(this.getUser)
    }
  }
}
</script>


<style>
    .set-height {
        min-height: 90vh;
    }
</style>