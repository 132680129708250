import swal from 'sweetalert'

export default {
    error(text) {
        swal({
            title: 'Gagal',
            text: text,
            icon: 'error',
            buttons: false,
            timer: 1500
        })
    },
    success(text) {
        swal({
            title: 'Berhasil',
            text: text,
            icon: 'success',
            buttons: false,
            timer: 1500
        })
    }
}