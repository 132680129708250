import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from './store'
// import VueCookies from 'vue-cookies'

Vue.use(vuetify);

new Vue({
  router,
  vuetify,
  store,
  // VueCookies,
  components: { App },
  template: "<App/>",
  render: h => h(App),
}).$mount('#app')

// Vue.$cookies.config('7d');
