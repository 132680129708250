<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <v-card-title>Form Item Invoice</v-card-title>
                        <!-- <label for="invoice_id">Pilih Invoice</label>
                        <div>
                            <select id="invoice_id" class="browser-default" autofocus v-model="invoice_id" required>
                                <option value="" disabled>Pilih Invoice Header</option>
                                <option v-for="invoice in invoices" :value="invoice.id" :key="invoice.id">{{ invoice.note }}</option>
                            </select>
                        </div> -->

                        <v-select
                            :items="invoices"
                            label="Pilih Invoice Header"
                            v-model="invoice_id"
                            item-text="note"
                            item-value="id"
                        ></v-select>
                        <v-text-field
                            label="Barcode"
                            v-model="barcode"
                            v-on:keydown.enter.prevent="addToList"
                            ref="barcode"
                        ></v-text-field>
                        <div class="text-center">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="addToList"
                                >
                                Tambah ke list
                            </v-btn>
                        </div>
                        <!-- <div class="input-field">
                            <input 
                            id="barcode"
                            type="text"  
                            class="validate" 
                            v-model="barcode" 
                            autofocus 
                            @keyup.prevent="addToList"
                            />
                            <label for="barcode">Masukkan Barcode</label>
                        </div> 
                        <div>
                            <button class="btn btn-block" @click.prevent="addToList">Tambah ke List</button>
                        </div> -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-text>
                        <v-card-title>List Checkout Barang {{ invoice_id }}</v-card-title>
                        <div class="my-2 d-flex flex-row-reverse">
                            <v-btn
                                color="error"
                                v-on:click="reset"
                                small
                            >
                                Reset
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;" class="text-left">#</th>
                                        <th class="text-left">Nama Barang</th>
                                        <th class="text-left">Berat</th>
                                        <th class="text-left">Barcode</th>
                                        <th class="text-left">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(invoice_item, index) in barcodes" :key="invoice_item.id">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ invoice_item.product_name  }}</td>
                                        <td>{{ invoice_item.amount }}</td>
                                        <td>{{ invoice_item.barcode }}</td>
                                        <td>
                                            <button class="btn btn-block" @click.prevent="removeBarcode(invoice_item)">Hapus</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <div class="d-flex">
                                    <div class="my-2">
                                        <v-btn
                                            color="primary"
                                            v-on:click="submit"
                                            small
                                        >
                                            Submit
                                            <v-icon>mdi-table-plus</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiProductItem from '@/api/productItem.js'
import ApiProduct from '@/api/product.js'
import ApiInvoice from '@/api/invoice.js'
import ApiInvoiceItem from '@/api/invoiceItem.js'
import moment from 'moment'
import Axios from 'axios'

export default {
    data() {
        return {
            invoices: [],
            id: '',
            invoiced_to: '',
            invoiced_at: '',
            address: '',
            discount: '',
            note: '',
            invoice: {
                invoiced_to: '',
                invoiced_at: '',
                address: '',
            },
            invoice_items: [],
            barcodes: [],
            barcode: '',
            invoice_id: '',
            invoice_name: '',
            list: '',
            headers: [
                {
                text: 'Invoice Kepada',
                align: 'start',
                sortable: false,
                value: 'invoiced_to',
                },
                { text: 'Alamat', value: 'address' },
                { text: 'Tanggal', value: 'invoiced_at' },
                { text: 'Nomor Invoice', value: 'note' },
                { text: 'Discount', value: 'discount' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
            check: '',
        }
    },
    mounted() {
        this.initInvoices(),
        this.initProductItems(),
        this.initProduct(),
        this.focusInput()
    },
    methods: {
        initProductItems() {
            this.getProductItems()
            .then(response => response.json())
            .then(json => {
                this.product_items = []
                if( json.success != undefined && json.success == true ) {
                    this.listProductItems(json.data)
                }
            })
            .catch(() => {
                
            })
        },
        initProduct() {
            this.getProducts()
            .then(response => response.json())
            .then(json => {
                this.products = []
                if( json.success != undefined && json.success == true ) {
                    this.listProducts(json.data)
                }
            })
            .catch(() => {

            })
        },
        listProductItems(datas) {
            Array.from(datas).forEach(el => {
                this.product_items.push({
                    id: el.id,
                    name: el.name,
                    description: el.description,
                    price: el.price,
                    rate: el.rate,
                    product_id: el.product_id,
                    product: {
                        name: el.product.name
                    },
                    created_at: el.created_at,
                    updated_at: el.updated_at,
                })
            })
        },
        listProducts(datas) {
            Array.from(datas).forEach(el => {
                this.products.push({
                    id: el.id,
                    name: el.name,
                    description: el.description,
                })
            })
        },
        initInvoices() {
            this.getInvoices()
            .then(response => response.json())
            .then(json => {
                this.invoices = []
                if( json.success != undefined && json.success == true ) {
                    this.listInvoices(json.data)
                }
            })
            .catch(() => {

            })
        },        
        listInvoices(datas) {
            Array.from(datas).forEach(el => {
                this.invoices.push({
                    id: el.id,
                    invoiced_to: el.invoiced_to,
                    invoiced_at: el.invoiced_at,
                    discount: el.discount,
                    address: el.address,
                    note: el.note
                })
            })
        },
        changeInvoice(data) {
            this.id = data.id,
            this.invoiced_to = data.invoiced_to,
            this.invoiced_at = data.invoiced_at,
            this.address = data.address,
            this.note = data.note,
            this.discount = data.discount
        },
        removeInvoice(data) {
            let response = confirm(`Are you sure you want to delete invoice ${data.note} to ${data.invoiced_to} ${data.invoiced_at}`)
            if(response) {
                this.invoices = this.invoices.filter(i => i.id != data.id)
                this.deleteInvoice(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.listInvoices(json.data)
                    }
                })
            }else{
                return
            }
        },
        submitInvoice(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            let invoice = {
                invoiced_to: this.invoiced_to,
                invoiced_at: this.invoiced_at,
                address: this.address,
                note: this.note,
                discount: this.discount
                // product: {
                //     name: this.product.name
                // }
            }
            
            console.log(invoice)
            if(this.id == '') {
                this.addInvoices(invoice)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.invoices.push({
                            id: json.data.id,
                            invoiced_to: json.data.invoiced_to,
                            invoiced_at: json.data.invoiced_at,
                            address: json.data.address,
                            note: json.data.note,
                            discount: json.data.discount
                        })
                        this.invoiced_to = '',
                        this.invoiced_at = '',
                        this.address = '',
                        this.note = '',
                        this.discount = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
            }else{
                let id = this.id
                let invoiced_to = this.invoiced_to
                let invoiced_at = this.invoiced_at
                let address = this.address
                let note = this.note
                let discount = this.discount
                this.editInvoice(this.id, invoice)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.invoices.forEach(p => {
                            if(p.id == id) {
                                p.invoiced_to = invoiced_to,
                                p.invoiced_at = invoiced_at,
                                p.address = address,
                                p.note = note,
                                p.discount = discount
                            }
                        })
                        this.invoiced_to = '',
                        this.invoiced_at = '',
                        this.address = '',
                        this.note = '',
                        this.discount = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
            }
        },
        addToList(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            if( this.invoice_id == '' ) {
                swal.error("Silahkan pilih Invoice Header")
                return
            }

            if( this.barcode == '' ) {
                swal.error('Barcode tidak boleh kosong!')
                return
            }

            this.barcode = this.barcode.replace(/\s/g, '')
            var check = this.barcodes.some(b => b.barcode == this.barcode)
            if(check == false) {
                this.checkBarcode(this.barcode)
                .then(barcode => barcode.json())
                .then(result => {
                    if( result.success != undefined && result.success == true ) {
                        swal.success("Barang berhasil masuk ke list!")
                        this.barcodes.push({
                            product_name: result.data.product_item.name,
                            amount: result.data.amount,
                            barcode: result.data.barcode,
                        })
                    } else {
                        swal.error(result.message)
                    }
                })
            } else {
                swal.error("Barang sudah ada di list!")

            }
            this.barcode = ''
        },
        removeBarcode(data) {
            let response = confirm(`Are you sure you want to delete ${data.barcode}`)
            if(response) {
                this.barcodes = this.barcodes.filter(i => i.barcode != data.barcode)
            }else{
                return
            }
        },
        submit(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }
            
            if( this.barcodes == '' ) {
                swal.error("List Checkout Kosong!")
                return
            }

            let list = {
                invoice_id: this.invoice_id,
                barcodes: this.barcodes
            }
            this.addBarcode(list)
            .then(result => result.json())
            .then(json => {
                if(json.success != undefined && json.success == true) { 
                    swal.success(json.message)
                    this.$router.push('/invoice')  
                } else {
                    swal.error(json.message)
                }
            })
            this.barcodes = ''
        },
        reset() {
            let response = confirm(`Apakah anda yakin ingin menghapus semua list`)
            
            if(response) {
                this.barcodes = []
            }else{
                return
            }
            this.focusInput()
        },
        print(id) {
            let inv_id = id
            console.log(inv_id)
            Axios.get('http://localhost:3000/invoice/export/' + inv_id, { responseType: 'blob', headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Methods': '*',
                'Content-type': 'application/json',
            } })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'invoice.xlsx'
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch( error => {
                swal.error(error)
            })

            // this.excelInvoice(inv_id)
            // .then(response => response.json())
            // .then(json => {
            //     console.log(json)
            // })
        },
        detailInvoice(data) {
          this.$router.push({ name: 'Invoice.Item', params: {id: data.id} })
        },
        async getProductItems() {
            return await ApiProductItem.all()
        },
        async addProductItem(product_item) {
            return await ApiProductItem.create(product_item)
        },
        async editProductItem(id, product_item) {
            return await ApiProductItem.put(id, product_item)
        },
        async deleteProductItem(id) {
            return await ApiProductItem.delete(id)
        },
        async getProducts() {
            return await ApiProduct.all()
        },
        async getProduct(id) {
            return await ApiProduct.get(id)
        },
        async getInvoices() {
            return await ApiInvoice.all()
        },
        async getInvoice(id) {
            return await ApiInvoice.get(id)
        },
        async addInvoices(invoice) {
            return await ApiInvoice.create(invoice)
        },
        async editInvoice(id, invoice) {
            return await ApiInvoice.put(id, invoice)
        },
        async deleteInvoice(id) {
            return await ApiInvoice.delete(id)
        },
        async excelInvoice(id) {
            return await ApiInvoice.export(id)
        },
        async checkBarcode(barcode) {
            return await ApiInvoiceItem.getBarcode(barcode)
        },
        async addBarcode(data) {
            return await ApiInvoiceItem.addBarcode(data)
        },
        focusInput() {
           this.$refs.barcode.focus();
        }
    },
    filters: {
        formattedDate(value) {
            return moment(value).format('DD-MM-YYYY')
        }
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>