import List from '@/views/invoice/List'
import Item from '@/views/invoice/Item'
import Checkout from '@/views/invoice/Checkout'
import Temp from '@/views/invoice/CheckoutTemp'

export default [
    {
        name: 'Invoice',
        path: '/invoice',
        component: List,
        meta: {
            title: 'Invoice Header List',
            requiresAuth: true
        }
    },
    {
        name: 'Invoice.Checkout',
        path: '/invoice/checkout',
        component: Checkout,
        meta: {
            title: 'Invoice Checkout',
            requiresAuth: true
        }
    },
    {
        name: 'Invoice.Item',
        path: '/invoice/:id',
        component: Item,
        meta: {
            title: 'Invoice Item',
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'Invoice.CheckoutTemp',
        path: '/invoice/checkout/:id',
        component: Temp,
        meta: {
            title: 'Invoice Checkout Temp',
            requiresAuth: true
        },
        params: true
    }
]