<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer v-model="sidebar" app>
        <v-list>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>Logout</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar>
        <span class="hidden-sm-and-up">
          <v-app-bar-nav-icon @click="sidebar = !sidebar"> </v-app-bar-nav-icon>
        </span>
        <v-toolbar-title>
          <router-link to="/home" tag="span" style="cursor: pointer">
            {{ appTitle }}
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only" v-if="isLoggedIn">
          <v-menu offset-y v-if="user.role_id == 1">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left dark>supervisor_account</v-icon>
                User
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-subheader>User</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn text :to="'/user'" class="text-capitalize">
                      <v-icon left dark>mdi-account</v-icon>
                      Manage User
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-subheader>Operator</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn text :to="'/operator'" class="text-capitalize">
                      <v-icon left dark>recent_actors</v-icon>
                      Manage Operator
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-menu offset-y>
            <template>
              <v-btn> User </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y v-if="user.role_id <= 2">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left dark>mdi-database</v-icon>
                Master Data
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-subheader>Bahan Baku</v-subheader>
                <v-divider></v-divider>

                <v-list-item key="raw-material">
                  <v-list-item-title>
                    <v-btn text :to="'/raw-material'" class="text-capitalize">
                      <v-icon left dark>mdi-package-variant-closed</v-icon>
                      Manage Produk BB
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      :to="'/raw-material-category'"
                      class="text-capitalize"
                    >
                      <v-icon left dark>mdi-layers</v-icon>
                      Manage Kategori BB
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item key="supplier">
                  <v-list-item-title>
                    <v-btn text :to="'/supplier'" class="text-capitalize">
                      <v-icon left dark>mdi-truck</v-icon>
                      Manage Supplier BB
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-subheader>Barang Setengah Jadi dan Jadi</v-subheader>
                <v-divider></v-divider>

                <v-list-item key="product-item">
                  <v-list-item-title>
                    <v-btn text :to="'/product-item'" class="text-capitalize">
                      <v-icon left dark>mdi-package-variant-closed</v-icon>
                      Manage Produk BSJ &amp; BJ
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item key="product">
                  <v-list-item-title>
                    <v-btn text :to="'/product'" class="text-capitalize">
                      <v-icon left dark>mdi-layers</v-icon>
                      Manage Kategori BSJ &amp; BJ
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu offset-y v-if="user.role_id <= 2">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left dark>mdi-home-city-outline</v-icon>
                Inventory
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-subheader>Bahan Baku</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      :to="'/raw-material-log'"
                      class="text-capitalize"
                    >
                      <v-icon left dark>mdi-home-import-outline</v-icon>
                      Log BB
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-subheader>Barang Setengah Jadi</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      :to="'/product-item-bsj-log'"
                      class="text-capitalize"
                    >
                      <v-icon left dark>mdi-home-minus</v-icon>
                      Log BSJ
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-subheader>Barang Jadi</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      :to="'/product-item-bj-log'"
                      class="text-capitalize"
                    >
                      <v-icon left dark>mdi-home-export-outline</v-icon>
                      Log BJ
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu offset-y v-if="user.role_id <= 2">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left dark>mdi-receipt</v-icon>
                Invoice
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-subheader>Invoice</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn text :to="'/invoice/'" class="text-capitalize">
                      <v-icon left dark>payment</v-icon>
                      Manage Invoice
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu offset-y v-if="user.role_id <= 2">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left dark>mdi-home-city-outline</v-icon>
                Opname
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-subheader>Stock Opname</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn text :to="'/stock-opname'" class="text-capitalize">
                      <v-icon left dark>mdi-calendar-weekend</v-icon>
                      Manage Stock Opname
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-btn v-if="user.role_id == 3" text :to="'/raw-material-log'">
            <v-icon left dark>mdi-home-import-outline</v-icon>
            Barang Baku
          </v-btn>

          <v-btn
            v-if="user.role_id == 4"
            text
            :to="'/product-item/bsj/checkin'"
          >
            <v-icon left dark>mdi-home-minus</v-icon>
            Barang Setengah Jadi Masuk
          </v-btn>

          <v-btn v-if="user.role_id == 5" text :to="'/product-item/bj/checkin'">
            <v-icon left dark>mdi-home-export-outline</v-icon>
            Barang Jadi Masuk
          </v-btn>

          <v-btn text v-on:click="logout">
            <v-icon left dark>mdi-exit-to-app</v-icon>
            Logout
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld";
import swal from "@/utils/alert.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      supplier: "",
      produk: "",
      invoice: "",
      invHead: "",
      invItem: "",
      useroperator: "",
      master: "",
      log: "",
      inv: "",
      appTitle: "Inventory and Invoice",
      sidebar: false,
      menuItems: [
        { title: "User", path: "/user", icon: "mdi-account" },
        { title: "Operator", path: "/operator", icon: "recent_actors" },
        { title: "Supplier", path: "/supplier", icon: "mdi-truck" },
        { title: "Bahan Baku", path: "/raw-material", icon: "all_inbox" },
        { title: "Product", path: "/product", icon: "mdi-layers" },
        {
          title: "Barang",
          path: "/product-item",
          icon: "mdi-package-variant-closed",
        },
        {
          title: "BB LOG",
          path: "/raw-material-log",
          icon: "mdi-home-import-outline",
        },
        {
          title: "BSJ LOG",
          path: "/product-item-bsj-log",
          icon: "mdi-home-minus",
        },
        {
          title: "BJ LOG",
          path: "/product-item-bj-log",
          icon: "mdi-home-export-outline",
        },
        { title: "Invoice", path: "/invoice", icon: "mdi-receipt" },
      ],
      isLogin: true,
      user: {},
    };
  },
  async created() {
    await this.setUser();
    if (this.getUser) {
      this.isLogin = true;
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getUser"]),
  },
  methods: {
    ...mapActions(["keluar", "deleteServer"]),
    async logout() {
      await this.keluar();
      // await this.deleteServer()
      swal.success("Logout Success");
      this.$router.push({ name: "Login" });
    },
    setUser() {
      this.user = JSON.parse(this.getUser);
    },
  },
  watch: {
    getUser: {
      handler() {
        this.setUser();
      },
      deep: true,
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 20px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
