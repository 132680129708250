import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/rawmaterialcategory')
    },
    async get(id) {
        return await api.get('/rawmaterialcategory/' + id)
    },
    async create(rawmaterial) {
        return await api.post('/rawmaterialcategory', rawmaterial)
    },
    async put(id, rawmaterial) {
        return await api.put('/rawmaterialcategory/' + id, rawmaterial)
    },
    async delete(id) {
        return await api.delete('/rawmaterialcategory/' + id)
    }
}