<template>
  <div class="container set-height">
    <v-row>
      <v-col cols="1" md="4" class="m4">
        <v-row>
          <v-col>
            <v-card>
              <v-tabs v-model="tab" background-color="blue" dark>
                <v-tab :key="addOne"> Tambah Satu </v-tab>
                <v-tab :key="addTwo" v-if="id == 0 || id == ''">
                  Tambah Banyak
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :key="addOne">
                  <v-card flat>
                    <v-card-text>
                      <v-autocomplete
                        :items="raw_materials"
                        label="Pilih Bahan Baku"
                        v-model="raw_material_id"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                      <v-text-field
                        label="Berat (Kg)"
                        v-model="amount"
                      ></v-text-field>
                      <v-autocomplete
                        :items="stat"
                        label="Pilih Status"
                        v-model="status"
                        :item-value="status"
                        disabled
                      ></v-autocomplete>
                      <v-datetime-picker
                        dateFormat="dd-MM-yyyy"
                        label="Tanggal Masuk"
                        v-model="date_in"
                      >
                      </v-datetime-picker>
                      <div v-if="this.id != ''">
                        <v-datetime-picker
                          v-model="date_out"
                          dateFormat="dd-MM-yyyy"
                          :disabled="status == `in`"
                          @change="datePicker = false"
                          label="Tanggal Keluar"
                        ></v-datetime-picker>
                        <v-text-field
                          label="Barcode"
                          v-model="barcode"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="text-center" v-if="this.id == ''">
                        <v-btn
                          block
                          :loading="loading"
                          :disabled="loading"
                          color="success"
                          @click.prevent="submit"
                        >
                          Tambah
                        </v-btn>
                      </div>
                      <div class="text-center" v-else>
                        <v-row>
                          <v-col>
                            <v-btn block color="error" @click.prevent="clear">
                              Batal
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              :loading="loading"
                              :disabled="loading"
                              color="warning"
                              @click.prevent="submit"
                            >
                              Ubah
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :key="addTwo" v-if="id == 0 || id == ''">
                  <v-card flat>
                    <v-card-text>
                      <v-autocomplete
                        :items="raw_materials"
                        label="Pilih Bahan Baku"
                        v-model="raw_material_id"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                      <v-text-field
                        label="Berat"
                        v-model="amount_array"
                      ></v-text-field>
                      <v-text-field
                        label="Jumlah"
                        v-model="count"
                      ></v-text-field>
                      <v-datetime-picker
                        label="Tanggal Masuk"
                        date-format="dd/MM/yyyy"
                        v-model="date_in"
                      >
                      </v-datetime-picker>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="submits"
                      >
                        Tambah
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
          <v-col v-if="user.role_id <= 2">
            <v-card>
              <v-tabs v-model="tab2" background-color="blue" dark>
                <v-tab :key="reportOne"> Report BB </v-tab>
                <v-tab :key="reportTwo"> Rekapitulasi BB </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab2">
                <v-tab-item :key="reportOne">
                  <v-card flat>
                    <v-card-text>
                      <v-date-picker v-model="dates" range></v-date-picker>
                      <v-text-field
                        v-model="dateRangeText"
                        label="Jarak Tanggal"
                        prepend-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="report"
                      >
                        Export
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :key="reportTwo">
                  <v-card flat>
                    <v-card-text>
                      <v-date-picker v-model="dates" range></v-date-picker>
                      <v-text-field
                        v-model="dateRangeText"
                        label="Jarak Tanggal"
                        prepend-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                      <v-btn
                        block
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click.prevent="recap"
                      >
                        Export
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <div data-app>
          <v-card>
            <v-card-title>
              <v-btn
                small
                :to="'/raw-material/checkout'"
                class="ma-2"
                color="red"
                dark
                >Bahan Baku Keluar
                <v-icon dark right>mdi-barcode-scan</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="query.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                v-on:keydown.enter.prevent="searchData"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                x-small
                fab
                class="mx-2"
                color="green"
                dark
                v-on:click="reload"
              >
                <v-icon dark center>mdi-cached</v-icon>
              </v-btn>
              <v-btn
                small
                to="/raw-material/filter"
                class="ma-2"
                color="warning"
                >Filter
                <v-icon right>mdi-filter</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="logs"
              :server-items-length="query.total"
              :loading="loadingTable"
              class="elevation-1"
              hide-default-footer
              :items-per-page="query.per_page"
              :page="query.page"
              :sort-desc.sync="options.sort_desc"
              :sort-by.sync="options.sort_by"
            >
              <template v-slot:[`item.date_in`]="{ item }">
                <span>{{ formattedDate(item.date_in) }}</span>
              </template>
              <template v-slot:[`item.date_out`]="{ item }">
                <span v-if="item.date_out == null || item.date_out == ''"
                  >-</span
                >
                <!-- <span v-else>{{new Date(item.date_out).toLocaleString()}}</span> -->
                <span v-else>{{ formattedDate(item.date_out) }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="changeRawMaterialLog(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="removeRawMaterialLog(item)">
                  mdi-delete
                </v-icon>
                <v-icon small @click="print(item.id)"> mdi-printer </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initRawMaterialLogs">
                  Reload
                </v-btn>
              </template>
            </v-data-table>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="2" class="py-2">
                  <v-select
                    v-model="query.per_page"
                    :items="perPages"
                    label="Tampilkan Data"
                    @change="handlePerPageChange"
                  ></v-select>
                </v-col>
                <v-col
                  cols="10"
                  class="align-self-center py-2 d-flex justify-end"
                >
                  <v-pagination
                    v-model="query.page"
                    :length="query.last_page"
                    total-visible="7"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                    @input="handlePageChange"
                  ></v-pagination>
                  <v-chip
                    class="align-self-center ma-2"
                    label
                    color="orange"
                    text-color="white"
                  >
                    <span class="font-weight-bold">
                      Total {{ query.total }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiRawMaterialLog from "@/api/rawMaterialLog.js";
import ApiUser from "@/api/user.js";
import ApiRawMaterial from "@/api/rawMaterial.js";
import ApiSupplier from "@/api/supplier.js";
import Axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      datePicker: false,
      logs: [],
      raw_material_id: "",
      amount: "",
      stat: ["in", "out", "sale"],
      status: "in",
      barcode: "",
      date_in: new Date(),
      date_out: "",
      checkout_by:"",
      id: "",
      user: {},
      raw_material: {
        name: "",
        supplier: {
          name: "",
        },
      },
      raw_materials: [],
      amount_array: "",
      count: "",
      barcodes: [],
      headers: [
        {
          text: "Bahan Baku",
          align: "start",
          value: "raw_material.name",
          width: "20%",
        },
        { text: "Berat", value: "amount", width: "3%" },
        { text: "Status", value: "status", width: "3%" },
        { text: "Tanggal Masuk", value: "date_in", width: "20%" },
        { text: "Tanggal Keluar", value: "date_out", width: "20%" },
        { text: "Dikeluarkan", value: "checkout_by", width: "10%" },
        { text: "Barcode", value: "barcode" },
        { text: "Dicetak", value: "print_count", align: "center" },
        { text: "Aksi", value: "actions", sortable: false, width: "10px" },
      ],
      tab: null,
      tab2: null,
      reportOne: null,
      reportTwo: null,
      addOne: null,
      addTwo: null,
      loading: false,
      loadingTable: false,
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      query: {
        page: 1,
        per_page: 10,
        search: "",
        total: 0,
        last_page: 0,
      },
      perPages: [5, 10, 20, 50, 100, 200],
      options: {
        sort_by: "date_in",
        sort_desc: true,
      },
    };
  },
  created() {
    if (this.rawMaterialLog?.length == 0) {
      this.initRawMaterialLogs();
    } else {
      this.listRawMaterialLogs(this.rawMaterialLog);
      this.query.page = this.rawMaterialLogPagination.page;
      this.query.per_page = Number(this.rawMaterialLogPagination.per_page);
      this.query.total = this.rawMaterialLogPagination.total;
      this.query.last_page = this.rawMaterialLogPagination.last_page;
    }
    this.initRawMaterials();
    this.user = JSON.parse(this.getUser);
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    computedDateFormattedMomentjs() {
      return this.date_out
        ? moment(this.date_out).format("DD-MM-yyyy HH:mm")
        : "";
    },
    ...mapGetters([
      "isLoggedIn",
      "getUser",
      "rawMaterialLog",
      "rawMaterialLogPagination",
      "getServer",
    ]),
  },
  methods: {
    ...mapActions(["fetchRawMaterialLog"]),
    async initRawMaterialLogs() {
      this.loadingTable = true;
      const payload = {
        query: this.query,
        options: this.options,
      };
      await this.fetchRawMaterialLog(payload);
      this.listRawMaterialLogs(this.rawMaterialLog);
      this.query.page = this.rawMaterialLogPagination.page;
      this.query.per_page = Number(this.rawMaterialLogPagination.per_page);
      this.query.total = this.rawMaterialLogPagination.total;
      this.query.last_page = this.rawMaterialLogPagination.last_page;

      this.loadingTable = false;
    },
    initRawMaterials() {
      this.getRawMaterials()
        .then((response) => response.json())
        .then((json) => {
          this.raw_materials = [];
          if (json.success != undefined && json.success == true) {
            this.listRawMaterials(json.data);
          }
        });
    },
    listRawMaterialLogs(datas) {
      this.logs = [];
      Array.from(datas).forEach((el) => {
        this.logs.push({
          id: el.id,
          raw_material_id: el.raw_material_id,
          user_id: el.user_id,
          amount: el.amount,
          status: el.status,
          barcode: el.barcode,
          created_at: el.created_at,
          updated_at: el.updated_at,
          date_in: el.date_in,
          date_out: el.date_out,
          checkout_by: el.checkout_by,
          print_count: el.print_count,
          raw_material: {
            name: el.raw_material.name,
            supplier: {
              name: el.raw_material?.supplier?.name,
            },
          },
          user: {
            name: el.user.name,
          },
        });
      });
    },
    listRawMaterials(datas) {
      Array.from(datas).forEach((el) => {
        this.raw_materials.push({
          id: el.id,
          name: el.name,
          description: el.description,
          price: el.price,
          supplier: {
            name: el.supplier?.name,
          },
        });
      });
    },
    submit(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }
      if (/^[0-9.]+$/.test(this.amount)) {
        if (Number(this.amount) < 1) {
          swal.error("Berat harus lebih dari 0");
          return;
        }
        this.loading = !this.loading;
        var date_out = this.date_out;

        let log = {
          raw_material_id: this.raw_material_id,
          user_id: this.user.id,
          amount: this.amount,
          status: this.status,
          barcode: this.barcode,
          raw_material: {
            name: this.raw_material.name,
          },
          date_in: this.date_in,
          date_out: date_out,
        };

        if (this.id == "") {
          this.addRawMaterialLog(log)
            .then((response) => response.json())
            .then((json) => {
              if (json.success != undefined && json.success == true) {
                this.query.page = 1;
                this.initRawMaterialLogs();
                swal.success(json.message);
                this.clear();
              } else {
                swal.error(json.message);
              }
              this.loading = !this.loading;
            });
        } else {
          this.editRawMaterialLog(this.id, log)
            .then((response) => response.json())
            .then((json) => {
              if (json.success != undefined && json.success == true) {
                this.initRawMaterialLogs();
                swal.success(json.message);
                this.clear();
              } else {
                swal.error(json.message);
              }
              this.loading = !this.loading;
            });
        }
      } else {
        swal.error("Berat harus berupa angka!");
      }
    },
    changeRawMaterialLog(data) {
      console.log(data.date_out);
      this.date_in = new Date(data.date_in);
      this.date_out = data.date_out == null ? "" : new Date(data.date_out);

      this.raw_material_id = data.raw_material_id;
      this.user_id = data.user_id;
      this.amount = data.amount;
      this.status = data.status;
      this.barcode = data.barcode;
      this.id = data.id;
      this.user.name = data.user.name;
      this.raw_material.name = data.raw_material.name;
      this.raw_material.supplier.name = data.raw_material.supplier.name;
    },
    removeRawMaterialLog(data) {
      let response = confirm(
        `Are you sure you want to delete log with barcode: ${data.barcode}`
      );
      if (response) {
        this.deleteRawMaterialLog(data.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.initRawMaterialLogs();
              swal.success(json.message);
            }
          });
      } else {
        return;
      }
    },
    print(id) {
      this.printBarcode(id)
        .then((response) => response.json())
        .then((json) => {
          if (json.success != undefined && json.success == true) {
            this.initRawMaterialLogs();
            swal.success("Berhasil");
          }
        });
    },
    submits(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }
      if (/^[0-9.]+$/.test(this.amount_array)) {
        if (Number(this.amount_array) < 1) {
          swal.error("Berat harus lebih dari 0");
          return;
        }
        if (Number(this.count) < 1) {
          swal.error("Jumlah harus lebih dari 0");
          return;
        }
        this.loading = !this.loading;
        let log = {
          raw_material_id: this.raw_material_id,
          user_id: this.user.id,
          amount: this.amount_array,
          status: "in",
          count: this.count,
          date_in: this.date_in,
        };

        this.addArray(log)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.query.page = 1;
              this.initRawMaterialLogs();
              swal.success("Log berhasil ditambahkan");
              this.date_in = new Date();
              this.raw_material_id = "";
              this.amount_array = "";
              this.status = "";
              this.count = "";
            } else {
              swal.error(json.massage);
            }
            this.loading = !this.loading;
          });
      } else {
        swal.error("Berat harus berupa angka!");
      }
    },
    report(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.dates[0] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      if (this.dates[1] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      this.loading = !this.loading;
      const apiURL = "http://" + this.getServer + ":3000" || "";

      Axios.get(
        apiURL +
          "/rawmateriallog/report/bb?start=" +
          this.dates[0] +
          "&end=" +
          this.dates[1],
        {
          responseType: "blob",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", "report_bb.xlsx");
          // link.download = 'report_bb.xlsx'
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
      this.loading = !this.loading;
    },
    recap(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.dates[0] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      if (this.dates[1] == null) {
        swal.error("Range tanggal salah");
        return;
      }

      this.loading = !this.loading;
      const apiURL = process.env.VUE_APP_SERVICE || ''
    //   const apiURL = "http://" + this.getServer + ":3000" || "";

      Axios.get(
        apiURL +
          "/rawmateriallog/rekap/bb?start=" +
          this.dates[0] +
          "&end=" +
          this.dates[1],
        {
          responseType: "blob",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "recap_bb.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
      this.loading = !this.loading;
    },
    searchData() {
      this.query.page = 1;
      this.initRawMaterialLogs();
    },
    reload() {
      this.query.page = 1;
      this.query.per_page = 10;
      this.query.search = "";
      this.initRawMaterialLogs();
    },
    handlePageChange(value) {
      this.query.page = value;
      this.initRawMaterialLogs();
    },
    handlePerPageChange(value) {
      this.query.per_page = value;
      this.initRawMaterialLogs();
    },
    async getRawMaterialLogs() {
      return await ApiRawMaterialLog.all(this.query, this.options);
    },
    async addRawMaterialLog(raw_material) {
      return await ApiRawMaterialLog.create(raw_material);
    },
    async editRawMaterialLog(id, raw_material) {
      return await ApiRawMaterialLog.put(id, raw_material);
    },
    async deleteRawMaterialLog(id) {
      return await ApiRawMaterialLog.delete(id);
    },
    async getRawMaterials() {
      return await ApiRawMaterial.all();
    },
    async getRawMaterial(id) {
      return await ApiRawMaterial.get(id);
    },
    async getSuppliers() {
      return await ApiSupplier.all();
    },
    async getSupplier(id) {
      return await ApiSupplier.get(id);
    },
    async getUsers() {
      return await ApiUser.all();
    },
    async printBarcode(id) {
      return await ApiRawMaterialLog.print(id);
    },
    async addArray(log) {
      return await ApiRawMaterialLog.createMany(log);
    },
    async getReport(start, end) {
      return await ApiRawMaterialLog.report(start, end);
    },
    async getRecap(start, end) {
      return await ApiRawMaterialLog.recap(start, end);
    },
    success(event) {
      if (event.keyCode == 13) {
        swal.success("Mantap");
      }
    },
    formatDate(date) {
      // date = '2020-09-01T00:00:00.000Z'
      var ms = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "Sepeptember",
        "Oktober",
        "November",
        "Desember",
      ];
      return (
        date.getDate() + " " + ms[date.getMonth()] + " " + date.getFullYear()
      );
    },
    formattedDate(value) {
      return moment(value).locale("id").format("DD-MM-YYYY HH:mm");
    },
    clear() {
      this.id = "";
      this.raw_material_id = "";
      this.amount = "";
      this.status = "";
      this.date_in = new Date();
      this.date_out = "";
      this.barcode = "";
    },
  },
  watch: {
    options: {
      handler() {
        if (this.options.sort_by) {
          this.initRawMaterialLogs();
        } else {
          this.options.sort_by = "";
          this.options.sort_desc = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>