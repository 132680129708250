// const apiURL = process.env.VUE_APP_SERVICE || ''
// import store from '../store'

export default {
    apiURL() {
        return 'https://api.inventori.sekolahdesain.id/'
    },
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': '*',
    },
    async post(url, body, additionalHeaders) {
        this.addHeaders(additionalHeaders)
        return this._fetch(url, 'POST', body)
    },
    async put(url, body, additionalHeaders) {
        this.addHeaders(additionalHeaders)
        return this._fetch(url, 'PUT', body)
    },
    async delete(url, additionalHeaders) {
        this.addHeaders(additionalHeaders)
        return this._fetch(url, 'DELETE')
    },
    async get(url, additionalHeaders) {
        this.addHeaders(additionalHeaders)
        return await fetch(this.apiURL() + url, this.headers)
    },
    async _fetch(url, method, body) {
        try {
            const res =  await fetch(this.apiURL() + url, {
                    method,
                    credentials: "same-origin",
                    headers: this.headers,
                    body: JSON.stringify(body)
                })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject('Tidak dapat terhubung dengan server')
        }
    },
    addHeaders(additionalHeaders) {
        for (let key in additionalHeaders) {
            this.headers[key] = additionalHeaders[key]
        }
    }
}