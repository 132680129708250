import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/invoiceitem')
    },
    async get(id) {
        return await api.get('/invoiceitem/' + id)
    },
    async create(invoiceitem) {
        return await api.post('/invoiceitem', invoiceitem)
    },
    async put(id, invoiceitem) {
        return await api.put('/invoiceitem/' + id, invoiceitem)
    },
    async delete(id) {
        return await api.delete('/invoiceitem/' + id)
    },
    async getBarcode(barcode) {
        return await api.get('/invoiceitem/barcode/' + barcode)
    },
    async addBarcode(invoiceitem) {
        return await api.post('/invoiceitem/add/', invoiceitem)
    },
    async getInvoiceId(id) {
        return await api.get('/invoiceitem/invoice/' + id)
    },
    async returnInvoiceItem(barcode) {
        return await api.get('/invoiceitem/return/' + barcode)
    }
}