import Vuex from 'vuex'
import Vue from 'vue'
import Auth from './Auth'
import RawItems from './RawItems'
import ProcessedItems from './ProcessedItems'
import Opname from './Opname'

//load Vuex
Vue.use(Vuex);

//export store module
export default new Vuex.Store({
    modules: {
        Auth,
        RawItems,
        ProcessedItems,
        Opname,
    }
})