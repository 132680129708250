<template>
  <v-container class="set-height">
    <div class="text-h5">{{ title_page }}</div>
    <div class="text-subtitle-1">{{ subtitle_page }}</div>
    <v-card class="mt-5">
      <v-data-table
        hide-default-footer
        disable-pagination
        :show-select="isUnscanned"
        :headers="computedHeaders"
        :items="listData"
        :search="search"
        v-model="selected"
        sort-by="number"
        class="elevation-1 mt-5"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="text-button orange px-2 rounded white--text"
              >Total = {{ listData.length }}</span
            >
            <v-btn
              v-if="$route.query.type == `unscanned`"
              class="text-button white--text ms-4"
              color="teal"
              :disabled="selected.length == 0"
              @click="dialogCheckout = true"
              >Checkout ({{selected.length}})</v-btn
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              label="Search"
              class="me-2"
            ></v-text-field>
          </v-toolbar>
        </template>

        <v-spacer></v-spacer>

        <template v-slot:[`item.date_in`]="{ item }">
          <span>{{ formattedDate(item.date_in) }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="500px" v-model="dialogOperator">
      <v-card>
        <v-card-title class="text-h5">Masukan Nama Operator Scan</v-card-title>
        <v-card-actions>
          <v-text-field
            autofocus
            clearable
            persistent
            text-center
            class="mx-3 text-center"
            v-on:keydown.enter.prevent="closeDialog"
            v-model="scanned_by"
          ></v-text-field>
          <v-btn
            color="blue darken-1 mx-auto text-button"
            dark
            @click="closeDialog"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="dialogCheckout">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="lighten-2"
            >Checkout ({{ title_page }})
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogCheckout = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-radio-group class="mx-5 mt-5" v-model="radioCheckout" column>
          <v-radio label="Checkout Produksi / Out" value="out"></v-radio>
          <v-radio label="Checkout Jual / Sale" value="sale"></v-radio>
        </v-radio-group>
        <v-text-field
          class="mx-5"
          label="Operator Checkout"
          v-model="checkout_by"
        >
        </v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="checkout_by.length == 0"
            text
            color="red"
            @click="doCheckout(radioCheckout)"
            >CHECKOUT</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>



<script>
import swal from "@/utils/alert.js";
import ApiStockOpname from "@/api/stockOpname.js";
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      dialogAction: false,
      dialogOperator: false,
      dialogDelete: false,
      dialogCheckout: false,
      radioCheckout: "out",
      datePicker: false,
      isUnscanned: false,
      selected: [],
      listData: [],
      headers: [
        { text: "Nomor", value: "number", align: "center" },
        { text: "Discan Oleh", value: "scanned_by", align: "start" },
        { text: "Barcode", value: "barcode", align: "center" },
        { text: "Berat (Kg)", value: "amount", align: "center" },
        { text: "Tanggal Masuk", value: "date_in", align: "center" },
        { text: "Status", value: "status" },
        { text: "Dikeluarkan", value: "checkout_by", align: "center" },
      ],

      title_page: "",
      subtitle_page: "",
      search: "",
      barcode: "",
      scanned_by: null,
      stock_opname_id: this.$route.params.id,
      checkout_by: "",

      opnameLog: {
        id: 0,
        number: 0,
        name: "",
        barcode: "",
        amount: "",
        date_in: "",
      },

      defaultopnameLog: {
        id: 0,
        number: 0,
        name: "",
        barcode: "",
        amount: "",
        date_in: "",
      },
    };
  },
  created() {
    this.initOpnameLog();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.opnameLog.started_at
        ? moment(this.opnameLog.started_at).format("DD-MM-YYYY")
        : "";
    },
    formTitle() {
      return this.opnameLog.id === 0
        ? "Tambah Stock Opname"
        : "Edit Stock Opname";
    },
    computedHeaders() {
      const type = this.$route.query.type;
      if (type == "scanned") {
        return this.headers.filter((item) => item.text != "Status");
      } else {
        return this.headers;
      }
    },
  },
  methods: {
    async initOpnameLog() {
      const param = this.$route.params.id;
      const type = this.$route.query.type;
      if (type === "scanned") {
        this.isUnscanned = false;
        this.initScanned(param);
        this.subtitle_page = "Barcode yang telah di scan";
      } else if (type === "unscanned") {
        this.isUnscanned = true;
        this.initUnscanned(param);
        this.subtitle_page = "Barcode yang Belum di scan";
      } else {
        swal.error("halaman tidak ditemukan");
      }
    },
    async initScanned(id) {
      await ApiStockOpname.allProductLog(id)
        .then((data) => data.json())
        .then((result) => {
          if (result.success == true) {
            const name =
              result.data[0].stock_opname_product.raw_material != null
                ? result.data[0].stock_opname_product.raw_material.name
                : result.data[0].stock_opname_product.product_item.name;
            this.title_page = name;
            this.listData = [];
            result.data.forEach((item, position) => {
              this.listData.push({
                number: position + 1,
                // name: name,
                ...item,
              });
            });
          } else {
            swal.error(result.message);
          }
        })
        .catch((error) => {
          swal.error(error);
        });
    },

    async initUnscanned(id) {
      await ApiStockOpname.allUnscannedLog(id)
        .then((response) =>
          response
            .json()
            .then((result) => {
              if (result.success == true) {
                if (result.data.length > 0) {
                  const name =
                    result.data[0].raw_material != null
                      ? result.data[0].raw_material.name
                      : result.data[0].product_item.name;
                  this.title_page = name;
                }

                this.listData = [];
                result.data.forEach((item, position) => {
                  this.listData.push({
                    number: position + 1,
                    // name: name,
                    ...item,
                  });
                });
              } else {
                this.listData = [];
                swal.error(result.message);
              }
            })
            .catch((error) => {
              this.listData = [];
              console.log("error \n" + error);
              swal.error("Data tidak ditemukan");
            })
        )
        .catch((error) => {
          this.listData = [];
          console.log("error \n" + error);
          swal.error("Data tidak ditemukan");
        });
    },

    getColorDiff(countDiff) {
      if (countDiff < -20) {
        return "red ";
      } else if (countDiff < -10) {
        return "orange";
      } else if (countDiff < 0) {
        return "yellow  darken-1";
      } else if (countDiff == 0) {
        return "grey";
      } else {
        return "black";
      }
    },

    async doScan() {
      if (this.barcode.length < 10) {
        swal.error("barcode tidak ditemukan");
      } else {
        if (this.scanned_by == null) {
          swal.error("Masukan Nama Operator Scan!");
        } else {
          console.log(this.stock_opname_id);
          console.log(this.scanned_by);
          console.log(this.barcode);
          await ApiStockOpname.doScanOpname(
            this.stock_opname_id,
            this.scanned_by,
            this.barcode
          )
            .then((response) => {
              response
                .json()
                .then((result) => {
                  if (result.success) {
                    swal.success(result.message);
                    this.initOpnameLog();
                    this.barcode = "";
                  } else {
                    swal.error(result.message);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  swal.error(err);
                });
            })
            .catch((err) => {
              console.error(err);
              swal.error(err);
            });
        }
      }
    },
    upperCase() {
      this.barcode = this.barcode.toUpperCase();
    },

    showDialogOperator(item) {
      this.opnameLog = Object.assign({}, item);
      this.dialogOperator = true;
    },

    showDialogUpdate(item) {
      this.opnameLog = Object.assign({}, item);
      this.dialog = true;
    },

    showDialogDelete(item) {
      this.opnameLog = Object.assign({}, item);
      this.dialogDelete = true;
    },

    updateItem() {
      const id = this.opnameLog.id;
      const body = {
        note: this.opnameLog.note,
      };
      ApiStockOpname.update(id, body)
        .then((response) => {
          response.json().then((result) => {
            if (result.success) {
              swal.success(result.message);
              this.initOpnameLog();
            } else {
              swal.error(result.message);
            }
            this.closeDialog();
          });
        })
        .catch((err) => {
          swal.error(err);
          this.closeDialog();
        });
    },
    doCheckout(type) {
      const containCheckout = this.selected.filter(
        (item) => item.status == "sale" || item.status == "out"
      );

      if (containCheckout.length > 0) {
        swal.error("Pilih hanya Barcode dengan status : IN ");
      } else {
        const id = this.$route.params.id;
        const barcodes = this.selected.map((it) => it.barcode);
        const body = {
          checkout_by: this.checkout_by,
          barcodes: barcodes,
        };
        ApiStockOpname.doCheckout(id, type, body)
          .then((response) =>
            response
              .json()
              .then((result) => {
                if (result.success) {
                  this.closeDialog();
                  this.initOpnameLog();
                  swal.success(result.message);
                } else {
                  swal.error(result.message);
                }
              })
              .catch((error) => {
                console.error(error);
                swal.error(error);
              })
          )
          .catch((error) => {
            console.error(error);
            swal.error(error);
          });
      }
    },

    closeDialog() {
      this.dialog = false;
      this.dialogOperator = false;
      this.dialogDelete = false;
      this.dialogCheckout = false;
      this.$nextTick(() => {
        this.opnameLog = Object.assign({}, this.defaultopnameLog);
      });
    },
    formattedDate(date) {
      return moment(date).format("DD-MM-yyyy HH:mm");
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>