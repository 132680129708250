var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.sidebar),callback:function ($$v) {_vm.sidebar=$$v},expression:"sidebar"}},[_c('v-list',[_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.path}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_vm._v(_vm._s(item.title))])],1)}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_vm._v("Logout")])],1)],2)],1),_c('v-toolbar',[_c('span',{staticClass:"hidden-sm-and-up"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.sidebar = !_vm.sidebar}}})],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":"/home","tag":"span"}},[_vm._v(" "+_vm._s(_vm.appTitle)+" ")])],1),_c('v-spacer'),(_vm.isLoggedIn)?_c('v-toolbar-items',{staticClass:"hidden-xs-only"},[(_vm.user.role_id == 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("supervisor_account")]),_vm._v(" User ")],1)]}}],null,false,859337434)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("User")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/user'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-account")]),_vm._v(" Manage User ")],1)],1)],1),_c('v-subheader',[_vm._v("Operator")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/operator'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("recent_actors")]),_vm._v(" Manage Operator ")],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""}},[[_c('v-btn',[_vm._v(" User ")])]],2),(_vm.user.role_id <= 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-database")]),_vm._v(" Master Data ")],1)]}}],null,false,975976673)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Bahan Baku")]),_c('v-divider'),_c('v-list-item',{key:"raw-material"},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/raw-material'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-package-variant-closed")]),_vm._v(" Manage Produk BB ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/raw-material-category'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-layers")]),_vm._v(" Manage Kategori BB ")],1)],1)],1),_c('v-list-item',{key:"supplier"},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/supplier'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-truck")]),_vm._v(" Manage Supplier BB ")],1)],1)],1),_c('v-subheader',[_vm._v("Barang Setengah Jadi dan Jadi")]),_c('v-divider'),_c('v-list-item',{key:"product-item"},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/product-item'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-package-variant-closed")]),_vm._v(" Manage Produk BSJ & BJ ")],1)],1)],1),_c('v-list-item',{key:"product"},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/product'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-layers")]),_vm._v(" Manage Kategori BSJ & BJ ")],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.user.role_id <= 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-city-outline")]),_vm._v(" Inventory ")],1)]}}],null,false,2495999082)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Bahan Baku")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/raw-material-log'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-import-outline")]),_vm._v(" Log BB ")],1)],1)],1),_c('v-subheader',[_vm._v("Barang Setengah Jadi")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/product-item-bsj-log'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-minus")]),_vm._v(" Log BSJ ")],1)],1)],1),_c('v-subheader',[_vm._v("Barang Jadi")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/product-item-bj-log'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-export-outline")]),_vm._v(" Log BJ ")],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.user.role_id <= 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-receipt")]),_vm._v(" Invoice ")],1)]}}],null,false,3513252197)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Invoice")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/invoice/'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("payment")]),_vm._v(" Manage Invoice ")],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.user.role_id <= 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-city-outline")]),_vm._v(" Opname ")],1)]}}],null,false,4212422712)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Stock Opname")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","to":'/stock-opname'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-calendar-weekend")]),_vm._v(" Manage Stock Opname ")],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.user.role_id == 3)?_c('v-btn',{attrs:{"text":"","to":'/raw-material-log'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-import-outline")]),_vm._v(" Barang Baku ")],1):_vm._e(),(_vm.user.role_id == 4)?_c('v-btn',{attrs:{"text":"","to":'/product-item/bsj/checkin'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-minus")]),_vm._v(" Barang Setengah Jadi Masuk ")],1):_vm._e(),(_vm.user.role_id == 5)?_c('v-btn',{attrs:{"text":"","to":'/product-item/bj/checkin'}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-home-export-outline")]),_vm._v(" Barang Jadi Masuk ")],1):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-exit-to-app")]),_vm._v(" Logout ")],1)],1):_vm._e()],1),_c('v-main',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }