import ProcessedItemsApi from '../api/processedMaterialLog'

const state = {
    processedMaterialLog: [],
    processedMaterialPagination: {},
    bsjLog: [],
    bjLog: [],
    bsjPagination: {},
    bjPagination: {},
    loadingProcessedMaterial: false,
}

const getters = {
    processedMaterialLog(state) {
        return state.processedMaterialLog
    },
    processedMaterialPagination(state) {
        return state.processedMaterialPagination
    },
    bsjLog(state) {
        return state.bsjLog
    },
    bsjPagination(state) {
        return state.bsjPagination
    },
    bjLog(state) {
        return state.bjLog
    },
    bjPagination(state) {
        return state.bjPagination
    },
    loadingProcessedMaterial(state) {
        return state.loadingProcessedMaterial
    },
}

const actions = {
    async fetchProcessedMaterialLog({commit}, payload) {
        commit('setLoadingProcessedMaterial', true)
        await ProcessedItemsApi.all(payload.query, payload.options)
        .then(data => data.json())
        .then(res => {
            commit('setProcessedMaterialLog', res.data)
            const pagination = {
                page: res.pages.current_page,
                per_page: res.pages.per_page,
                total: res.pages.total,
                last_page: res.pages.last_page,
            }
            commit('setProcessedMaterialLogPagination', pagination)
        })  
        commit('setLoadingProcessedMaterial', false)
    },
    async fetchBSJLog({commit}, payload) {
        commit('setLoadingProcessedMaterial', true)
        await ProcessedItemsApi.allBSJ(payload.query, payload.options)
        .then(data => data.json())
        .then(res => {
            commit('setBSJLog', res.data)
            const pagination = {
                page: res.pages.current_page,
                per_page: res.pages.per_page,
                total: res.pages.total,
                last_page: res.pages.last_page,
            }
            commit('setBSJPagination', pagination)
        })
        commit('setLoadingProcessedMaterial', false)
    },
    async fetchBJLog({commit}, payload) {
        commit('setLoadingProcessedMaterial', true)
        await ProcessedItemsApi.allBJ(payload.query, payload.options)
        .then(data => data.json())
        .then(res => {
            commit('setBJLog', res.data)
            const pagination = {
                page: res.pages.current_page,
                per_page: res.pages.per_page,
                total: res.pages.total,
                last_page: res.pages.last_page,
            }
            commit('setBJPagination', pagination)
        })
        commit('setLoadingProcessedMaterial', false)
    },
}

const mutations = {
    setProcessedMaterialLog(state, log) {
        state.processedMaterialLog = log
    },
    setProcessedMaterialLogPagination(state, pagination) {
        state.processedMaterialPagination = pagination
    },
    setBSJLog(state, log) {
        state.bsjLog = log
    },
    setBSJPagination(state, pagination) {
        state.bsjPagination = pagination
    },
    setBJLog(state, log) {
        state.bjLog = log
    },
    setBJPagination(state, pagination) {
        state.bjPagination = pagination
    },
    setLoadingProcessedMaterial(state, loading) {
        state.loadingProcessedMaterial = loading
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}