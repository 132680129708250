import api from '@/utils/api.js'

export default {
    async all(query, options) {
        let endpoint = '/rawmateriallog'
        const page = query.page
        if (page) {
            endpoint = endpoint + '?page=' + page
        } else {
            endpoint = endpoint + '?page=1'
        }
        const per_page = query.per_page
        if (per_page) {
            endpoint = endpoint + '&per_page=' + per_page
        }
        const search = query.search
        if (search) {
            endpoint = endpoint + '&search=' + search
        }
        const sort_by = options.sort_by
        endpoint = endpoint + '&sort_by=' + sort_by
        let sort_desc = 'ASC'
        if (options.sort_desc) {
            sort_desc = 'DESC'
        }
        endpoint = endpoint + '&sort_desc=' + sort_desc
        return await api.get(endpoint)
    },
    async get(id) {
        return await api.get('/rawmateriallog/' + id)
    },
    async create(rawmateriallog) {
        return await api.post('/rawmateriallog?printer=Printer-Barcode-Dalam', rawmateriallog)
    },
    async put(id, rawmateriallog) {
        return await api.put('/rawmateriallog/' + id, rawmateriallog)
    },
    async delete(id) {
        return await api.delete('/rawmateriallog/' + id)
    },
    async print(id) {
        return await api.get('/rawmateriallog/print/' + id + '?printer=Printer-Barcode-Dalam')
    },
    async createMany(rawmateriallog) {
        return await api.post('/rawmateriallog/add?printer=Printer-Barcode-Dalam', rawmateriallog)
    },
    async report(start, end) {
        return await api.get('/rawmateriallog/report/bb?start=' + start + '&end=' + end)
    },
    async recap(start, end) {
        return await api.get('/rawmateriallog/rekap/bb?start=' + start + '&end=' + end)
    },
    async checkOutBarcode(barcode) {
        return await api.get('/rawmateriallog/checkout/' + barcode)
    },
    async addBarcode(data) {
        return await api.put('/rawmateriallog/checkout/', data)
    },
    async opname() {
        return await api.get('/rawmateriallog/opname/all')
    },
    async addOpname() {
        return await api.get('/rawmateriallog/opname/add')
    },
    async resetOpname() {
        return await api.get('/rawmateriallog/opname/reset/')
    },
    async updateOpname(barcode) {
        return await api.put('/rawmateriallog/opname/all/' + barcode)
    },
    async deleteOpname() {
        return await api.delete('/rawmateriallog/opname/all')
    },
    async filter(productId, status, date) {
        let prodId = 0;
        let dateSearch = "";
        let statusValue = ""

        if (status == "" || status == null) {
            statusValue = "all";
        } else {
            statusValue = status;
        }

       
        if (productId == "" || productId == null) {
            prodId = "";
        } else {
            prodId = productId;
        }

        if (date == "" || date == null) {
            dateSearch = "";
        } else {
            dateSearch = date;
        }

        const endpoint =
            "/rawmateriallog/filter/find?raw_material_id=" +
            prodId +
            "&status=" +
            statusValue +
            "&date=" +
            dateSearch;
        return await api.get(endpoint);
    },

}