<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form Supplier</span>
                        <v-text-field
                            label="Nama Supplier"
                            v-model="name"
                            @keyup.prevent="submitSupplier"
                        ></v-text-field>
                        <v-text-field
                            label="Alamat"
                            v-model="address"
                            @keyup.prevent="submitSupplier"
                        ></v-text-field>
                        <v-text-field
                            label="Telepon"
                            v-model="telp"
                            @keyup.prevent="submitSupplier"
                        ></v-text-field>
                        <div v-if="(this.id == '')">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="submitSupplier"
                                >
                                Tambah
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-col>
                                <v-row>
                                    <v-col class="pl-0 py-0">
                                        <v-btn
                                            block
                                            color="error"
                                            @click.prevent="clear"
                                        >
                                            Batal
                                        </v-btn>
                                    </v-col>
                                    <v-col class="pr-0 py-0">
                                        <v-btn
                                            block
                                            color="warning"
                                            @click.prevent="submitSupplier"
                                            >
                                            Ubah
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="suppliers"
                        :search="search"
                        sort-by="name"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small @click="changeSupplier(item)" class="warning ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn small @click="removeSupplier(item)" class="error ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initSuppliers"
                        >
                            Reset
                        </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiSupplier from '@/api/supplier.js'


export default {
    data() {
        return {
            suppliers: [],
            name: '',
            address: '',
            telp: '',
            id: '',
            headers: [
                {
                text: 'Nama Supplier',
                align: 'start',
                value: 'name',
                },
                { text: 'Alamat', value: 'address' },
                { text: 'Telepon', value: 'telp' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
        }
    },
    mounted() {
        this.initSuppliers()
    },
    methods: {
        initSuppliers() {
            this.getSuppliers()
            .then(response => response.json())
            .then(json => {
                this.suppliers = []
                if( json.success != undefined && json.success == true ) {
                    this.listSuppliers(json.data)
                }
            })
            .catch(err => {
                swal.error(err)
            })
        },
        listSuppliers(datas) {
            Array.from(datas).forEach(el => {
                this.suppliers.push({
                    id: el.id,
                    name: el.name,
                    address: el.address,
                    telp: el.telp,
                    created_at: el.created_at,
                    updated_at: el.updated_at,
                })
            })
        },
        submitSupplier(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            let supplier = {
                name: this.name,
                address: this.address,
                telp: this.telp,
            }
            
            if(this.id == '') {
                this.addSupplier(supplier)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.suppliers.push({
                            id: json.data.id,
                            name: json.data.name,
                            address: json.data.address,
                            telp: json.data.telp
                        })
                        swal.success(json.message)
                        this.name = '',
                        this.address = '',
                        this.telp = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                this.editSupplier(this.id, supplier)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.suppliers.forEach(p => {
                            if(p.id == this.id) {
                                p.name = this.name,
                                p.address = this.address,
                                p.telp = this.telp
                            }
                        })
                        swal.success(json.message)
                        this.name = '',
                        this.address = '',
                        this.telp = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }
            
        },
        changeSupplier(data) {
            this.name = data.name,
            this.address = data.address,
            this.telp = data.telp,
            this.id = data.id

        },
        removeSupplier(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.deleteSupplier(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.initSuppliers()
                        swal.success(json.message)
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                return
            }
        },
        async getSuppliers() {
            return await ApiSupplier.all()
        },
        async addSupplier(supplier) {
            return await ApiSupplier.create(supplier)
        },
        async editSupplier(id, supplier) {
            return await ApiSupplier.put(id, supplier)
        },
        async deleteSupplier(id) {
            return await ApiSupplier.delete(id)
        },
        submit(event) {
            if(event.keyCode == 13) {
                swal.success('Mantap')
            }
        },
        clear() {
            this.name = ''
            this.address = ''
            this.telp = ''
            this.id = ''
        }
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>