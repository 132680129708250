<template>
  <div data-app class="container set-height">
        <v-btn
            v-if="user.role_id < 3"
            class="ma-2"
            color="orange darken-2"
            dark
            small
            to="/product-item-bj-log"
        >
            <v-icon
            dark
            left
            >
            mdi-arrow-left
            </v-icon>Kembali
        </v-btn>
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <div class="my-2 d-flex flex-row-reverse">
                            <v-btn small class="mx-2" color="secondary" dark v-on:click="reload">
                                Ulangi
                                <v-icon dark center>mdi-cached</v-icon>
                            </v-btn>
                        </div>
                        <span class="card-title">Form Masuk Barang Jadi (BJ)</span>
                        <v-text-field
                            label="Scan Operator"
                            v-model="operator"
                            clearable
                            type="text"
                            ref="operator"
                        ></v-text-field>
                        <v-text-field
                            label="Scan Barang"
                            v-model="product_item"
                            clearable
                            type="text"
                            ref="barang"
                        ></v-text-field>
                        <v-text-field
                            label="Masukkan Berat"
                            v-model="amount"
                            type="text"
                            ref="amount"
                            v-on:keydown.enter.prevent="addToList"
                        ></v-text-field>
                        <v-datetime-picker 
                            label="Tanggal Masuk"
                            date-format="dd/MM/yyyy"
                            time-format="HH:mm"
                            v-model="date_in"> 
                        </v-datetime-picker>
                        <div class="text-center">
                            <v-btn
                                block
                                :loading="loading"
                                :disabled="loading"
                                color="success"
                                @click.prevent="addToList"
                                >
                                Tambah ke list
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-text>
                        <span class="card-title">List Masuk Sementara Barang Jadi</span>
                        <div class="my-2 d-flex flex-row-reverse">
                            <v-btn
                                color="error"
                                v-on:click="reset"
                                small
                            >
                                Hapus Semua
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">#</th>
                                        <th>Operator</th>
                                        <th>Barang</th>
                                        <th>Berat</th>
                                        <th>Tanggal Masuk</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(log, index) in logs" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ log.operator }}</td>
                                        <td>{{ log.product_item_name }}</td>
                                        <td>{{ log.amount }}</td>
                                        <!-- <td>{{ new Date(log.date_in).toLocaleString() }}</td> -->
                                        <td>{{ formattedDate(log.date_in) }}</td>
                                        <td>
                                            <button class="btn btn-block" @click.prevent="removeBarcode(index)">Hapus</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <div class="d-flex">
                                    <div class="my-2">
                                        <v-btn
                                            :loading="loading2"
                                            :disabled="loading2"
                                            color="primary"
                                            v-on:click="submit"
                                            small
                                        >
                                            Submit
                                            <v-icon>mdi-table-plus</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import swal from '@/utils/alert.js'
import ApiProcessedMaterialLog from '@/api/processedMaterialLog.js'
import ApiProductItem from '@/api/productItem.js'
import ApiOperator from '@/api/operator.js'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            product_items: [],
            logs: [],
            id: '',
            product_item_id: '',
            user_id: 0,
            amount: '',
            processed_times: '',
            valid: null,
            loading: false,
            loading2: false,
            product_item: '',
            operator: '',
            date_in: new Date(),
            user: {},
        }
    },
    created() {
        this.user = JSON.parse(this.getUser)
        this.user_id = this.user.id
    },
    computed: {
        ...mapGetters(["isLoggedIn", "getUser"]),
    },
    methods: {
        addToList(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            if( this.product_item == '' || this.amount == '' ) {
                swal.error('Form tidak boleh kosong!')
                if(this.product_item == '') {
                    this.$refs.barang.focus()
                } else {
                    this.$refs.amount.focus()
                }
                return
            }
            
            if( /^[0-9.]+$/.test(this.amount) ) {
                if( Number(this.amount) < 1 ) {
                    swal.error('Berat harus lebih dari 0')
                    this.$refs.amount.focus()
                    return
                }

                if( this.date_in == '' || this.date_in == null ) {
                    this.date_in = new Date()
                }

                this.loading = true
                let product_item = {
                    name: this.product_item
                }
                let amount = this.amount
                let user_id = this.user.id
                let operator = {
                    name: this.operator
                }

                this.getOperatorName(operator)
                .then(op => op.json())
                .then(oper => {
                    if(oper.success != undefined && oper.success == true) {
                        this.getProductItemName(product_item)
                        .then(result => result.json())
                        .then(product_item => {
                            if(product_item.success != undefined && product_item.success == true) {
                                swal.success("Berhasil masuk ke list!")
                                this.logs.push({
                                    product_item_id: product_item.data.id,
                                    amount: amount,
                                    user_id: user_id,
                                    product_item_name: product_item.data.name,
                                    operator: oper.data.name,
                                    operator_id: oper.data.id,
                                    date_in: this.date_in
                                })
                                this.amount = ''
                                this.$refs.amount.focus()
                            } else {
                                swal.error("Barang tidak ditemukan, Silahkan scan ulang!")
                                this.product_item = ''
                                this.$refs.barang.focus()
                            }
                        })
                    } else {
                        swal.error("Operator tidak ditemukan, Silahkan scan ulang!")
                        this.operator = ''
                        this.$refs.operator.focus()
                    }
                })
                this.loading = false
            } else {
                swal.error('Berat harus angka!')
                this.$refs.amount.focus()
            }
        },
        removeBarcode(index) {
            let response = confirm(`Apakah anda yakin ingin menghapus list ke #${index+1}`)
            
            if(response) {
                // this.logs = this.logs.splice(index+1)
                this.$delete(this.logs, index)
            }else{
                return
            }
        },
        submit(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            if( this.logs == '' ) {
                swal.error("List Checkout Kosong!")
                return
            }
            this.loading2 = !this.loading2
            let list = {
                status: "in",
                processed_times: 2,
                data: this.logs
            }
            this.checkInBJ(list)
            .then(result => result.json())
            .then(json => {
                if(json.success != undefined && json.success == true) {
                    swal.success(json.message)
                } else {
                    swal.error(json.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
            this.logs = []
            this.loading2 = !this.loading2
        },
        reset() {
            let response = confirm(`Apakah anda yakin ingin menghapus semua list`)
            
            if(response) {
                this.logs = []
            }else{
                return
            }
        },
        reload() {
            this.operator = ''
            this.product_item = ''
            this.amount = ''
            this.date_in = new Date()
        },
        async getProductItems() {
            return await ApiProductItem.all()
        },
        async getProductItem(id) {
            return await ApiProductItem.get(id)
        },
        async checkInBJ(data) {
            return await ApiProcessedMaterialLog.checkInBJ(data)
        },
        async getProductItemName(name) {
            return await ApiProductItem.postName(name)
        },
        async getOperatorName(name) {
            return await ApiOperator.postName(name)
        },
        formattedDate(value) {
            return moment(value).locale('id').format('DD MMMM YYYY HH:mm')
        },
    }
}

</script>


<style>
    .set-height {
        min-height: 90vh;
    }
</style>