<template>
  <v-container class="set-height">
    <div class="text-h5">{{ title_page }}</div>
    <div class="text-subheader">stock opname detail</div>
    <v-container v-if="!isFinish">
      <v-row justify="center">
        <v-btn
          class="text-button"
          color="primary"
          small
          @click="showDialogOperator"
          text
          >Operator Scan</v-btn
        >
      </v-row>
      <v-row justify="center">
        <span class="text-caption" v-if="scanned_by != null"
          >Discan oleh : {{ scanned_by }}</span
        >
      </v-row>

      <v-row justify="center">
        <v-text-field
          filled
          rounded
          single-line
          autofocus
          label="Scan Barcode"
          append-icon="mdi-qrcode-scan"
          placeholder="Input Barcode"
          class="shrink"
          v-model="barcode"
          @keyup="upperCase"
          hide-details
          v-on:keydown.enter.prevent="doScan"
        ></v-text-field>
      </v-row>
    </v-container>
    <v-card class="mt-5">
      <v-data-table
        hide-default-footer
        disable-pagination
        :headers="headers"
        :items="listData"
        :search="search"
        sort-by="number"
        class="elevation-1 mt-5"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="text-button orange px-2 rounded white--text"
              >Total = {{ listData.length }}</span
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              label="Search"
            ></v-text-field>
          </v-toolbar>
        </template>

        <v-spacer></v-spacer>

        <template v-slot:[`item.count_diff`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="getColorDiff(item.count_diff)"
                rounded
                small
                dark
                elevation="0"
                v-on="on"
                v-bind="attrs"
                @click="toDetail(item, `unscanned`)"
                >{{ item.count_diff }}</v-btn
              >
            </template>
            <span v-if="item.count_diff < 0" class="text-caption"
              >Barcode Unscan</span
            >
            <span v-else class="text-caption">Done</span>
          </v-tooltip>
        </template>

        <!-- action detail -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                elevation="0"
                small
                v-bind="attrs"
                v-on="on"
                :href="'/stock-opname/detail/' + item.id + '?type=scanned'"
              >
                <v-icon>mdi-arrow-right-bold-circle</v-icon>
              </v-btn>
            </template>
            <span class="text-caption">Detail</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.finished_at == null"
                small
                class="teal darken-2 ma-1"
                v-bind="attrs"
                v-on="on"
                dark
                @click="showDialogBalance(item)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span class="text-caption">Catatan</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!isFinish">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                elevation="0"
                @click="showDialogDelete(item)"
                class="red darken-4 ma-1 white--text"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-delete-empty </v-icon>
              </v-btn>
            </template>
            <span class="text-caption">Hapus</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="500px" v-model="dialogOperator">
      <v-card>
        <v-card-title class="text-h5">Masukan Nama Operator Scan</v-card-title>
        <v-card-actions>
          <v-text-field
            autofocus
            clearable
            persistent
            text-center
            class="mx-3 text-center"
            v-on:keydown.enter.prevent="closeDialog"
            v-model="scanned_by"
          ></v-text-field>
          <v-btn
            color="blue darken-1 mx-auto text-button"
            dark
            @click="closeDialog"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <!-- toolbar dialog-->
        <v-toolbar dark color="blue darken-3">
          <v-toolbar-title>Hapus Stock Opname Produk</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <!-- body dialog -->
        <div class="pa-5">
          Anda Akan menghapus seluruh
          <span class="text-button">{{ opnameProduct.name }}</span> yang telah
          di scan!
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue  darken-2" dark @click="closeDialog"
            >Kembali</v-btn
          >
          <v-btn dark color="red darken-2" text class="mr-4" @click="removeItem"
            >Hapus</v-btn
          >
        </v-card-actions>

        <div class="yellow"></div>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" persistent v-model="dialogBalance">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="lighten-2"
            >Catatan : {{ opnameProduct.name }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-textarea
          autofocus
          label="Tambah Keterangan disini"
          persistent
          hint="keterangan barcode yang tidak ditemukan"
          class="mx-5 mt-2"
          v-model="opnameProduct.note"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            class="text-button white--text mb-2"
            small
            @click="updateItem"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiStockOpname from "@/api/stockOpname.js";
import moment from "moment";

export default {
  data() {
    return {
      dialogBalance: false,
      dialogOperator: false,
      dialogDelete: false,

      listData: [],
      headers: [
        { text: "Nomor", value: "number", align: "center" },
        { text: "Nama Produk", value: "name", align: "start" },
        { text: "Kategori", value: "category", align: "center" },
        { text: "Jumlah Sistem", value: "count_system", align: "center" },
        { text: "Jumlah Scan", value: "count_actual", align: "center" },
        { text: "Selisih", value: "count_diff", align: "center" },
        { text: "Catatan", value: "note", align: "center" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      title_page: "",
      isFinish: false,
      search: "",
      barcode: "",
      scanned_by: null,
      stock_opname_id: this.$route.params.id,

      opnameProduct: {
        id: 0,
        number: 0,
        name: "",
        category: "",
        count_system: 0,
        count_actual: 0,
        count_diff: 0,
        note: 0,
      },

      defaultopnameProduct: {
        id: 0,
        number: 0,
        name: "",
        category: "",
        count_system: 0,
        count_actual: 0,
        count_diff: 0,
        note: 0,
      },
    };
  },
  created() {
    this.initStockOpname();
    this.initOpnameDetail();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.opnameProduct.started_at
        ? moment(this.opnameProduct.started_at).format("DD-MM-yyyy")
        : "";
    },
    formTitle() {
      return this.opnameProduct.id === 0
        ? "Tambah Stock Opname"
        : "Edit Stock Opname";
    },
  },
  methods: {
    async initStockOpname() {
      await ApiStockOpname.get(this.stock_opname_id).then((response) =>
        response.json().then((result) => {
          if (result.success) {
            this.title_page = result.data.description;
            this.isFinish = result.data.finished_at == null ? false : true;
          } else {
            console.log(result.message);
          }
        })
      );
    },
    async initOpnameDetail() {
      const param = this.$route.params.id;
      await ApiStockOpname.getProduct(param)
        .then((data) => data.json())
        .then((result) => {
          if (result.success == true) {
            this.listData = [];
            result.data.forEach((item, position) => {
              const name =
                item.raw_material != null
                  ? item.raw_material.name
                  : item.product_item.name;
              const countDiff = item.count_actual - item.count_system;

              this.listData.push({
                number: position + 1,
                name: name,
                count_diff: countDiff,
                ...item,
              });
            });
          } else {
            swal.error(result.message);
          }
        });
    },

    getColorDiff(countDiff) {
      if (countDiff < -20) {
        return "red ";
      } else if (countDiff < -10) {
        return "orange";
      } else if (countDiff < 0) {
        return "yellow  darken-2";
      } else if (countDiff == 0) {
        return "grey";
      } else {
        return "black";
      }
    },

    async doScan() {
      if (this.barcode.length < 10) {
        swal.error("barcode tidak ditemukan");
        this.barcode = "";
      } else {
        if (this.scanned_by == null) {
          swal.error("Masukan Nama Operator Scan!");
          this.barcode = "";
        } else {
          console.log(this.stock_opname_id);
          console.log(this.scanned_by);
          console.log(this.barcode);
          await ApiStockOpname.doScanOpname(
            this.stock_opname_id,
            this.scanned_by,
            this.barcode
          )
            .then((response) => {
              response
                .json()
                .then((result) => {
                  if (result.success) {
                    swal.success(result.message);
                    this.initOpnameDetail();
                    this.barcode = "";
                  } else {
                    swal.error(result.message);
                    this.barcode = "";
                  }
                })
                .catch((err) => {
                  console.error(err);
                  swal.error(err);
                  this.barcode = "";
                });
            })
            .catch((err) => {
              console.error(err);
              swal.error(err);
              this.barcode = "";
            });
        }
      }
    },
    upperCase() {
      this.barcode = this.barcode.toUpperCase();
    },

    createopnameProduct() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const body = {
          created_by: this.opnameProduct.created_by,
          started_at: this.opnameProduct.started_at,
          description: this.opnameProduct.description,
        };

        ApiStockOpname.create(body)
          .then((response) => {
            response.json().then((result) => {
              console.log("response :\n" + JSON.stringify(result));
              if (result.success) {
                swal.success(result.message);
                this.initOpnameDetail();
              } else {
                swal.error(result.message);
              }
              this.closeDialog();
            });
          })
          .catch((err) => {
            swal.error(err);
          });
        this.closeDialog();
      }
    },

    showDialogOperator(item) {
      this.opnameProduct = Object.assign({}, item);
      this.dialogOperator = true;
    },

    showDialogBalance(item) {
      this.opnameProduct = Object.assign({}, item);
      this.dialogBalance = true;
    },

    showDialogCheckout(typeCheckout) {
      this.typeCheckout = typeCheckout;
      this.dialogCheckout = true;
    },

    showDialogDelete(item) {
      this.opnameProduct = Object.assign({}, item);
      this.dialogDelete = true;
    },

    updateItem() {
      const id = this.opnameProduct.id;
      const body = {
        note: this.opnameProduct.note,
      };
      ApiStockOpname.updateProduct(id, body)
        .then((response) => {
          response.json().then((result) => {
            if (result.success) {
              this.initOpnameDetail();
              swal.success(result.message);
            } else {
              swal.error(result.message);
            }
            this.closeDialog();
          });
        })
        .catch((err) => {
          swal.error(err);
          this.closeDialog();
        });
    },

    removeItem() {
      const id = this.opnameProduct.id;
      ApiStockOpname.deleteProduct(id)
        .then((response) =>
          response.json().then((result) => {
            if (result.success) {
              this.closeDialog();
              this.initOpnameDetail();
              swal.success(result.message);
            } else {
              swal.error(result.message);
            }
          })
        )
        .catch((err) => {
          swal.error(err);
        });
    },
    toDetail(item, type) {
      if (type == "unscanned" && item.count_diff >= 0) {
        swal.success("Semua Barcode telah discan");
      } else {
        window.open("/stock-opname/detail/" + item.id + "?type=" + type);
      }
    },

    closeDialog() {
      this.dialogBalance = false;
      this.dialogOperator = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.opnameProduct = Object.assign({}, this.defaultopnameProduct);
      });
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>