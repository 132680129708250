import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/invoicetemporary')
    },
    async get(id) {
        return await api.get('/invoicetemporary/' + id)
    },
    async create(data) {
        return await api.post('/invoicetemporary', data)
    },
    async put(id, data) {
        return await api.put('/invoicetemporary/' + id, data)
    },
    async delete(id) {
        return await api.delete('/invoicetemporary/' + id)
    },
    async getBarcode(barcode, checkoutBy) {
        return await api.get('/invoicetemporary/barcode/' + barcode + "?checkout_by=" + checkoutBy)
    },
    async addBarcode(data) {
        return await api.post('/invoicetemporary/add/', data)
    },
    async getInvoiceId(id) {
        return await api.get('/invoicetemporary/invoice/' + id)
    },
    async deleteBarcode(barcode) {
        return await api.delete('/invoicetemporary/barcode/' + barcode)
    },
    async copy(data) {
        return await api.post('/invoicetemporary/copy/' + data)
    }
}