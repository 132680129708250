<template>
    <div data-app class="container set-height">
        <v-btn
            class="ma-2"
            color="orange darken-2"
            dark
            small
            to="/invoice"
        >
            <v-icon
            dark
            left
            >
            mdi-arrow-left
            </v-icon>Kembali
        </v-btn>
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Edit Invoice Item </span>
                        <v-text-field
                            label="Nama Barang"
                            v-model="product_item_name"
                            disabled
                        ></v-text-field>
                        <v-text-field
                            label="Harga"
                            v-model="price"
                        ></v-text-field>
                        <v-text-field
                            label="Berat /Kuantum"
                            v-model="quantity"
                        ></v-text-field>
                        <v-text-field
                            label="Barcode"
                            v-model="barcode"
                            disabled
                        ></v-text-field>
                        <div v-if="(this.id == '')">
                            <button class="btn btn-block" @click.prevent="submit" disabled>Ubah</button>
                        </div>
                         <div v-else>
                            <button class="btn btn-block" @click.prevent="submit">Ubah</button>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col col="12" md="8" class="m8">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Data Invoice Item</span>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th style="width: 5%;" class="text-left">#</th>
                                        <th class="text-left">Nama Barang</th>
                                        <th class="text-left">Berat / Kuantum</th>
                                        <th class="text-left">Harga</th>
                                        <th class="text-left">Barcode</th>
                                        <th class="text-left">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(invoice_item, index) in invoice_items" :key="invoice_item.id">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ invoice_item.product_item.name }}</td>
                                        <td>{{ invoice_item.quantity }}</td>
                                        <td>{{ invoice_item.price }}</td>
                                        <td>{{ invoice_item.barcode }}</td>
                                        <td>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                @click="changeInvoiceItem(invoice_item)"
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                small
                                                @click="removeInvoiceItem(invoice_item)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiInvoice from '@/api/invoice.js'
import ApiInvoiceItem from '@/api/invoiceItem.js'
import ApiProductItem from '@/api/productItem.js'
import ApiProcessedMaterialLog from '@/api/processedMaterialLog.js'

export default {
    data() {
        return {
            invoice_items: [],
            id: '',
            invoice_id: '',
            product_item_id: '',
            quantity: '',
            price: '',
            barcode: '',
            invoice: {
                invoiced_to: '',
                invoiced_at: '',
                note: '',
                address: '',
            },
            product_item: {
                name: ''
            },
            product_items: '',
            invoices: '',
            product_item_name: ''
        }
    },
    mounted() {
        this.initInvoiceItems(),
        this.initProductItems()
    },
    methods: {
        initInvoiceItems() {
            this.getInvoiceId(this.$route.params.id)
            .then(response => response.json())
            .then(json => {
                this.invoice_items = []
                if( json.success != undefined && json.success == true ) {
                    this.listInvoiceItems(json.data)
                }
                console.log(this.invoice_items)
            })
            .catch(() => {
                
            })
        },
        listInvoiceItems(datas) {
            Array.from(datas).forEach(el => {
                if( el.product_item_id == null ) {  
                    this.invoice_items.push({
                        id: el.id,
                        invoice_id: el.invoice_id,
                        product_item_id: el.raw_material_id,
                        quantity: el.quantity,
                        barcode: el.barcode,
                        price: el.price,
                        product_item: {
                            name: el.raw_material.name,
                        },
                        invoice: {
                            invoiced_to: el.invoice.invoiced_to,
                            invoiced_at: el.invoice.invoice_at,
                            note: el.invoice.note
                        },
                        created_at: el.created_at,
                        updated_at: el.updated_at,
                    })
                } else if ( el.raw_material_id == null ) {
                    this.invoice_items.push({
                        id: el.id,
                        invoice_id: el.invoice_id,
                        product_item_id: el.product_item_id,
                        quantity: el.quantity,
                        barcode: el.barcode,
                        price: el.price,
                        product_item: {
                            name: el.product_item.name,
                        },
                        invoice: {
                            invoiced_to: el.invoice.invoiced_to,
                            invoiced_at: el.invoice.invoice_at,
                            note: el.invoice.note
                        },
                        created_at: el.created_at,
                        updated_at: el.updated_at,
                    })
                }
            })
        },
        initProductItems() {
            this.getProductItems()
            .then(response => response.json())
            .then(json => {
                this.product_items = []
                if( json.success != undefined && json.success == true ) {
                    this.listProductItems(json.data)
                    console.log(json.data)
                }
            })
        },
        listProductItems(datas) {
            Array.from(datas).forEach(el => {
                this.product_items.push({
                    id: el.id,
                    name: el.name,
                    description: el.description,
                    price: el.price,
                    rate: el.rate,
                    created_at: el.created_at,
                    updated_at: el.updated_at
                })
            })
        },
        submit(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }
            
            if( this.invoice_items == '' ) {
                swal.error("List Checkout Kosong!")
                return
            }

            let invoice_item = {
                invoice_id: this.invoice_id,
                product_item_id: this.product_item_id,
                quantity: this.quantity,
                price: this.price,
                barcode: this.barcode,
                product_item: {
                    name: this.product_item.name
                }
            }
            if(this.id != '' || this.id != null) {
                let id = this.id
                let invoice_id = this.invoice_id
                let product_item_id = this.product_item_id
                let product_item_name = this.product_item_name
                let quantity = this.quantity
                let price = this.price
                let barcode = this.barcode
                this.editInvoiceItem(this.id, invoice_item)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.invoice_items.forEach(p => {
                            if(p.id == id) {
                                p.invoice_id = invoice_id,
                                p.product_item_id = product_item_id,
                                p.quantity = quantity,
                                p.price = price,
                                p.barcode = barcode,
                                p.product_item.name = product_item_name
                            }
                        })

                        // this.getProductItem(product_item_id)
                        // .then(result => result.json())
                        // .then(product_item => {
                        //     swal.success(json.message)
                        // })

                        this.invoice_id = '',
                        this.product_item_id = '',
                        this.product_item_name = '',
                        this.quantity = '',
                        this.price = '',
                        this.barcode = '',
                        this.id = ''
                    } else {
                        swal.error(json.message)
                    }
                })
            }
            
        },
        changeInvoiceItem(data) {
            this.id = data.id
            this.invoice_id = data.invoice_id,
            this.product_item_id = data.product_item_id,
            this.quantity = data.quantity,
            this.price = data.price,
            this.barcode = data.barcode,
            this.product_item_name = data.product_item.name
        },
        removeInvoiceItem(data) {
            let response = confirm(`Are you sure you want to delete ${data.barcode} from invoice`)
            let barcode = data.barcode
            if(response) {
                this.returnInvoiceItem(barcode)
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.invoice_items = this.invoice_items.filter(u => u.id != data.id)
                        this.listInvoiceItems(json.data)
                    } else {
                        swal.error("Delete Fail")
                    }
                })
            }else{
                return
            }
        },
        async getInvoiceItems() {
            return await ApiInvoiceItem.all()
        },
        async addInvoiceItem(invoice_item) {
            return await ApiInvoiceItem.create(invoice_item)
        },
        async editInvoiceItem(id, invoice_item) {
            return await ApiInvoiceItem.put(id, invoice_item)
        },
        async deleteInvoiceItem(id) {
            return await ApiInvoiceItem.delete(id)
        },
        async getProductItem(id) {
            return await ApiProductItem.get(id)
        },
        async getSupplier(id) {
            return await ApiProductItem.get(id)
        },
        async getInvoice(id) {
            return await ApiInvoice.get(id)
        },
        async getProcessingMaterial(id) {
            return await ApiProcessedMaterialLog.get(id)
        },
        async getInvoiceId(id) {
            return await ApiInvoiceItem.getInvoiceId(id)
        },
        async returnInvoiceItem(barcode) {
            return await ApiInvoiceItem.returnInvoiceItem(barcode)
        },
        async getProductItems() {
            return await ApiProductItem.all()
        }
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>