import stockOpname from '@/views/opname/StockOpname'
import stockOpnameProduct from '@/views/opname/StockOpnameDetail'
import stockOpnameLog from '@/views/opname/StockOpnameLog'

export default [
    {
        name: 'stockOpname',
        path: '/stock-opname',
        component: stockOpname,
        meta: {
            title: 'Opname Bahan Baku',
            requiresAuth: true
        }
    },
    {
        name: 'stockOpname.detail',
        path: '/stock-opname/:id',
        component: stockOpnameProduct,
        meta: {
            title: 'Opname Bahan Baku',
            requiresAuth: true
        }
    },
    {
        name: 'stockOpname.log',
        path: '/stock-opname/detail/:id',
        component: stockOpnameLog,
        meta: {
            title: 'Opname Bahan Baku',
            requiresAuth: true
        }
    },


]