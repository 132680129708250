import api from "@/utils/api.js";

export default {
  async all(query, options) {
    let endpoint = "/processedmateriallog";
    const page = query.page;
    if (page) {
      endpoint = endpoint + "?page=" + page;
    } else {
      endpoint = endpoint + "?page=1";
    }
    const per_page = query.per_page;
    if (per_page) {
      endpoint = endpoint + "&per_page=" + per_page;
    }
    const search = query.search;
    if (search) {
      endpoint = endpoint + "&search=" + search;
    }
    const sort_by = options.sort_by;
    endpoint = endpoint + "&sort_by=" + sort_by;
    let sort_desc = "ASC";
    if (options.sort_desc) {
      sort_desc = "DESC";
    }
    endpoint = endpoint + "&sort_desc=" + sort_desc;
    return await api.get(endpoint);
  },
  async allBSJ(query, options) {
    let endpoint = "/processedmateriallog/bsj/all";
    const page = query.page;
    if (page) {
      endpoint = endpoint + "?page=" + page;
    } else {
      endpoint = endpoint + "?page=1";
    }
    const per_page = query.per_page;
    if (per_page) {
      endpoint = endpoint + "&per_page=" + per_page;
    }
    const search = query.search;
    if (search) {
      endpoint = endpoint + "&search=" + search;
    }
    const sort_by = options.sort_by;
    endpoint = endpoint + "&sort_by=" + sort_by;
    let sort_desc = "ASC";
    if (options.sort_desc) {
      sort_desc = "DESC";
    }
    endpoint = endpoint + "&sort_desc=" + sort_desc;
    return await api.get(endpoint);
  },
  async allBJ(query, options) {
    let endpoint = "/processedmateriallog/bj/all";
    const page = query.page;
    if (page) {
      endpoint = endpoint + "?page=" + page;
    } else {
      endpoint = endpoint + "?page=1";
    }
    const per_page = query.per_page;
    if (per_page) {
      endpoint = endpoint + "&per_page=" + per_page;
    }
    const search = query.search;
    if (search) {
      endpoint = endpoint + "&search=" + search;
    }
    const sort_by = options.sort_by;
    endpoint = endpoint + "&sort_by=" + sort_by;
    let sort_desc = "ASC";
    if (options.sort_desc) {
      sort_desc = "DESC";
    }
    endpoint = endpoint + "&sort_desc=" + sort_desc;
    return await api.get(endpoint);
  },
  async get(id) {
    return await api.get("/processedmateriallog/" + id);
  },
  async create(processedmateriallog) {
    return await api.post(
      "/processedmateriallog?printer=Printer-Barcode-Dalam",
      processedmateriallog
    );
  },
  async put(id, processedmateriallog) {
    return await api.put("/processedmateriallog/" + id, processedmateriallog);
  },
  async delete(id) {
    return await api.delete("/processedmateriallog/" + id);
  },
  async print(id) {
    return await api.get(
      "/processedmateriallog/print/" + id + "?printer=Printer-Barcode-Dalam"
    );
  },
  async report(start, end) {
    return await api.get(
      "/processedmateriallog/report/bsj?start=" + start + "&end=" + end
    );
  },
  async recap(start, end) {
    return await api.get(
      "/processedmateriallog/rekap/bsj?start=" + start + "&end=" + end
    );
  },
  async production(date) {
    return await api.get(
      "/processedmateriallog/report/production?date=" + date
    );
  },
  async checkOutBSJ(barcode) {
    return await api.get("/processedmateriallog/checkout/bsj/" + barcode);
  },
  async addBarcode(data) {
    return await api.put("/processedmateriallog/checkout/bsj/", data);
  },
  async checkInBSJ(data) {
    return await api.post("/processedmateriallog/checkin/bsj/", data);
  },
  async checkInBJ(data) {
    return await api.post("/processedmateriallog/checkin/bj/", data);
  },
  async opnameBSJ() {
    return await api.get("/processedmateriallog/opname/bsj/all");
  },
  async opnameBJ() {
    return await api.get("/processedmateriallog/opname/bj/all");
  },
  async addOpnameBSJ() {
    return await api.get("/processedmateriallog/opname/bsj/add");
  },
  async resetOpnameBSJ() {
    return await api.get("/processedmateriallog/opname/bsj/reset");
  },
  async updateOpnameBSJ(barcode) {
    return await api.put("/processedmateriallog/opname/bsj/all/" + barcode);
  },
  async deleteOpnameBSJ() {
    return await api.delete("/processedmateriallog/opname/bsj/all");
  },
  async addOpnameBJ() {
    return await api.get("/processedmateriallog/opname/bj/add");
  },
  async resetOpnameBJ() {
    return await api.get("/processedmateriallog/opname/bj/reset");
  },
  async updateOpnameBJ(barcode) {
    return await api.put("/processedmateriallog/opname/bj/all/" + barcode);
  },
  async deleteOpnameBJ() {
    return await api.delete("/processedmateriallog/opname/bj/all");
  },
  async filter(type, operatorId, productId, date) {
    let optId = 0;
    let prodId = 0;
    let dateSearch = "";
    if (operatorId == "" || operatorId == null) {
      optId = "";
    } else {
      optId = operatorId;
    }

    if (productId == "" || productId == null) {
      prodId = "";
    } else {
      prodId = productId;
    }

    if (date == "" || date == null) {
      dateSearch = "";
    } else {
      dateSearch = date;
    }

    const endpoint =
      "/processedmateriallog/filter/find?type=" +
      type +
      "&operator_id=" +
      optId +
      "&product_item_id=" +
      prodId +
      "&date=" +
      dateSearch;
    return await api.get(endpoint);
  },
};
