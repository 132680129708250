import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/user')
    },
    async get(id) {
        return await api.get('/user/' + id)
    },
    async create(user) {
        return await api.post('/user', user)
    },
    async put(id, user) {
        return await api.put('/user/' + id, user)
    },
    async delete(id) {
        return await api.delete('/user/' + id)
    }
}