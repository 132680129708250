<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form Role</span>
                        <div class="input-field">
                            <input 
                            id="name"
                            type="text" 
                            class="validate" 
                            v-model="name" 
                            @keyup.prevent=""
                            autofocus 
                            required
                            />
                            <label for="name">Nama Role</label>
                        </div>
                        <!-- <div v-if="(this.id == '')">
                            <button class="btn btn-block" @click.prevent="submitRole">Tambah</button>
                        </div>
                        <div v-else>
                            <button class="btn btn-block" @click.prevent="submitRole">Ubah</button>
                        </div> -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" class="m8">
                <div data-app>
                    <v-card>
                        <v-card-title>
                            Daftar Role
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="roles"
                            sort-by="calories"
                            class="elevation-1"
                        >
                            <template v-slot:top>
                            <v-toolbar
                                flat
                            >
                                <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
                                <!-- <v-divider
                                class="mx-4"
                                inset
                                vertical
                                ></v-divider>
                                <v-spacer></v-spacer> -->
                                <v-dialog
                                v-model="dialog"
                                max-width="500px"
                                >
                                <!-- <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    New Item
                                    </v-btn>
                                </template> -->
                                <v-card>
                                    <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                    <v-container>
                                        <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.name"
                                            label="Dessert name"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.calories"
                                            label="Calories"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.fat"
                                            label="Fat (g)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.carbs"
                                            label="Carbs (g)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                            v-model="editedItem.protein"
                                            label="Protein (g)"
                                            ></v-text-field>
                                        </v-col>
                                        </v-row>
                                    </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                            <template v-slot:no-data>
                            <v-btn
                                color="primary"
                                @click="initialize"
                            >
                                Reset
                            </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>

                <!-- <div class="card">
                    <div class="card-content">
                        <span class="card-title">Data Role</span>
                        <table class="responsive-table">
                            <thead>
                                <tr>
                                    <th style="width: 5%;">#</th>
                                    <th>ID</th>
                                    <th>Role</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(role, index) in roles" :key="role.id">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ role.id }}</td>
                                    <td>{{ role.name }}</td>
                                    <td>
                                        <button class="btn btn-block" @click.prevent="changeRole(role)">Ubah</button>
                                        <br>
                                        <button class="btn btn-block" @click.prevent="removeRole(role)">Hapus</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
// import Role from '@/models/role.js'
import ApiRole from '@/api/role.js'

export default {
    data() {
        return {
            roles: [],
            name: '',
            id: '',
            search: '',
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
                },
                { text: 'Nama Role', value: 'name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                id: '',
                name: 0,
            },
            defaultItem: {
                id: 0,
                name: '',
            },
        }
    },
    mounted() {
        this.initRoles()
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    methods: {
        initialize () {
            this.desserts = [
            {
                name: 'Frozen Yogurt',
                calories: 159,
                fat: 6.0,
                carbs: 24,
                protein: 4.0,
            },
            {
                name: 'Ice cream sandwich',
                calories: 237,
                fat: 9.0,
                carbs: 37,
                protein: 4.3,
            },
            {
                name: 'Eclair',
                calories: 262,
                fat: 16.0,
                carbs: 23,
                protein: 6.0,
            },
            {
                name: 'Cupcake',
                calories: 305,
                fat: 3.7,
                carbs: 67,
                protein: 4.3,
            },
            {
                name: 'Gingerbread',
                calories: 356,
                fat: 16.0,
                carbs: 49,
                protein: 3.9,
            },
            {
                name: 'Jelly bean',
                calories: 375,
                fat: 0.0,
                carbs: 94,
                protein: 0.0,
            },
            {
                name: 'Lollipop',
                calories: 392,
                fat: 0.2,
                carbs: 98,
                protein: 0,
            },
            {
                name: 'Honeycomb',
                calories: 408,
                fat: 3.2,
                carbs: 87,
                protein: 6.5,
            },
            {
                name: 'Donut',
                calories: 452,
                fat: 25.0,
                carbs: 51,
                protein: 4.9,
            },
            {
                name: 'KitKat',
                calories: 518,
                fat: 26.0,
                carbs: 65,
                protein: 7,
            },
            ]
        },

        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },
        initRoles() {
            this.getRoles()
            .then(response => response.json())
            .then(json => {
                this.roles = []
                if( json.success != undefined && json.success == true ) {
                    this.listRoles(json.data)
                }
            })
            .catch(() => {
                
            })
        },
        listRoles(datas) {
            Array.from(datas).forEach(el => {
                this.roles.push({
                    id: el.id,
                    name: el.name
                })
            })
        },
        submitRole(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }

            let role = {
                name: this.name
            }
            
            if(this.id == '') {
                this.addRole(role)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.roles.push({
                            id: json.data.id,
                            name: json.data.name
                        })
                        this.name = ''
                    }
                })
            } else {
                this.editRole(this.id, role)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.roles.forEach(u => {
                            if(u.id == this.id) {
                                u.name = this.name
                            }
                        })
                        this.name = '',
                        this.id = ''
                    }
                })
            }
        },
        changeRole(data) {
            this.id = data.id,
            this.name = data.name

        },
        removeRole(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.roles = this.roles.filter(u => u.id != data.id)
                this.deleteRole(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.listRoles(json.data)
                    }
                    console.log(json)
                })
            }else{
                return
            }
        },
        async getRoles() {
            return await ApiRole.all()
        },
        async addRole(role) {
            return await ApiRole.create(role)
        },
        async editRole(id, role) {
            return await ApiRole.put(id, role)
        },
        async deleteRole(id) {
            return await ApiRole.delete(id)
        },
    },
    computed: {
        formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>