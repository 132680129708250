import api from '@/utils/api.js'

export default {
    async all() {
        const endpoint = '/stockopname'
        return await api.get(endpoint)
    },
    async get(id) {
        const endpoint = '/stockopname/' + id
        return await api.get(endpoint)
    },
    async create(body) {
        const endpoint = '/stockopname'
        return await api.post(endpoint, body)
    },
    async update(id, body) {
        const endpoint = '/stockopname/' + id
        return await api.put(endpoint, body)
    },
    async delete(id) {
        const endpoint = '/stockopname/' + id
        return await api.delete(endpoint)
    },
    async allProduct() {
        const endpoint = '/stockopname/product'
        return await api.get(endpoint)
    },
    async getProduct(stockOpnameId) {
        const endpoint = '/stockopname/' + stockOpnameId + '/product'
        return await api.get(endpoint)
    },
    async updateProduct(id, body) {
        const endpoint = '/stockopname/product/' + id
        return await api.put(endpoint, body)
    },
    async deleteProduct(id) {
        const endpoint = '/stockopname/product/' + id
        return await api.delete(endpoint)
    },
    async allProductLog(stockOpnameProductId) {
        const endpoint = '/stockopname/product/' + stockOpnameProductId + '/log'
        return await api.get(endpoint)
    },
    async doScanOpname(id, scanedBy, barcode) {
        const endpoint = '/stockopname/scan/barcode?id=' + id + '&scanned_by=' + scanedBy + '&barcode=' + barcode
        return await api.get(endpoint)
    },
    async allUnscannedLog(stockOpnameProductId) {
        const endpoint = '/stockopname/product/' + stockOpnameProductId + '/log/unscanned'
        return await api.get(endpoint)
    },
    async doCheckout(id, type, body) {
        const endpoint = '/stockopname/product/' + id + '/checkout?type=' + type
        return await api.post(endpoint, body)
    }
}