<template>
  <v-container class="set-height">
    <div class="text-h5">STOCK OPNAME</div>

    <v-card class="mt-5">
      <v-data-table
        hide-default-footer
        disable-pagination
        :headers="headers"
        :items="listData"
        sort-by="finished_at"
        class="elevation-1 mt-5"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>List Stock Opname</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <span class="text-button rounded orange white--text px-2"
              >Total = {{ listData.length }}</span
            >
            <v-spacer></v-spacer>

            <v-btn
              color="teal darken-2"
              dark
              class="text-button mb-2"
              @click="dialog = true"
            >
              Tambah
            </v-btn>
          </v-toolbar>
        </template>

        <v-spacer></v-spacer>

        <template v-slot:[`item.started_at`]="{ item }">
          <span>{{ formattedDate(item.started_at) }}</span>
        </template>

        <template v-slot:[`item.finished_at`]="{ item }">
          <v-btn
            v-if="item.finished_at == null"
            color="blue darken-3"
            rounded
            small
            dark
            elevation="0"
            class="text-button"
            @click="showDialogFinish(item)"
            >Finish</v-btn
          >
          <span>{{ formattedDate(item.finished_at) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="purple"
                dark
                small
                v-bind="attrs"
                v-on="on"
                class="ma-1"
                @click="toDetail(item.id)"
              >
                <v-icon>mdi-arrow-right-bold-circle</v-icon>
              </v-btn>
            </template>
            <span class="text-caption">Detail</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.finished_at == null"
                small
                class="orange darken-2 ma-1"
                v-bind="attrs"
                v-on="on"
                dark
                @click="showDialogUpdate(item)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span class="text-caption">Ubah Data</span>
          </v-tooltip>

          <v-tooltip v-if="item.finished_at != null" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="teal ma-1"
                v-bind="attrs"
                v-on="on"
                dark
                @click="downloadReport(item.id, item.started_at)"
              >
                <v-icon> mdi-file-download </v-icon>
              </v-btn>
            </template>

            <span class="text-caption">Report</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="red darken-4 ma-1"
                v-bind="attrs"
                v-on="on"
                dark
                @click="showDialogDelete(item.id)"
              >
                <v-icon> mdi-delete-empty </v-icon>
              </v-btn>
            </template>

            <span class="text-caption">Hapus</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog persistent v-model="dialog" width="500">
        <v-card>
          <!-- toolbar dialog-->
          <v-toolbar dark color="blue darken-3">
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <!-- body dialog -->
          <div class="pa-5">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="stockOpname.description"
                :counter="40"
                :rules="descritionRules"
                label="Judul"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="stockOpname.created_by"
                :counter="20"
                :rules="nameRules"
                label="Dibuat Oleh"
                required
              >
              </v-text-field>

              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    readonly
                    :rules="dateStartRules"
                    :value="computedDateFormattedMomentjs"
                    label="Tanggal Dimulai Stock Opname"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="stockOpname.started_at = null"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="stockOpname.started_at"
                  @change="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-form>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1 text-button" text @click="closeDialog">
              Batal
            </v-btn>
            <v-btn
              v-if="stockOpname.id == 0"
              :disabled="!valid"
              color="blue darken-3"
              class="mr-4 text-button white--text"
              @click="createStockOpname"
            >
              Simpan
            </v-btn>
            <v-btn
              v-else
              :disabled="!valid"
              color="orange darken-2"
              class="mr-4 text-button white--text"
              dark
              @click="updateItem"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog max-width="500px" v-model="dialogFinish">
        <v-card>
          <v-card-title class="text-h6"
            >Akhiri Stok Opname Sekarang? data tidak bisa dirubah ketika selesai
            stock opname</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-2" text rounded @click="closeDialog"
              >Kembali</v-btn
            >
            <v-btn color="teal darken-2 " dark rounded @click="finishItem"
              >Finish</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete">
        <v-card>
          <v-card-title class="text-h6"
            >Anda akan menghapus Stock Opname?. Data yang telah terhapus tidak dapat dikembalikan !</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-2" dark @click="closeDialog"
              >Kembali</v-btn
            >
            <v-btn color="red darken-2" text @click="deleteItem">Hapus</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiStockOpname from "@/api/stockOpname.js";
import { mapGetters } from "vuex";
import moment from "moment";
import Axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      dialogFinish: false,
      dialogDelete: false,

      valid: true,
      datePicker: false,
      nameRules: [
        (v) => !!v || "Nama tidak boleh kosong",
        (v) =>
          (v && v.length <= 20) || "Nama tidak boleh lebih dari 20 karakter",
      ],
      descritionRules: [
        (v) => !!v || "Judul tidak boleh kosong",
        (v) =>
          (v && v.length <= 40) || "Judul tidak boleh lebih dari 40 karakter",
      ],
      dateStartRules: [(v) => !!v || "Tanggal mulai tidak boleh kosong"],

      listData: [],

      headers: [
        { text: "Judul", value: "description" },
        { text: "Dimulai", value: "started_at", align: "center" },
        { text: "Selesai", value: "finished_at", align: "center" },
        { text: "Penanggung Jawab", value: "created_by", align: "center" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      stockOpname: {
        id: 0,
        description: "",
        started_at: "",
        finished_at: "",
        created_by: "",
      },

      defaultStockOpname: {
        id: 0,
        description: "",
        started_at: "",
        finished_at: "",
        created_by: "",
      },
    };
  },
  created() {
    this.initStockOpname();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.stockOpname.started_at
        ? moment(this.stockOpname.started_at).format("DD-MM-YYYY")
        : "";
    },
    formTitle() {
      return this.stockOpname.id === 0
        ? "Tambah Stock Opname"
        : "Edit Stock Opname";
    },
    ...mapGetters(["getServer"]),
  },
  methods: {
    async initStockOpname() {
      await ApiStockOpname.all()
        .then((data) => data.json())
        .then((result) => {
          if (result.success == true) {
            this.listData = [];
            result.data.forEach((item) => {
              this.listData.push({
                ...item,
              });
            });
          } else {
            swal.error(result.message);
          }
        });
    },

    createStockOpname() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const body = {
          created_by: this.stockOpname.created_by,
          started_at: this.stockOpname.started_at + " 08:00",
          description: this.stockOpname.description,
        };

        ApiStockOpname.create(body)
          .then((response) => {
            response.json().then((result) => {
              console.log("response :\n" + JSON.stringify(result));
              if (result.success) {
                swal.success(result.message);
                this.initStockOpname();
              } else {
                swal.error(result.message);
              }
              this.closeDialog();
            });
          })
          .catch((err) => {
            swal.error(err);
          });
        this.closeDialog();
      }
    },

    showDialogFinish(item) {
      this.stockOpname = Object.assign({}, item);
      this.dialogFinish = true;
    },

    finishItem() {
      this.stockOpname.finished_at = Date.now();
      this.updateItem();
    },

    showDialogUpdate(item) {
      this.stockOpname = Object.assign({}, item);
      this.dialog = true;
    },

    showDialogDelete(id) {
      this.stockOpname.id = id;
      this.dialogDelete = true;
    },

    updateItem() {
      const id = this.stockOpname.id;
      const body = {
        description: this.stockOpname.description,
        started_at: this.stockOpname.started_at,
        finished_at: this.stockOpname.finished_at,
        created_by: this.stockOpname.created_by,
      };
      ApiStockOpname.update(id, body)
        .then((response) => {
          response.json().then((result) => {
            if (result.success) {
              swal.success(result.message);
              this.initStockOpname();
            } else {
              swal.error(result.message);
            }
            this.closeDialog();
          });
        })
        .catch((err) => {
          swal.error(err);
          this.closeDialog();
        });
    },

    deleteItem() {
      const id = this.stockOpname.id;
      ApiStockOpname.delete(id).then((response) =>
        response
          .json()
          .then((result) => {
            if (result.success) {
              swal.success(result.message);
              this.initStockOpname();
            } else {
              swal.error(result.message);
            }
            this.closeDialog();
          })
          .catch((err) => {
            swal.error(err);
          })
      );
    },
    closeDialog() {
      this.dialog = false;
      this.dialogFinish = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.stockOpname = Object.assign({}, this.defaultStockOpname);
      });
    },
    downloadReport(id, date) {
      const server = this.getServer;
      const apiUrl = "http://" + server + ":3000/stockopnamereport/" + id;
      Axios.get(apiUrl, {
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "invoice-" + moment(date).format("DDMMyyyy") + ".xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          swal.error(err);
        });
    },
    formattedDate(date) {
      if (date != null) {
        return moment(date).format("DD-MM-yyyy HH:mm");
      } else {
        return "";
      }
    },
    toDetail(id) {
      console.log(id);
      this.$router.push("/stock-opname/" + id);
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>