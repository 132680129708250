<template>
  <div data-app class="container set-height">
    <v-row>
      <v-col cols="12" md="4" class="m4">
        <v-card>
          <v-card-text>
            <span class="card-title"
              >Form Barang Setengah Jadi dan Barang Jadi</span
            >
            <v-text-field label="Nama Barang" v-model="name"></v-text-field>
            <v-text-field label="Harga Barang" v-model="price"></v-text-field>
            <v-select
              :disabled="id != ''"
              :items="cat"
              label="Pilih Tipe Barang"
              v-model="category"
              item-text="id"
              item-value="id"
            ></v-select>
            <v-select
              :items="products"
              label="Pilih Kategori Barang"
              v-model="product_id"
              item-text="name"
              item-value="id"
            ></v-select>

            <div v-if="this.id == ''">
              <v-btn block color="success" @click.prevent="submitProductItem">
                Tambah
              </v-btn>
            </div>
            <div v-else>
              <v-col>
                <v-row>
                  <v-col class="pl-0 py-0">
                    <v-btn block color="error" @click.prevent="clear">
                      Batal
                    </v-btn>
                  </v-col>
                  <v-col class="pr-0 py-0">
                    <v-btn
                      block
                      color="warning"
                      @click.prevent="submitProductItem"
                    >
                      Ubah
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8" class="m8">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="product_items"
            :search="search"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                @click="changeProductItem(item)"
                class="warning ma-1"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-btn small @click="removeProductItem(item)" class="error ma-1">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
              <v-btn small @click="print(item.id)" class="secondary ma-1">
                <v-icon small> mdi-printer </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initProductItems"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiProductItem from "@/api/productItem.js";
import ApiProduct from "@/api/product.js";

export default {
  data() {
    return {
      product_items: [],
      name: "",
      description: "",
      price: "",
      rate: 0,
      product_id: "",
      product: {
        name: "",
      },
      id: "",
      products: "",
      headers: [
        {
          text: "Nama Barang",
          align: "start",
          value: "name",
        },
        { text: "Harga", value: "price" },
        { text: "Upah", value: "rate" },
        { text: "Kategori", value: "category" },
        { text: "Produk", value: "product.name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      loading: false,
      cat: [
        {
          id: "BSJ",
        },
        {
          id: "BJ",
        },
      ],
      category: "",
    };
  },
  mounted() {
    this.initProductItems(), this.initProduct();
  },
  methods: {
    initProductItems() {
      this.getProductItems()
        .then((response) => response.json())
        .then((json) => {
          this.product_items = [];
          if (json.success != undefined && json.success == true) {
            this.listProductItems(json.data);
          }
        })
        .catch(() => {});
    },
    initProduct() {
      this.getProducts()
        .then((response) => response.json())
        .then((json) => {
          this.products = [];
          if (json.success != undefined && json.success == true) {
            this.listProducts(json.data);
          }
        })
        .catch(() => {});
    },
    listProductItems(datas) {
      Array.from(datas).forEach((el) => {
        this.product_items.push({
          id: el.id,
          name: el.name,
          description: el.description,
          price: el.price,
          rate: el.rate,
          category: el.category,
          product_id: el.product_id,
          product: {
            name: el.product.name,
          },
          created_at: el.created_at,
          updated_at: el.updated_at,
        });
      });
    },
    listProducts(datas) {
      Array.from(datas).forEach((el) => {
        this.products.push({
          id: el.id,
          name: el.name,
          description: el.description,
        });
      });
    },
    submitProductItem(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }
      this.loading = !this.loading;
      let product_item = {
        name: this.name,
        description: this.description,
        price: this.price,
        rate: this.rate,
        category: this.category,
        product_id: this.product_id,
        product: {
          name: this.product.name,
        },
      };

      if (this.id == "") {
        this.addProductItem(product_item)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.getProduct(json.data.product_id)
                .then((result) => result.json())
                .then((product) => {
                  swal.success(json.message);
                  this.product_items.push({
                    id: json.data.id,
                    name: json.data.name,
                    description: json.data.description,
                    price: json.data.price,
                    product_id: json.data.product_id,
                    rate: json.data.rate,
                    category: json.data.category,
                    product: {
                      name: product.data.name,
                    },
                  });
                });
              this.clear();
            } else {
              swal.error(json.message);
            }
          });
      } else {
        let id = this.id;
        let name = this.name;
        let description = this.description;
        let price = this.price;
        let rate = this.rate;
        let category = this.category;
        let product_id = this.product_id;
        this.editProductItem(this.id, product_item)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.getProduct(this.product_id)
                .then((result) => result.json())
                .then((product) => {
                  swal.success(json.message);
                  this.product_items.forEach((p) => {
                    if (p.id == id) {
                      (p.name = name),
                        (p.description = description),
                        (p.price = price),
                        (p.rate = rate),
                        (p.category = category),
                        (p.product_id = product_id),
                        (p.product.name = product.data.name);
                    }
                  });
                });
              this.clear();
            } else {
              swal.error(json.message);
            }
          });
      }
      this.loading = !this.loading;
    },
    changeProductItem(data) {
      (this.name = data.name),
        (this.description = data.description),
        (this.price = data.price),
        (this.rate = data.rate),
        (this.product_id = data.product_id),
        (this.id = data.id),
        (this.product.name = data.product.name),
        (this.category = data.category);
    },
    removeProductItem(data) {
      let response = confirm(`Are you sure you want to delete ${data.name}`);
      if (response) {
        this.deleteProductItem(data.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              swal.success(json.message);
              this.initProductItems();
            }
          });
      } else {
        return;
      }
    },
    async getProductItems() {
      return await ApiProductItem.all();
    },
    async addProductItem(product_item) {
      return await ApiProductItem.create(product_item);
    },
    async editProductItem(id, product_item) {
      return await ApiProductItem.put(id, product_item);
    },
    async deleteProductItem(id) {
      return await ApiProductItem.delete(id);
    },
    async getProducts() {
      return await ApiProduct.all();
    },
    async getProduct(id) {
      return await ApiProduct.get(id);
    },
    async print(id) {
      return await ApiProductItem.print(id);
    },
    submit(event) {
      if (event.keyCode == 13) {
        swal.success("Mantap");
      }
    },
    clear() {
      this.id = "";
      this.name = "";
      this.description = "";
      this.price = "";
      this.rate = "";
      this.product_id = "";
      this.category = "";
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>