<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form Bahan Baku</span>
                        <v-text-field
                            label="Nama Bahan Baku"
                            v-model="name"
                        ></v-text-field>
                        <!-- <v-text-field
                            label="Deskripsi Bahan Baku"
                            v-model="description"
                        ></v-text-field> -->
                        <v-text-field
                            label="Harga Bahan Baku"
                            v-model="price"
                        ></v-text-field>
                        <v-select
                            :items="suppliers"
                            label="Pilih Supplier"
                            v-model="supplier_id"
                            item-text="name"
                            item-value="id"
                        ></v-select>
                        <v-select
                            :items="categories"
                            label="Pilih Kategori"
                            v-model="category_id"
                            item-text="name"
                            item-value="id"
                        ></v-select>
                        <div v-if="(this.id == '')">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="submitRawMaterial"
                                >
                                Tambah
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        block
                                        color="error"
                                        @click.prevent="clear"
                                        >
                                        Batal
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        block
                                        color="warning"
                                        @click.prevent="submitRawMaterial"
                                        >
                                        Ubah
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="raw_materials"
                        :search="search"
                        sort-by="name"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.category.name`]="{ item }">
                            <span>{{ item.category_id ? item.category.name : '-' }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small @click="changeRawMaterial(item)" class="warning ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn small @click="removeRawMaterial(item)" class="error ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initRawMaterials"
                        >
                            Reset
                        </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiRawMaterial from '@/api/rawMaterial.js'
import ApiSupplier from '@/api/supplier.js'
import ApiRawMaterialCategory from '@/api/rawMaterialCategory.js'


export default {
    data() {
        return {
            dialog: false,
            raw_materials: [],
            suppliers: [],
            categories: [],
            name: '',
            description: '',
            price: '',
            supplier_id: 0,
            category_id: 0,
            id: 0,
            supplier: {
                name: ''
            },
            headers: [
                {
                    text: 'Nama Bahan Baku',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Harga', value: 'price' },
                { text: 'Supplier', value: 'supplier.name' },
                { text: 'Kategori', value: 'category.name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
            loading: false,
        }
    },
    mounted() {
        this.initRawMaterials()
        this.initSuppliers()
        this.initCategories()
    },
    methods: {
        initRawMaterials() {
            this.getRawMaterials()
            .then(response => response.json())
            .then(json => {
                this.raw_materials = []
                if( json.success != undefined && json.success == true ) {
                    this.listRawMaterials(json.data)
                }
            })
            .catch(err => {
                swal.error(err)
            })
        },
        initSuppliers() {
            this.getSuppliers()
            .then(response => response.json())
            .then(json => {
                if( json.success != undefined && json.success == true ) {
                    this.listSuppliers(json.data)
                }
            })
        },
        initCategories() {
            this.getCategories()
            .then(res => res.json())
            .then(json => {
                if(json.success) {
                    this.listCategories(json.data)
                }
            })
        },
        listRawMaterials(datas) {
            Array.from(datas).forEach(el => {
                if(el.category_id) {
                    this.raw_materials.push({
                        id: el.id,
                        name: el.name,
                        description: el.description,
                        price: el.price,
                        supplier_id: el.supplier_id,
                        supplier: {
                            name: el.supplier.name
                        },
                        category_id: el.category_id,
                        category: {
                            name: el.category.name
                        },  
                        created_at: el.created_at,
                        updated_at: el.updated_at,
                    })
                } else {
                    this.raw_materials.push({
                        id: el.id,
                        name: el.name,
                        description: el.description,
                        price: el.price,
                        supplier_id: el.supplier_id,
                        supplier: {
                            name: el.supplier.name
                        },
                        category_id: 0,
                        category: {
                            name: ''
                        },  
                        created_at: el.created_at,
                        updated_at: el.updated_at,
                    })
                }
            })
        },
        listSuppliers(datas) {
            Array.from(datas).forEach(el => {
                this.suppliers.push({
                    id: el.id,
                    name: el.name,
                    address: el.address,
                    telp: el.telp,
                })
            })
        },
        listCategories(datas) {
            Array.from(datas).forEach(el => {
                this.categories.push({
                    id: el.id,
                    name: el.name,
                    description: el.description, 
                })
            })
        },
        submitRawMaterial(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }
            this.loading = !this.loading
            let raw_material = {
                name: this.name,
                description: this.description,
                price: this.price,
                supplier_id: this.supplier_id,
                category_id: this.category_id, 
            }
            
            if(this.id == 0) {
                raw_material.description = ''
                this.addRawMaterial(raw_material)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.initRawMaterials()
                        swal.success(json.message)
                        this.clear()
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                this.editRawMaterial(this.id, raw_material)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        this.initRawMaterials()
                        swal.success(json.message)
                        this.clear()
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err =>{
                    swal.error(err)
                })
            } 
            this.loading = !this.loading
        },
        changeRawMaterial(data) {
            this.name = data.name
            this.description = data.description
            this.price = data.price
            this.supplier_id = data.supplier_id
            this.category_id = data.category_id
            this.id = data.id
        },
        removeRawMaterial(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.deleteRawMaterial(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.initRawMaterials()
                    } else {
                        swal.error(json.message)
                    }
                })
                .catch(err => {
                    swal.error(err)
                })
            }else{
                return
            }
        },
        async getRawMaterials() {
            return await ApiRawMaterial.all()
        },
        async addRawMaterial(raw_material) {
            return await ApiRawMaterial.create(raw_material)
        },
        async editRawMaterial(id, raw_material) {
            return await ApiRawMaterial.put(id, raw_material)
        },
        async deleteRawMaterial(id) {
            return await ApiRawMaterial.delete(id)
        },
        async getSuppliers() {
            return await ApiSupplier.all()
        },
        async getSupplier(id) {
            return await ApiSupplier.get(id)
        },
        async getCategories() {
            return await ApiRawMaterialCategory.all()
        },
        async getCategory(id) {
            return await ApiRawMaterialCategory.get(id)
        },
        submit(event) {
            if(event.keyCode == 13) {
                swal.success('Mantap')
            }
        },
        clear() {
            this.id = 0
            this.name =  ''
            this.description = ''
            this.price =  ''
            this.supplier_id = 0
            this.category_id = 0
        },
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>