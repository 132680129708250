<template>
  <v-container fill-height class="set-height">
    <v-row justify="center" align="center" class="justify-center align-center" no-gutters>
      <v-col cols="12" sm="3">
        <v-card class="pa-2" outlined tile>
          <v-card-text class="card-content">
            <v-text-field
                label="Server"
                v-model="server"
                type="text"
                v-on:keydown.enter.prevent="login"
            ></v-text-field>
            <v-text-field
                label="Username"
                v-model="username"
                type="text"
                v-on:keydown.enter.prevent="login"
            ></v-text-field>
            <v-text-field
                label="Password"
                v-model="password"
                type="password"
                v-on:keydown.enter.prevent="login"
            ></v-text-field>
            <v-btn
                block
                color="success"
                @click.prevent="login"
                >
                Login
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/api/auth.js'
import swal from '@/utils/alert.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      username: '',
      password: '',
      server: '',
    }
  },
  computed: {
    ...mapGetters(["getServer"])
  },
  mounted() {
    this.server = this.getServer
  },
  methods: {
    ...mapActions(["initServer", "deleteServer"]),
    ...mapMutations(["setUser", "setToken", "auth_success", "auth_error"]),
    async login() {
      if( this.username == '' || this.password == '' || this.server == '') {
        swal.error('Server, Username dan Password harus dimasukkan')
        return
      }
      this.initServer(this.server)

      await auth.login(this.username, this.password)
      .then(response => response.json())
      .then(json => {
        if(json.success){
          this.auth_success()
          this.setUser(json.user)
          this.setToken(json.token)
          swal.success(json.message)
          if ( json.user.role_id == 1 ) {
            this.$router.push({ name: 'Home' })
          } else if ( json.user.role_id == 2 ) {
            this.$router.push({ name: 'Home' })
          } else if ( json.user.role_id == 3 ) {
            this.$router.push({ name: 'RawMaterial.Log' })
          } else if ( json.user.role_id == 4 ) {
            this.$router.push({ path: 'product-item/bsj/checkin' })
          } else if ( json.user.role_id == 5 ) {
            this.$router.push({ name: 'ProductItem.BJCheckin' })
          }
        } else {
          this.auth_error()
          swal.error(json.message)
        }
      })
      .catch(error => {
        swal.error(error)
      })

    },
    submit(event) {
      if( event.keyCode == 13 ) {
        this.login()
      }
    }
  }
}
</script>


<style>
    .set-height {
        min-height: 90vh;
    }
</style>