import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/role')
    },
    async get(id) {
        return await api.get('/role/' + id)
    },
    async create(role) {
        return await api.post('/role', role)
    },
    async put(id, role) {
        return await api.put('/role/' + id, role)
    },
    async delete(id) {
        return await api.delete('/role/' + id)
    }
}