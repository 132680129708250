<template>
  <div data-app class="container set-height">
    <v-row>
      <v-col cols="12" md="4" class="m4">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <label for="invoiced_id">Form Invoice</label>
                <v-text-field
                  label="Nomor Invoice"
                  v-model="note"
                ></v-text-field>
                <v-text-field
                  label="Nomor Surat Jalan"
                  v-model="delivery_no"
                ></v-text-field>
                <v-text-field
                  label="Kepada"
                  v-model="invoiced_to"
                ></v-text-field>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="invoicedAtFormated"
                      clearable
                      label="Tanggal Invoice"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="invoiced_at = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoiced_at"
                    year-icon="mdi-calendar-blank"
                    color="primary"
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field label="Alamat" v-model="address"></v-text-field>
                <v-text-field
                  label="Potongan"
                  v-model="discount"
                ></v-text-field>
                <v-text-field
                  label="Dibuat Oleh"
                  v-model="created_by"
                ></v-text-field>
                <v-text-field
                  label="Disetujui Oleh"
                  v-model="checked_by"
                ></v-text-field>
                <v-text-field
                  label="Nomor Angkutan"
                  v-model="vehicle_no"
                ></v-text-field>
                <div v-if="this.id == ''">
                  <v-btn block color="success" @click.prevent="submitInvoice">
                    Tambah
                  </v-btn>
                </div>
                <div v-else>
                  <v-col>
                    <v-row>
                      <v-col class="pl-0 py-0">
                        <v-btn block color="error" @click.prevent="clear">
                          Batal
                        </v-btn>
                      </v-col>
                      <v-col class="pr-0 py-0">
                        <v-btn
                          block
                          color="warning"
                          @click.prevent="submitInvoice"
                        >
                          Ubah
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" class="m4">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="invoices"
            :search="search"
            :sort-by="[`invoiced_at`,`note`]"
            :sort-desc="[`true`,`true`]"
            class="elevation-1"
          >
            <template v-slot:[`item.invoiced_at`]="{ item }">
              <span>{{ formattedDate(item.invoiced_at) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="changeInvoice(item)"
                    class="warning ma-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Ubah Invoice</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="checkoutTemp(item)"
                    class="green ma-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-barcode-scan </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Tambah Produk</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="detailInvoice(item)"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="primary ma-1"
                  >
                    <v-icon small> mdi-format-list-bulleted </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Lihat Produk</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="print(item.id)"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="secondary ma-1"
                  >
                    <v-icon small> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Invoice</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="printItem(item.id)"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="secondary ma-1"
                  >
                    <v-icon small> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Invoice Item</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="printSJ(item.id)"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="secondary ma-1"
                  >
                    <v-icon small> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Surat Jalan</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="removeInvoice(item)"
                    class="error ma-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Hapus Invoice</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initInvoices"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiProductItem from "@/api/productItem.js";
import ApiProduct from "@/api/product.js";
import ApiInvoice from "@/api/invoice.js";
import ApiInvoiceItem from "@/api/invoiceItem.js";
import moment from "moment";
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      invoices: [],
      id: "",
      invoiced_to: "",
      invoiced_at: new Date().toISOString().substr(0, 10),
      address: "",
      discount: 0,
      note: "",
      delivery_no: "",
      created_by: "Putri",
      checked_by: "Narti",
      vehicle_no: "",
      invoice: {
        invoiced_to: "",
        invoiced_at: "",
        address: "",
      },
      invoice_items: [],
      barcodes: [],
      barcode: "",
      invoice_id: "",
      invoice_name: "",
      list: "",
      headers: [
        { text: "Nomor Invoice", value: "note", align:"center" },
        { text: "Nomor Surat Jalan", value: "delivery_no", align: "center" },
        {
          text: "Invoice Kepada",
          value: "invoiced_to",
        },
        { text: "Alamat", value: "address" },
        { text: "Tanggal Dibuat", value: "invoiced_at" },
        { text: "Nomor Kendaraan", value: "vehicle_no", align:"center" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      check: "",
      menu: false,
    };
  },
  mounted() {
    this.initInvoices(), this.initProductItems(), this.initProduct();
  },
  computed: {
    invoicedAtFormated() {
      return this.invoiced_at
        ? moment(this.invoiced_at).locale("id").format("DD MMMM YYYY")
        : "";
    },
    ...mapGetters(["getServer"]),
  },
  methods: {
    initProductItems() {
      this.getProductItems()
        .then((response) => response.json())
        .then((json) => {
          this.product_items = [];
          if (json.success != undefined && json.success == true) {
            this.listProductItems(json.data);
          }
        })
        .catch(() => {});
    },
    initProduct() {
      this.getProducts()
        .then((response) => response.json())
        .then((json) => {
          this.products = [];
          if (json.success != undefined && json.success == true) {
            this.listProducts(json.data);
          }
        })
        .catch(() => {});
    },
    listProductItems(datas) {
      Array.from(datas).forEach((el) => {
        this.product_items.push({
          id: el.id,
          name: el.name,
          description: el.description,
          price: el.price,
          rate: el.rate,
          product_id: el.product_id,
          product: {
            name: el.product.name,
          },
          created_at: el.created_at,
          updated_at: el.updated_at,
        });
      });
    },
    listProducts(datas) {
      Array.from(datas).forEach((el) => {
        this.products.push({
          id: el.id,
          name: el.name,
          description: el.description,
        });
      });
    },
    initInvoices() {
      this.getInvoices()
        .then((response) => response.json())
        .then((json) => {
          this.invoices = [];
          if (json.success != undefined && json.success == true) {
            this.listInvoices(json.data);
          }
        })
        .catch(() => {});
    },
    listInvoices(datas) {
      Array.from(datas).forEach((el) => {
        this.invoices.push({
          id: el.id,
          invoiced_to: el.invoiced_to,
          invoiced_at: el.invoiced_at,
          discount: el.discount,
          address: el.address,
          note: el.note,
          delivery_no: el.delivery_note_no,
          created_by: el.delivery_note_created_by,
          checked_by: el.delivery_note_checked_by,
          vehicle_no: el.delivery_note_vehicle_no,
        });
      });
    },
    changeInvoice(data) {
      this.id = data.id;
      this.invoiced_to = data.invoiced_to;
      this.invoiced_at = data.invoiced_at;
      this.address = data.address;
      this.note = data.note;
      this.discount = data.discount;
      this.delivery_no = data.delivery_no;
      this.created_by = data.created_by;
      this.checked_by = data.checked_by;
      this.vehicle_no = data.vehicle_no;
      const a = JSON.stringify(data);
      console.log("dataku-click :\n" + a);
    },
    removeInvoice(data) {
      let response = confirm(
        `Are you sure you want to delete invoice ${data.note} to ${data.invoiced_to} ${data.invoiced_at}`
      );
      if (response) {
        this.invoices = this.invoices.filter((i) => i.id != data.id);
        this.deleteInvoice(data.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.initInvoices();
              swal.success(json.message);
            }
          });
      } else {
        return;
      }
    },
    submitInvoice() {
      let invoice = {
        invoiced_to: this.invoiced_to,
        invoiced_at: this.invoiced_at,
        address: this.address,
        note: this.note,
        discount: this.discount,
        delivery_note_no: this.delivery_no,
        delivery_note_created_by: this.created_by,
        delivery_note_checked_by: this.checked_by,
        delivery_note_vehicle_no: this.vehicle_no,
      };

      console.log(invoice);
      if (this.id == "") {
        this.addInvoices(invoice)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              this.clear();
              this.initInvoices()
              swal.success(json.message);
            } else {
              swal.error(json.message);
            }
          });
      } else {
        let id = this.id;
        let invoiced_to = this.invoiced_to;
        let invoiced_at = this.invoiced_at;
        let address = this.address;
        let note = this.note;
        let discount = this.discount;
        let delivery_no = this.delivery_no;
        let created_by = this.created_by;
        let checked_by = this.checked_by;
        let vehicle_no = this.vehicle_no;

        this.editInvoice(this.id, invoice)
          .then((response) => response.json())
          .then((json) => {
            if (json.success != undefined && json.success == true) {
              swal.success(json.message);
              this.invoices.forEach((p) => {
                if (p.id == id) {
                  p.invoiced_to = invoiced_to;
                  p.invoiced_at = invoiced_at;
                  p.address = address;
                  p.note = note;
                  p.discount = discount;
                  p.delivery_no = delivery_no;
                  p.created_by = created_by;
                  p.checked_by = checked_by;
                  p.vehicle_no = vehicle_no;
                }
              });
              this.clear();
            } else {
              swal.error(json.message);
            }
          });
      }
    },
    addToList(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }

      if (this.invoice_id == "") {
        swal.error("Silahkan pilih Invoice Header");
        return;
      }

      if (this.barcode == "") {
        swal.error("Barcode tidak boleh kosong!");
        return;
      }

      this.barcode = this.barcode.replace(/\s/g, "");
      var check = this.barcodes.some((b) => b.barcode == this.barcode);
      if (check == false) {
        this.checkBarcode(this.barcode)
          .then((barcode) => barcode.json())
          .then((result) => {
            if (result.success != undefined && result.success == true) {
              swal.success(result.message);
              this.barcodes.push({
                product_name: result.data.product_item.name,
                amount: result.data.amount,
                barcode: result.data.barcode,
              });
            } else {
              swal.error(result.message);
            }
          });
      } else {
        swal.error("Barang sudah ada di list!");
      }
      this.barcode = "";
    },
    removeBarcode(data) {
      let response = confirm(`Are you sure you want to delete ${data.barcode}`);
      if (response) {
        this.barcodes = this.barcodes.filter((i) => i.barcode != data.barcode);
      } else {
        return;
      }
    },
    submit(event) {
      if (event.keyCode != undefined && event.keyCode != 13) {
        return;
      }
      let list = {
        invoice_id: this.invoice_id,
        barcodes: this.barcodes,
      };
      this.addBarcode(list)
        .then((result) => result.json())
        .then((json) => {
          swal.success(json.message);
        });
      this.barcodes = "";
    },
    reset() {
      this.barcodes = [];
    },

    //DOWNLOAD INVOICE
    print(id) {
      let inv_id = id;
      console.log(inv_id);
      const apiURL = "http://" + this.getServer + ":3000" || "";
      Axios.get(apiURL + "/invoice/export/" + inv_id, {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "invoice.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
    },

    //DOWNLOAD INVOICE ITEM
    printItem(id) {
      let inv_id = id;
      const apiURL = "http://" + this.getServer + ":3000" || "";
      Axios.get(apiURL + "/invoice/export/item/" + inv_id, {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "invoice_item.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
    },

    //DOWNLOAD SURAT JALAN
    printSJ(id) {
      let inv_id = id;
      const apiURL = "http://" + this.getServer + ":3000" || "";
      Axios.get(apiURL + "/invoice/export/sj/" + inv_id, {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "surat_jalan.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          swal.error(error);
        });
    },

    detailInvoice(data) {
      this.$router.push({ name: "Invoice.Item", params: { id: data.id } });
    },
    checkoutTemp(data) {
      this.$router.push({
        name: "Invoice.CheckoutTemp",
        params: { id: data.id },
      });
    },
    async getProductItems() {
      return await ApiProductItem.all();
    },
    async addProductItem(product_item) {
      return await ApiProductItem.create(product_item);
    },
    async editProductItem(id, product_item) {
      return await ApiProductItem.put(id, product_item);
    },
    async deleteProductItem(id) {
      return await ApiProductItem.delete(id);
    },
    async getProducts() {
      return await ApiProduct.all();
    },
    async getProduct(id) {
      return await ApiProduct.get(id);
    },
    async getInvoices() {
      return await ApiInvoice.all();
    },
    async getInvoice(id) {
      return await ApiInvoice.get(id);
    },
    async addInvoices(invoice) {
      return await ApiInvoice.create(invoice);
    },
    async editInvoice(id, invoice) {
      return await ApiInvoice.put(id, invoice);
    },
    async deleteInvoice(id) {
      return await ApiInvoice.delete(id);
    },
    async excelInvoice(id) {
      return await ApiInvoice.export(id);
    },
    async checkBarcode(barcode) {
      return await ApiInvoiceItem.getBarcode(barcode);
    },
    async addBarcode(data) {
      return await ApiInvoiceItem.addBarcode(data);
    },
    formattedDate(value) {
      return moment(value).locale("id").format("DD-MM-yyyy");
    },
    clear() {
      this.id = "";
      this.invoiced_to = "";
      this.invoiced_at = new Date().toISOString().substr(0, 10);
      this.address = "";
      this.note = "";
      this.discount = 0;
      this.delivery_no = "";
      this.vehicle_no = "";
    },
  },
};
</script>

<style>
.set-height {
  min-height: 90vh;
}
</style>
