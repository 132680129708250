import api from '@/utils/api.js'

export default {
    async all() {
        return await api.get('/supplier')
    },
    async get(id) {
        return await api.get('/supplier/' + id)
    },
    async create(supplier) {
        return await api.post('/supplier', supplier)
    },
    async put(id, supplier) {
        return await api.put('/supplier/' + id, supplier)
    },
    async delete(id) {
        return await api.delete('/supplier/' + id)
    }
}