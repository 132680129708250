import List from '@/views/operator/List'

export default [
    {
        name: 'Operator',
        path: '/operator',
        component: List,
        meta: {
            title: 'Operator List',
            requiresAuth: true
        }
    }
]