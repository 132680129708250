import List from '@/views/raw-material/List'
import Log from '@/views/raw-material/Log.vue'
import Checkout from '@/views/raw-material/Checkout'
import Category from '@/views/raw-material/Category'
import Filter from '@/views/raw-material/Filter'

export default [
    {
        name: 'RawMaterial',
        path: '/raw-material',
        component: List,
        meta: {
            title: 'List Bahan Baku',
            requiresAuth: true
        }
    },
    {
        name: 'RawMaterial.Log',
        path: '/raw-material-log',
        component: Log,
        meta: {
            title: 'Log Bahan Baku',
            requiresAuth: true
        }
    },
    {
        name: 'RawMaterial.Checkout',
        path: '/raw-material/checkout',
        component: Checkout,
        meta: {
            title: 'Check Out Bahan Baku',
            requiresAuth: true
        }
    },
    {
        name: 'RawMaterial.Category',
        path: '/raw-material-category',
        component: Category,
        meta: {
            title: 'Kategori Bahan Baku',
            requiresAuth: true,
        }
    },
    {
        name: 'RawMaterial.Filter',
        path: '/raw-material/filter',
        component: Filter,
        meta: {
            title: 'Filter Inventory Log BB',
            requiresAuth: true
        }
    },
]