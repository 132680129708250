import RawMaterialLogApi from '../api/rawMaterialLog'

const state = {
    rawItems: [],
    rawItemsPagination: {},
    loadingRawMaterial: false,
}

const getters = {
    rawMaterialLog(state) {
        return state.rawItems
    },
    rawMaterialLogPagination(state) {
        return state.rawItemsPagination
    },
    loadingRawMaterial(state) {
        return state.loadingRawMaterial
    },
}

const actions = {
    async fetchRawMaterialLog({commit}, payload) {
        commit('setLoadingRawMaterial', true)
        await RawMaterialLogApi.all(payload.query, payload.options)
        .then(data => data.json())
        .then(res => {
            commit('setRawItems', res.data)
            const pagination = {
                page: res.pages.current_page,
                per_page: res.pages.per_page,
                total: res.pages.total,
                last_page: res.pages.last_page,
            }
            commit('setRawItemsPagination', pagination)
        })
        .catch(err => {
            throw (err)
        })
        commit('setLoadingRawMaterial', false)
    }
}

const mutations = {
    setRawItems(state, items) {
        state.rawItems = items
    },
    setRawItemsPagination(state, pagination) {
        state.rawItemsPagination = pagination
    },
    setLoadingRawMaterial(state, loading) {
        state.loadingRawMaterial = loading
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}