import api from '@/utils/api.js'

export default {
    async all(type = null) {
        let endpoint = '/productitem'
        if(type) {
            endpoint = endpoint + '?type=' + type
        }
        return await api.get(endpoint)
    },
    async get(id) {
        return await api.get('/productitem/' + id)
    },
    async create(productitem) {
        return await api.post('/productitem', productitem)
    },
    async put(id, productitem) {
        return await api.put('/productitem/' + id, productitem)
    },
    async delete(id) {
        return await api.delete('/productitem/' + id)
    },
    async postName(name) {
        return await api.post('/productitem/name/', name)
    },
    async bsj() {
        return await api.get('/productitem/bsj')
    },
    async print(id) {
        return await api.get('/productitem/print/'+ id)
    },
    async bj() {
        return await api.get('/productitem/bj')
    }
}