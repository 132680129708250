<template>
  <div data-app class="container set-height">
    <v-main>
      <v-container>
        <!-- FILTER -->
        <v-row class="d-flex justify-center align-top">
          <v-col>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  rounded
                  solo
                  clearable
                  readonly
                  :value="computedDateFormattedMomentjs"
                  label="pilih tanggal"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date_in = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_in"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-autocomplete
              solo
              clearable
              rounded
              :items="operators"
              v-model="operator_id"
              label="Operator"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              solo
              clearable
              rounded
              :items="productItems"
              v-model="product_item_id"
              label="Produk"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col :cols="1" class="align-center">
            <v-btn rounded large color="primary" dark v-on:click="doFilter">
              Cari
            </v-btn>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <!-- Data List -->
        <v-data-table
          v-model="selected"
          dense
          hide-default-footer
          disable-pagination
          show-select
          :headers="headers"
          :items="productLogs"
          :search="search"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-chip color="orange text-button" text-color="white">
                <span class="font-weight-bold"
                  >Total : {{ productLogs.length }}</span
                >
              </v-chip>
              <v-text-field
                class="mx-4"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                small
                rounded
                color="secondary"
                class="text-button white--text"
                @click="doPrintAll"
              >
                <v-icon left>mdi-printer</v-icon>
                Print Semua {{ selected.length }}
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:></template>
          <template v-slot:[`item.date_in`]="{ item }">
            <span>{{ formattedDate(item.date_in) }}</span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span justify="center">{{ formattedDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.date_out`]="{ item }">
            <span v-if="item.date_out == null || item.date_out == ''">-</span>
            <span v-else>{{ formattedDate(item.date_out) }}</span>
          </template>
          <template v-slot:no-data>
            <span> Data belum ada </span>
          </template>
        </v-data-table>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import swal from "@/utils/alert.js";
import ApiProcessedMaterialLog from "@/api/processedMaterialLog.js";
import ApiProductItem from "@/api/productItem.js";
import ApiOperator from "@/api/operator.js";
import ApiPrint from "@/api/print.js";
import moment from "moment";

export default {
  data() {
    return {
      selected: [],
      id: "",
      product_item_id: "",
      operator_id: "",
      amount: "",
      status: "",
      processed_times: "",
      barcode: "",
      date_in: new Date().toISOString().substr(0, 10),
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      date_out: "",
      created_at: "",

      product_item: {
        name: "",
      },
      operator: {
        name: "",
      },

      //list product item
      productItems: [],
      //list operator
      operators: [],
      // list processed material logs
      productLogs: [],
      menu1: false,
      search: "",

      headers: [
        {
          text: "No",
          value: "number",
        },
        {
          text: "Barang Setengah Jadi",
          align: "start",
          value: "product_item.name",
          width: "30%",
        },
        { text: "Operator", value: "operator.name" },
        { text: "Berat", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Tanggal Masuk", value: "date_in" },
        { text: "Tanggal Keluar", value: "date_out" },
        { text: "Dikeluarkan", value: "checkout_by" },
        { text: "Barcode", value: "barcode" },
        { text: "Dicetak", value: "print_count" },
      ],
    };
  },
  created() {
    this.initListProductItem();
    this.initOperator();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date_in ? moment(this.date_in).format("DD-MM-YYYY") : "";
    },
  },
  methods: {
    async initOperator() {
      await ApiOperator.all()
        .then((response) => response.json())
        .then((json) => {
          this.operators = [];
          if (json.success == true) {
            this.operators = json.data;
          }
        })
        .catch((err) => {
          console.log("Error get List Operator : " + err);
        });
    },

    async initListProductItem() {
      const type = "BSJ";
      await ApiProductItem.all(type)
        .then((response) => response.json())
        .then((json) => {
          if (json.success == true) {
            this.productItems = json.data;
          }
        })
        .catch((err) => {
          console.log("Error get List Product Item : " + err);
        });
    },

    async doFilter() {
      console.log("opt : " + JSON.stringify(this.operator_id));
      console.log("prod : " + JSON.stringify(this.product_item_id));
      console.log("date : " + JSON.stringify(this.date_in));
      await ApiProcessedMaterialLog.filter(
        "bsj",
        this.operator_id,
        this.product_item_id,
        this.date_in
      )
        .then((response) => response.json())
        .then((json) => {
          if (json.success == true && json.data.length > 0) {
            this.productLogs = [];
            for (let i = 0; i < json.data.length; i++) {
              this.productLogs.push({
                number: i + 1,
                ...json.data[i],
              });
            }
          } else {
            swal.error(json.message);
            this.productLogs = [];
          }
        })
        .catch((err) => {
          swal.error(err);
          console.log("Error get List Product Item : " + err);
        });
    },

    doPrintAll() {
      if (this.selected.length > 0) {
        const barcodes = {
          barcodes: [],
        };
        this.selected.forEach((item) => {
          barcodes.barcodes.push(item.barcode);
        });
        ApiPrint.printAll("bsj", barcodes).then((response) =>
          response.json().then((result) => {
            if (result.success) {
              this.doFilter();
              swal.success(result.message);
            } else {
              swal.error(result.message);
            }
          })
        );
        console.log(JSON.stringify(barcodes));
      } else {
        swal.error("Pilih Barcode yang akan di Print");
      }
    },
    formattedDate(date) {
      if (date != null) {
        return moment(date).format("DD-MM-YYYY HH:mm");
      } else {
        return "";
      }
    },
  },
};
</script>
