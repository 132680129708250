<template>
    <div data-app class="container set-height">
        <v-row>
            <v-col cols="12" md="4" class="m4">
                <v-card>
                    <v-card-text>
                        <span class="card-title">Form User</span>
                        <v-text-field
                            label="Nama"
                            v-model="name"
                            v-on:keydown.enter.prevent="submitUser"
                        ></v-text-field>
                        <v-text-field
                            label="Username"
                            v-model="username"
                            v-on:keydown.enter.prevent="submitUser"
                        ></v-text-field>
                        
                        <v-select
                            :items="roles"
                            label="Pilih Role"
                            v-model="role_id"
                            item-text="name"
                            item-value="id"
                            v-on:keydown.enter.prevent="submitUser"
                        ></v-select>
                        
                        <v-switch
                            v-if="id != ''"
                            v-model="switch1"
                            :label="`Ganti Password`"
                        ></v-switch>
                        <div v-if="id == ''">                            
                            <v-text-field
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                label="Password"
                                hint="Minimal 8 karakter"
                                counter
                                @click:append="show1 = !show1"
                                v-on:keydown.enter.prevent="submitUser"
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="passwordConfirmation"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[passwordConfirmationRule]"
                                :type="show2 ? 'text' : 'password'"
                                label="Konfirmasi Password"
                                hint=""
                                counter
                                @click:append="show2 = !show2"
                                v-on:keydown.enter.prevent="submitUser"
                            ></v-text-field>
                        </div>
                        <div v-else-if="switch1">
                            <v-text-field
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                label="Password Lama"
                                counter
                                @click:append="show1 = !show1"
                                v-on:keydown.enter.prevent="submitUser"
                                class="mb-3"
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="passwordNew"
                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.min]"
                                :type="show3 ? 'text' : 'password'"
                                label="Password Baru"
                                counter
                                @click:append="show3 = !show3"
                                v-on:keydown.enter.prevent="submitUser"
                            ></v-text-field>


                            <v-text-field
                                v-model="passwordNewConfirmation"
                                :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[passwordNewConfirmationRule]"
                                :type="show4 ? 'text' : 'password'"
                                label="Konfirmasi Password Baru"
                                counter
                                @click:append="show4 = !show4"
                                v-on:keydown.enter.prevent="submitUser"
                            ></v-text-field>
                        </div>

                        <div v-if="(this.id == '')" class="mt-3">
                            <v-btn
                                block
                                color="success"
                                @click.prevent="submitUser"
                                >
                                Tambah
                            </v-btn>
                        </div>
                        <div v-else class="mt-3">
                            <v-col>
                                <v-row>
                                    <v-col class="pl-0 py-0">
                                        <v-btn
                                            block
                                            color="error"
                                            @click.prevent="clear"
                                        >
                                            Batal
                                        </v-btn>
                                    </v-col>
                                    <v-col class="pr-0 py-0">
                                        <v-btn
                                            block
                                            color="warning"
                                            @click.prevent="submitUser"
                                            >
                                            Ubah
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" class="m8">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                            <v-btn x-small fab class="mx-2" color="green" dark v-on:click="reload">
                            <v-icon dark center>mdi-cached</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="users"
                        :search="search"
                        sort-by="name"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small @click="changeUser(item)" class="warning ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn small @click="removeUser(item)" class="error ma-1">
                                <v-icon
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:no-data>
                            <v-btn
                                color="primary"
                                @click="initUsers"
                            >
                                Reset
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-dialog
            v-model="dialog"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    Masukkan password
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Masukkan password anda"
                        v-model="passwordDialog"
                    ></v-text-field>
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import swal from '@/utils/alert.js'
import ApiUser from '@/api/user.js'
import ApiRole from '@/api/role.js'


export default {
    data() {
        return {
            users: [],
            roles: [],
            username: '',
            name: '',
            password: '',
            role_id: '',
            id: '',
            role: {
                name: ''
            },
            headers: [
                {
                text: 'Nama User',
                align: 'start',
                value: 'name',
                },
                { text: 'Username', value: 'username' },
                { text: 'Role', value: 'role.name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            search: '',
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            switch1: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => (v.length >= 8 || v.length == 0) || 'Minimal 8 Karakter',
            },
            passwordConfirmation: '',
            dialog: false,
            passwordNew: '',
            passwordNewConfirmation: '',
        }
    },
    mounted() {
        this.initUsers()
        this.initRoles()
    },
    computed: {
        passwordConfirmationRule() {
            return (this.password === this.passwordConfirmation) || 'Password tidak sama'
        },
        passwordNewConfirmationRule() {
            return (this.passwordNew === this.passwordNewConfirmation) || 'Password baru tidak sama'
        },
    },
    methods: {
        async initUsers() {
            await this.getUsers()
            .then(response => response.json())
            .then(json => {
                this.users = []
                if( json.success != undefined && json.success == true ) {
                    this.users = json.data
                }
            })
            .catch(err => {
                /*eslint-disable */
                console.log(err)
            })
        },
       
        async submitUser(event) {
            if( event.keyCode != undefined && event.keyCode != 13 ) {
                return
            }


            if(this.id == '') {
                if (this.password.length < 8 || this.passwordConfirmation.length < 8) {
                    swal.error('Password minimal 8 karakter')
                    return
                }
                if (this.password !== this.passwordConfirmation) {
                    swal.error('Password tidak sama!')
                    return
                }
                const user = {
                    name: this.name,
                    username: this.username,
                    password: this.password,
                    role_id: this.role_id,
                }
                await this.addUser(user)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.initUsers()
                        this.clear()
                    } else {
                        swal.error(json.message)
                    }
                })
            } else {
                let user = {
                    name: this.name,
                    username: this.username,
                    role_id: this.role_id,
                }

                if (this.switch1) {
                    if (this.passwordNew.length < 8 || this.passwordNewConfirmation.length < 8) {
                        swal.error('Password baru minimal 8 karakter!')
                        return
                    }

                    if (this.passwordNew !== this.passwordNewConfirmation) {
                        swal.error('Password baru tidak sama!')
                        return
                    }
                    user = {
                        name: this.name,
                        username: this.username,
                        role_id: this.role_id,
                        password: this.password,
                        new_password: this.passwordNew
                    }
                }
                await this.editUser(this.id, user)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.initUsers()
                        this.clear()
                    } else {
                        swal.error(json.message)
                    }
                })
            }
            
        },
        changeUser(data) {
            this.name = data.name,
            this.username = data.username,
            this.role_id = data.role_id,
            this.id = data.id

        },
        removeUser(data) {
            let response = confirm(`Are you sure you want to delete ${data.name}`)
            if(response) {
                this.users = this.users.filter(u => u.id != data.id)
                this.deleteUser(data.id)
                .then(response => response.json())
                .then(json => {
                    if( json.success != undefined && json.success == true ) {
                        swal.success(json.message)
                        this.listUsers(json.data)
                    }
                })
            }
        },
        initRoles() {
            this.getRoles()
            .then(response => response.json())
            .then(json => {
                this.roles = []
                if( json.success != undefined && json.success == true ) {
                    this.roles = json.data
                }
            })
            .catch(err => {
                /*eslint-disable */
                console.log(err)
            })
        },
  
        reload() {
            this.initUsers()
        },
        async getUsers() {
            return await ApiUser.all()
        },
        async addUser(user) {
            return await ApiUser.create(user)
        },
        async editUser(id, user) {
            return await ApiUser.put(id, user)
        },
        async deleteUser(id) {
            return await ApiUser.delete(id)
        },
        async getRoles() {
            return await ApiRole.all()
        },
        async getRole(id) {
            return await ApiRole.get(id)
        },
        submit(event) {
            if(event.keyCode == 13) {
                swal.success('Mantap')
            }
        },
        clear() {
            this.id = ''
            this.name = ''
            this.username = ''
            this.role_id = ''
            this.password = ''
            this.passwordConfirmation = ''
            this.passwordNew = ''
            this.switch1 = false
        }
    }
}
</script>

<style>
    .set-height {
        min-height: 90vh;
    }
</style>